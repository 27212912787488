// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from "history";
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import './index.scss'
// import { createStore, applyMiddleware, compose } from "redux";
// import { Provider } from "react-redux";
// import thunk from "redux-thunk";
// import promise from "redux-promise-middleware";
// import combineReducers from "./reducer";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const middleware = [thunk, promise];

// const store = createStore(
//   combineReducers(),
//   composeEnhancers(applyMiddleware(...middleware))
// );


const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>  
    <App history={history}/>
 </Router>,
  document.getElementById('root')
);
registerServiceWorker();
