Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.ApiContentType = "application/json";
exports.ApiGetMethodType = "GET";
exports.ApiPutMethodType = "PUT";
exports.ApiDeleteMethodType = "DELETE";
exports.ApiPostMethodType = "POST";
exports.getAddressEndpoint = "bx_block_address/addresses";
exports.deleteAddressEndpoint = "bx_block_address/addresses/";
exports.addAddressEndPoint = "bx_block_address/addresses/delivery_address_create";
exports.makePrimaryAddressEndPoint = "bx_block_address/addresses/make_primary_address/"
exports.productDetailApiEndPoint = "/bx_block_catalogue/catalogues";

exports.nameRegex = /^[a-z A-Z ]*$/;
exports.phoneRegex = /^[0-9]{10}$/;
exports.pinRegex = /^[1-9]{6}$/;
exports.addressRegex = /^[a-zA-Z0-9\s,'-/,.]*$/;

exports.nameRequire = 'Name is required';
exports.phoneRequire = 'Phone number is required';
exports.pincodeRequire = 'Pincode is required';
exports.localityRequire = 'Locality is required';
exports.addressRequire = 'Address is required';

exports.nameError = 'Name should only consist of alphabets(a-z)';
exports.landmarkError = 'Landmark should only consist of alphabets(a-z)';
exports.phoneError = 'Phone number should have exact 10 digits';
exports.pincodeError = 'Pincode should consist of 6 digits';
exports.localityError = 'Locality should only consist of alphabets(a-z)';
exports.cityErr = 'City should only consist of alphabets(a-z)';
exports.addressError = 'Address should not contain special characters except (- , . / #)';



// Customizable Area End