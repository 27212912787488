// Customizable Area Start
import React from "react";
import { Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "../assets/DashboardBuyer.scss";
import FooterController, { Props } from "./FooterController.web";
const BuyerLogo = require("../../../web/public/BuyerLogo.png");
const InstaLogo = require("../assets/InstaLogo.png");
const TwitterLogo = require("../assets/TwitterLogo.png");
const FBLogo = require("../assets/FBLogo.png");

class Footer extends FooterController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className="footer" style={!this.props.hasData || this.props.hasData.length === 0 ? {
        position: "relative",
        bottom: "-80px"
      } : {}}>
        <div className="footer-row1">
          <div>
            <img src={BuyerLogo} alt="" />
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Typography
              className="footer-text-link"
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/about");
              }}
            >
              About Us
            </Typography>
            <Typography
              className="footer-text-link"
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/privacy");
              }}
            >
              Privacy Policy
            </Typography>
            <Typography
              className="footer-text-link"
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/terms");
              }}
            >
              Terms and Conditions
            </Typography>
            <Typography className="footer-text-link" onClick={()=>{
                //@ts-ignore
                this.props.history.push("/contact-us");
            }} >Contact Us</Typography>
          </div>
        </div>
        <div className="footer-row2">
          <div>
            <Typography className="rights-text">
              &copy; Growcart. All Rights Reserved
            </Typography>
          </div>
          <div className="icon-space">
            <img src={InstaLogo} className="social-logo" alt="" />
            <img src={TwitterLogo} className="social-logo" alt="" />
            <img src={FBLogo} className="social-logo" alt="" />
          </div>
        </div>
      </div>
    );
  }
}
//@ts-ignore
export default withRouter(Footer);
//Customizable Area End
