// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.placeHolderEmail = "Email";
exports.labelHeader = "Signup your account";
exports.name = "Name";
exports.labelEmail = "Email ID";
exports.labelPassword = "Password";
exports.labelRePassword = "Confirm Password";
exports.labelPhone = " | Enter Phone";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.labelLegalText =
  "By signing up you agree to our Terms & conditions and Privacy policy.";
exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.nameRequire = "Fullname is required";
exports.fullNameInvalid = "Fullname should be 8-20 characters.";
exports.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
exports.passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
exports.passwordRequire = "Password is required.";
exports.passwordMinlength = "Password is too short - should be 8 chars minimum.";
exports.passwordInValid = "Password should contain  at least a symbol, upper and lower case letters and a number.";
exports.emailRequire = "Email is required.";
exports.phoneRegex = /^[1-9]+[0-9]*$/;
exports.phoneRequire = "Phone number is required";
exports.phoneInvalid = "Phone number should contain only numbers";
exports.confirmPasswordRequire = "Confirm password is required";
exports.confirmPasswordInvalid = "Password doesn't match";
exports.contentTypeApiAddDetail = "application/json";
exports.accountsAPiEndPoint =
  "account/accounts";
exports.apiMethodTypeAddDetail = "POST";
exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.companyNameRequired = "Company Name is required";
exports.gstNumRequired = "GST Number is required";
exports.gstNumberReg=/[0-9]{2}[A-Za-z]{3}[ABCFGHLJPTFabcfghiljptf]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz]{1}[0-9A-Za-z]{1}/
exports.gstinInvalid="GST Number is not valid"
exports.sellerEndPoint="/bx_block_custom_form/seller_accounts"
exports.bankNameRequired="Bank Name is required"
exports.bankAccountNumRequired="Account Number is required"
exports.bankConfrimAccountNumRequired="Confirm Account Number is required"
exports.bankAccountReg=/^\d{9,18}$/
exports.bankAccountNumValid="Account Number is not valid"
exports.errorBothAccountsNotSame = 'Account Number and Confirm Account Number should match.'
exports.ifscReg=/^[A-Za-z]{4}0[A-Z0-9]{6}$/
exports.codeIFSCRequired="IFSC code is required"
exports.codeIFSCValid="IFSC code is not valid"
exports.endPointBankDetail='/seller_accounts/bank_details'
exports.nameReg=/^[a-zA-Z ]*$/
exports.nameInvalid='Only letters are allowed'
exports.refInvalid='Special characters or space is not allowed'
exports.termsAndCondition='Please Agree terms and condition'
exports.viewUserAccountData="/account_block/accounts/:id"
// Customizable Area End