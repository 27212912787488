// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  history:any;
}
interface S {
  progress:boolean;
  responseContent:any;
  userType:any;
  keepTermsView:boolean;
  branchName:any;
  menu:boolean
}
interface SS {
  id: any;
}

export default class TermsAndConditionWebController extends BlockComponent<Props, S, SS> {
    apiTermsAndCondtitionCallId:string
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      progress:false,
      responseContent:"",
      userType:localStorage.getItem('user_type'),
      keepTermsView:true,
      branchName:"TermsAndCondition",
      menu:false
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // let token = Cookies.get('Login_Token')
    // if(token){
     this.getTermsAndCondition()
    // }else{
    // this.props?.history?.push("/login")
    // }
  }
  
  keepTerms=(value:any)=>{
    this.setState({keepTermsView:value})
  }
  setBranchName = (num: any) => {
    this.setState({branchName:num})
  }
  showSidebar = (flag:boolean)=>{
    this.setState({menu:flag})
  }
  getTermsAndCondition(): boolean {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      // "token":Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTermsAndCondtitionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointTermsPrivacy+"1"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          
          //CreateAccount response Api
          if (
            apiRequestCallId === this.apiTermsAndCondtitionCallId &&
            responseJson !== undefined
          ){
             this.setState({responseContent:responseJson.data})
          }
        }
      } else {
          
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        if (errors[0]?.token === 'Invalid token' || errors[0]?.token==="Token has Expired" ) {
          Cookies.remove('Login_Token')
           //@ts-ignore
           
           this.props.history.push({
            pathname: '/login'
          })
          // this.setState({ loginLoader: false })
          toast.error(errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
      } 
      }
    }
    // Customizable Area End
  }

}
// Customizable Area End