//Customizable Area Start
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
//@ts-ignore
import Cookies from 'js-cookie';
import ResetPasswordModal from "../../../components/src/ResetPasswordModal.web";
import "../../../components/src/styles.scss";
//@ts-ignore
import AuthBanner from '../../../web/public/AuthBanner.jpg'
//@ts-ignore
import Logo from '../../../web/public/Logo.png'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
export const configJSON = require("./config");
const configURL = require('../../../framework/src/config')
const TrendingWeb= (props:any) => {
    useEffect(() => {
       let token= Cookies.get('Login_Token')
       let otp=Cookies.get('is_otp_verified');
       let docs=Cookies.get('is_document_uploaded');
       let bank=Cookies.get('is_bank_details_filled');
       let account_activated=Cookies.get('account_activated');
       let user=localStorage.getItem('user_type')
       if(!token){
        props?.history?.push("/login")
       }else if(!otp){
        props?.history?.push("/otp-verification")
       }else if(!docs){
        props?.history?.push("/docsCompany")
       }else if(!bank){
        props?.history?.push("/docsCompany")
       }else if(account_activated!=='yes'){
        props?.history?.push("/profileReview")
       }else if(otp && docs && bank && account_activated ==='yes' && user==='buyer'){
        props?.history?.push("/")
       }else if(otp && docs && bank && account_activated ==='yes' && user==='merchant'){
        props?.history?.push("/dashboard")
       }
      },[]);
  return (
      <div style={{display:'flex'}}>
      <div style={{marginLeft:'45px'}}>
      <h4>Landing</h4>
      </div>
    <div style={{textAlign:'end',marginLeft:'1145px',marginTop:'15px'}}>
        <Button variant="contained" 
             onClick={(e) => {
              Cookies.remove('Login_Token')
              Cookies.remove('Login_Account_Id')
              Cookies.remove('Login_Email')
              Cookies.remove('Signup_Email')
              Cookies.remove('Signup_Phone')
              Cookies.remove('is_bank_details_filled')
              Cookies.remove('is_document_uploaded')
              Cookies.remove('is_otp_verified')
              props?.history?.push("/login")
            }}>
              LOGOOUT
            </Button>
    </div>
    </div>
  );
};
export default TrendingWeb;
