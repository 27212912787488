// Customizable Area Start
import React from 'react';
import AddNewAddressController, { Props, statesData } from './AddNewAddressController.web';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, Typography, TextField, Button } from '@material-ui/core';
const closeIcon = require('../../../components/src/close.png');
const plus = require('../assets/plus.png');
import '../assets/addNewAddr.scss';
const configJSON = require('./AddNewAddressController.web');

const styles = () => ({
	noBorder: {
		border: '1px solid #e4e4e4',
	},
	input: {
		color: '#414141',
		fontSize: '16px',
	},
});

class AddNewAddress extends AddNewAddressController {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const toggleDrawer = (anchor: any, open: any) => (event: any) => {
			if (
				event.type === 'keydown' &&
				(event.key === 'Tab' || event.key === 'Shift')
			) {
				return;
			}
            if (this.props.openModal === 'edit') {
                //@ts-ignore
                document.querySelector('.info-modal').style.display =
                    'none';
            }
			this.setState({
				anchorState: open,
			});
		};
		const list = (anchor: any) => (
			<div className='form-container'>
				<div className='top-bar'>
					<Typography className='add-new-heading'>
						{this.props.openModal === 'add'
							? 'Add New Address'
							: 'Edit Address'}
					</Typography>
					<div style={{ cursor: 'pointer' }}>
						<img
							src={closeIcon}
							alt=''
							onClick={toggleDrawer('right', false)}
						/>
					</div>
				</div>
				<div className='addr-form-body'>
					<div className='field-div'>
						<Typography className='addr-form-label'>Name</Typography>
						<TextField
							id='name'
							className='add-addr-field'
							placeholder='Name'
							InputLabelProps={{
								style: { color: 'hsl(0deg 0% 50%) !important' },
							}}
							InputProps={{
								//@ts-ignore
								className: this.props.classes.input,
								//@ts-ignore
								classes: { notchedOutline: this.props.classes.noBorder },
							}}
							value={this.state.name}
							variant='outlined'
							onChange={(e) => {
								if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
									if (e.target.value !== '') {
										this.setState({
											nameErr: false,
										});
									} else {
										this.setState({
											nameErr: true,
										});
									}
									this.setState({
										name: e.target.value,
									});
								}
							}}
						/>
						{this.state.nameErr && (
							<div className='err-text'>Name can't be empty</div>
						)}
					</div>
					<div className='field-div'>
						<Typography className='addr-form-label'>
							Mobile Number
						</Typography>
						<TextField
							id='mobile-number'
							className='add-addr-field'
							placeholder='10 -  Digit Mobile Number'
							InputLabelProps={{
								style: { color: 'hsl(0deg 0% 50%) !important' },
							}}
							InputProps={{
								//@ts-ignore
								classes: { notchedOutline: this.props.classes.noBorder },
							}}
							type='number'
							variant='outlined'
							value={this.state.phone_number}
							onChange={(e) => {
								if (e.target.value.length === 10) {
									this.setState({
										phoneErr: false,
										phone_number: e.target.value,
									});
								}
								if (e.target.value.length < 10) {
									if (e.target.value.length !== 0) {
										this.setState({
											phoneErr: false,
										});
									} else {
										this.setState({
											phoneErr: true,
										});
									}
									this.setState({
										phoneErr: true,
										phone_number: e.target.value,
									});
								}
							}}
						/>
						{this.state.phoneErr && (
							<div className='err-text'>
								Phone number should be exact 10 digits
							</div>
						)}
					</div>
					<div className='field-div'>
						<Typography className='addr-form-label'>Pincode</Typography>
						<TextField
							id='pincode'
							className='add-addr-field'
							placeholder='Pincode'
							InputLabelProps={{
								style: { color: 'hsl(0deg 0% 50%) !important' },
							}}
							InputProps={{
								//@ts-ignore
								classes: { notchedOutline: this.props.classes.noBorder },
							}}
							type='number'
							variant='outlined'
							value={this.state.zip_code}
							onChange={(e) => {
								if (e.target.value.length === 6) {
									this.setState({
										pincodeErr: false,
										zip_code: e.target.value,
									});
								}
								if (e.target.value.length < 6) {
									if (e.target.value.length !== 0) {
										this.setState({
											pincodeErr: false,
										});
									} else {
										this.setState({
											pincodeErr: true,
										});
									}
									this.setState({
										pincodeErr: true,
										zip_code: e.target.value,
									});
								}
							}}
						/>
						{this.state.pincodeErr && (
							<div className='err-text'>
								Pincode should be exact 6 digits
							</div>
						)}
					</div>
					<div className='field-div'>
						<Typography className='addr-form-label'>Locality</Typography>
						<TextField
							id='locality'
							className='add-addr-field'
							placeholder='Locality'
							InputLabelProps={{
								style: { color: 'hsl(0deg 0% 50%) !important' },
							}}
							InputProps={{
								//@ts-ignore
								classes: { notchedOutline: this.props.classes.noBorder },
							}}
							variant='outlined'
							value={this.state.locality}
							onChange={(e) => {
								if (/^[a-zA-Z1-9\s.-]*$/.test(e.target.value)) {
									if (e.target.value !== '') {
										this.setState({
											localityErr: false,
										});
									} else {
										this.setState({
											localityErr: true,
										});
									}
									this.setState({
										locality: e.target.value,
									});
								}
							}}
						/>
						{this.state.localityErr && (
							<div className='err-text'>Locality can't be empty</div>
						)}
					</div>
					<div className='field-div'>
						<Typography className='addr-form-label'>Address</Typography>
						<TextField
							id='address'
							className='add-addr-field'
							placeholder='Address (Area and Street)'
							InputLabelProps={{
								style: { color: 'hsl(0deg 0% 50%) !important' },
							}}
							InputProps={{
								//@ts-ignore
								classes: { notchedOutline: this.props.classes.noBorder },
							}}
							variant='outlined'
							value={this.state.address}
							onChange={(e) => {
								if (
									/^[a-zA-Z0-9\s@#()_+-\[\]{}'\\|,.\/]*$/.test(e.target.value)
								) {
									if (e.target.value !== '') {
										this.setState({
											addressErr: false,
										});
									} else {
										this.setState({
											addressErr: true,
										});
									}
									this.setState({
										address: e.target.value,
									});
								}
							}}
						/>
						{this.state.addressErr && (
							<div className='err-text'>Address field can't be empty</div>
						)}
					</div>
					<div className='field-div'>
						<Typography className='addr-form-label'>Landmark</Typography>
						<TextField
							id='landmark'
							className='add-addr-field'
							placeholder='Landmark (optional)'
							InputLabelProps={{
								style: { color: 'hsl(0deg 0% 50%) !important' },
							}}
							InputProps={{
								//@ts-ignore
								classes: { notchedOutline: this.props.classes.noBorder },
							}}
							variant='outlined'
							value={this.state.landmark}
							onChange={(e) => {
								if (/^[a-zA-Z1-9\s.-]*$/.test(e.target.value)) {
									this.setState({
										landmark: e.target.value,
									});
								}
							}}
						/>
					</div>
					<div className='field-div'>
						<Typography className='addr-form-label'>City</Typography>
						<TextField
							id='city'
							className='add-addr-field'
							placeholder='City'
							InputLabelProps={{
								style: { color: 'hsl(0deg 0% 50%) !important' },
							}}
							InputProps={{
								//@ts-ignore
								classes: { notchedOutline: this.props.classes.noBorder },
							}}
							variant='outlined'
							value={this.state.city}
							onChange={(e) => {
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)){
                                    if (e.target.value !== '') {
                                        this.setState({
                                            cityErr: false,
                                        });
                                    } else {
                                        this.setState({
                                            cityErr: true,
                                        });
                                    }
                                    this.setState({
                                        city: e.target.value,
                                    });
                                }
							}}
						/>
						{this.state.cityErr && (
							<div className='err-text'>City can't be empty</div>
						)}
					</div>
					<div className='field-div'>
						<Typography className='addr-form-label'>State</Typography>
						<Select
							placeholder='State'
							name='colors'
							options={statesData}
							className='city-select'
							classNamePrefix='cityselect'
							//@ts-ignore
							value={this.state.state}
							onChange={(value) => {
								this.setState({
									state: value,
									stateErr: false,
								});
							}}
						/>
						{this.state.stateErr && (
							<div className='err-text'>Please select a state</div>
						)}
					</div>
				</div>
				<div className="save-cancel-combo">
					<Button
						variant='contained'
						className='save-cancel-btn1'
						onClick={() => {
							this.setState({
								anchorState: false,
							});
							if (this.props.openModal === 'edit') {
								//@ts-ignore
								document.querySelector('.info-modal').style.display =
									'none';
							}
						}}
					>
						Cancel
					</Button>
					&nbsp;
					<Button
						variant='contained'
						className='save-cancel-btn2'
						onClick={() => {
							if (this.props.openModal === 'edit') {
								this.saveEditAddress(this.props.addressID);
							} else {
								this.handleAddAddress();
							}
						}}
					>
						Save
					</Button>
				</div>
			</div>
		);
		return (
			<div>
				<React.Fragment key={'right'}>
					{this.props.openModal === 'add' ? (
						<div
							className='add-addr-btn'
							onClick={() => {
								this.setState({
									anchorState: true,
									name: '',
									phone_number: '',
									address: '',
									landmark: '',
									zip_code: '',
									locality: '',
									state: null,
									city: '',
									phoneErr: false,
									pincodeErr: false,
								});
							}}
						>
							<img className='plus-btn' src={plus} alt='' />
							<Typography className='add-text'>ADD NEW ADDRESS</Typography>
						</div>
					) : (
						<Typography
							style={{
								margin: '3px 5px',
								cursor: 'pointer',
								padding: '2px 5px',
							}}
							onClick={() => {
								if (this.props.openModal === 'edit') {
									this.getEditAddress(this.props.addressID);
								}
								this.setState({
									anchorState: true,
								});
							}}
						>
							Edit Address
						</Typography>
					)}
					<Drawer
						anchor='right'
						open={this.state.anchorState}
						onClose={toggleDrawer('right', false)}
					>
						{list('right')}
					</Drawer>
				</React.Fragment>
			</div>
		);
	}
}

// @ts-ignore
export default withStyles(styles)(withRouter(AddNewAddress));
// Customizable Area End
