// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  history: any;
}
interface S {
    branchName:any;
    keepNotificationView:boolean
    allNotification:any,
    menu:boolean,
    showIcon:boolean,
    loader: boolean;
}
interface SS {
  id: any;
}

interface NotificatiosAttr {
  notify_type: string;
  push_notificable_id: string;
  remarks: "push_notification";
  product_image?: any;
  created_at: string;
}
export interface NotificatiosType {
  attributes: NotificatiosAttr;
  id: string;
  type: "push_notification";
}

export default class NotificationsControllerWeb extends BlockComponent<Props, S, SS> {
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getNotificationApiCallId:string="";
  deleteNotificationApiCallId:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
        branchName:'notifications',
        keepNotificationView:true,
        allNotification:[],
        menu:false,
        loader: false,
        showIcon:false
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const token = Cookies.get('Login_Token')
    const id = Cookies.get('Login_Account_Id')
    if(!token){
        //@ts-ignore
        this.props?.history?.push("/login")
    }
   if(token){
    this.getNotifications()
   }
}
  
  setBranchName = (num: any) => {
         this.setState({branchName:num})
   }
   showSidebar = (flag:boolean)=>{
    this.setState({menu:flag})
  }
  
  getNotifications=async(): Promise<boolean> =>{
    this.setState({
        loader: true
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: Cookies.get('Login_Token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }


  deleteAllNotifications=async(): Promise<boolean> =>{
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: Cookies.get('Login_Token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteNotificationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteNotificationsURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.getNotificationApiCallId &&
            responseJson !== undefined
          ) {
                this.setState({ allNotification:responseJson?.data,loader: false})
                 
            } 
            if (
              apiRequestCallId === this.deleteNotificationApiCallId &&
              responseJson !== undefined
            ) {
              this.getNotifications()
              if(localStorage.getItem('user_type') === 'merchant'){
                location.reload()
              }
              toast.success('Notifications deleted successfully.',{position: toast.POSITION.BOTTOM_RIGHT})
              }    
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        if(errors==="There is no push notification.")
        {
          this.setState({ allNotification:[],loader:false})
          return
        }

        if (errors[0]?.message==='No notification found.') {
          this.setState({ allNotification:[],loader:false})
          return
        }
      }
    }
  }
}
// Customizable Area End