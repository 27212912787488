//Customizable Area Start
import React from 'react';
import BuyerNavbarWeb from '../../dashboard/src/BuyerNavbar.web';
import BuyerProfileController from './BuyerProfileController.web';
import BuyerSidebar from './BuyerSidebar.web';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
const profileLogo = require('../assets/profile-logo.png')
const profileEdit = require('../assets/profile-edit.png')
import '../assets/BuyerProfile.scss';
import FooterWeb from '../../dashboard/src/Footer.web';
//@ts-ignore
import Cookies from 'js-cookie'

const styles = () => ({
	noBorder: {
		border: '1px solid #cacaca',
	},
	input: {
		color: '#414141',
		fontSize: '16px',
	},
});

class BuyerProfile extends BuyerProfileController {
	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<>
				<BuyerNavbarWeb />
				<div className='buyer-container'>
					{/* @ts-ignore */}
					<BuyerSidebar activeTab={1} />

					<div className='profile-container'>
						<div className='div-profile-img1'>
							<img
								className='img-profile'
								src={
									this.state.imgPreview
										? this.state.imgPreview
										: profileLogo
								}
								alt=''
							/>
							<label className='img-profile' htmlFor='icon-button-file'>
								<div className='update-icon-container'>
									<img
										className='update-icon'
										src={profileEdit}
										alt=''
										aria-label='upload picture'
									/>
								</div>
							</label>
							<div
								style={{
									width: '50%',
									margin: '0 auto',
									position: 'relative',
								}}
							>
								<input
									accept='.png, .jpg, .jpeg'
									id='icon-button-file'
									type='file'
									style={{ display: 'none' }}
									name='profilePic'
									onChange={(event: any) => {
										this.fileUploadHandler(event);
									}}
								/>
							</div>
						</div>
						<div className='buyer-profile-con'>
							<div className='profile-field-con'>
								<Typography className='addr-form-label'>Name</Typography>
								<TextField
									id='name'
									className='profile-field'
									placeholder='Name'
									InputLabelProps={{
										style: { color: 'hsl(0deg 0% 50%) !important' },
									}}
									InputProps={{
										//@ts-ignore
										className: this.props.classes.input,
										classes: {
											//@ts-ignore
											notchedOutline: this.props.classes.noBorder,
										},
									}}
									value={this.state.name}
									variant='outlined'
									onChange={(e) => {
										if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
											if (e.target.value !== '') {
												this.setState({
													nameErr: false,
												});
											} else {
												this.setState({
													nameErr: true,
												});
											}
											this.setState({
												name: e.target.value,
											});
										}
									}}
								/>
								{this.state.nameErr && (
									<div className='err-text'>Name can't be empty</div>
								)}
							</div>
							<div className='profile-field-con'>
								<Typography className='addr-form-label'>Phone</Typography>
								<PhoneInput
									//@ts-ignore
									id='phoneNumber'
									name='phoneNumber'
									className='profile-field'
									country={'in'}
									value={this.state.phone}
									onlyCountries={['in']}
									onChange={(e) => {
										if (e.length === 12) {
											if (e !== this.state.currentPhone) {
												Cookies.set('is_otp_verified', false)
                                                Cookies.set('Signup_Phone', e)
											} else {
												Cookies.set('is_otp_verified', true)
                                                Cookies.set('Signup_Phone', e)
											}
											this.setState({
												phone: e,
												phoneErr: false,
											});
										} else {
											this.setState({
												phone: e,
												phoneErr: true,
												// otpEnable: false,
											});
										}
									}}
									error={this.state.phoneErr ? true : false}
									helperText={''}
									countryCodeEditable={false}
								/>
								{this.state.phoneErr && (
									<div className='err-text'>
										Please enter valid phone number
									</div>
								)}
							</div>
							{/* {this.state.otpEnable && (
								<div className='profile-field-con'>
									<Typography className='addr-form-label'>OTP</Typography>
									<TextField
										id='name'
										className='profile-field'
										placeholder='Enter 4-digit OTP'
										InputLabelProps={{
											style: { color: 'hsl(0deg 0% 50%) !important' },
										}}
										InputProps={{
											//@ts-ignore
											className: this.props.classes.input,
											classes: {
												//@ts-ignore
												notchedOutline: this.props.classes.noBorder,
											},
										}}
										value={this.state.OTP}
										variant='outlined'
										onChange={(e) => {
											if (/^[0-9]*$/.test(e.target.value)) {
												if (e.target.value.length !== 4) {
													this.setState({
														OTPErr: true,
													});
												} else {
													this.setState({
														OTPErr: false,
													});
												}
												this.setState({
													OTP: e.target.value,
												});
											}
										}}
									/>
									{!this.state.btnDisable && (
										<>
											{this.state.showSendBtn ? (
												<div
													className='send_otp_btn'
													onClick={() => {
														this.sendOtp();
														this.setState({
															showSendBtn: false,
														});
													}}
												>
													Send OTP?
												</div>
											) : (
												<div className='send_otp_btn_dis'>
													Resend in{' '}
													{this.state.seconds === 10
														? '00:10'
														: `00:${this.state.seconds < 10 ? '0' : ''}${
																this.state.seconds
														  }`}
												</div>
											)}
										</>
									)}
									{this.state.OTPErr && (
										<div className='err-text'>
											OTP should be exact 4 digits
										</div>
									)}
								</div>
							)} */}
							<div className='profile-field-con'>
								<Typography className='addr-form-label'>Email</Typography>
								<TextField
									id='email'
									className='profile-field'
									placeholder='Email'
									InputLabelProps={{
										style: { color: 'hsl(0deg 0% 50%) !important' },
									}}
									InputProps={{
										//@ts-ignore
										className: this.props.classes.input,
										classes: {
											//@ts-ignore
											notchedOutline: this.props.classes.noBorder,
										},
									}}
									value={this.state.email}
									variant='outlined'
									onChange={(e) => {
										if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) {
											this.setState({
												emailErr: false,
											});
										} else {
											this.setState({
												emailErr: true,
											});
										}
										this.setState({
											email: e.target.value,
										});
									}}
								/>
								{this.state.emailErr && (
									<div className='err-text'>Email is invalid! </div>
								)}
							</div>
						</div>
						<Button
							variant='outlined'
							className='profile-save-btn'
							onClick={() => {
                                this.updateProfile(this.state.id)
							}}
							disabled={this.state.saveLoader}
						>
							{this.state.saveLoader ? (
								<CircularProgress
									style={{
										width: '40px',
										height: '40px',
										margin: '0 auto',
									}}
								/>
							) : (
								'SAVE'
							)}
						</Button>
					</div>
				</div>
				<FooterWeb />
			</>
		);
	}
}
//@ts-ignore
export default withStyles(styles)(withRouter(BuyerProfile));
//Customizable Area End