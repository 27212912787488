Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePut = "PUT";
exports.apiMethodTypeDelete = "DELETE";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ShoppingCart";
exports.labelBodyText = "ShoppingCart Body";
exports.getCartItemsListApiEndPoint = "/bx_block_shopping_cart/cart_items";
exports.updateCartItemQuantityApiEndPoint = (cartItemId) => `/bx_block_shopping_cart/cart_items/${cartItemId}/update_quantity`;
exports.removeCartItemApiEndPoint = (cartItemId) => `/bx_block_shopping_cart/cart_items/${cartItemId}`;
exports.getAllAddressesApiEndPoint = "/bx_block_address/addresses";
exports.createOrderApiEndPoint = "/bx_block_order_management/orders";
exports.capturePaymentApiEndPoint = (id) => `/bx_block_order_management/orders/${id}/capture_order`;
exports.getRazorpayOrderIdApiEndPoint = `/bx_block_order_management/orders/razor_pay_order`;

exports.paymentTypeRazorPay = "RAZOR_PAY";
exports.paymentTypeCOD = "COD";
exports.razorPayKeyId = "rzp_test_HSLS3aJO5jttaZ";
exports.razorPayCurrency = "INR";

exports.btnExampleTitle = "CLICK ME";

exports.razorpaySecrateKey = "rzp_test_HSLS3aJO5jttaZ";

// Customizable Area End