// Customizable Area Start
import React, { useEffect, useState } from "react";
import SidebarWeb from "../../../components/src/SidebarWeb.web";
import NavbarWeb from "../../../components/src/NavbarWeb.web";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import "../assets/TermsAndConditionWeb.scss";
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
//@ts-ignore
import Cookies from 'js-cookie';
import {logOut} from "./assets"
import DashboardFooter from "../../../components/src/DashboardFooter";
import TermsAndConditionWebController, { Props } from "./TermsAndConditionWebController.web";
import BuyerNavbarWeb from "./BuyerNavbar.web";
import FooterWeb from "./Footer.web";
export default class TermsAndConditionWeb extends TermsAndConditionWebController {
  constructor(props: Props) {
    super(props);
  }
  
//   localStorage.setItem('user_type','merchant')
  render() {
    
    return (
      <>{
        this.state.userType==='merchant'?(
        <div className="containerDas">
        <div className="bannerDas">
        {/* <SidebarWeb setBranchName={setBranchName} activeBranchState={props?.location?.state}/> */}
          {Cookies.get('Login_Token') && <SidebarWeb 
           //@ts-ignore
            openSidebar={this.state.menu} changeIconValue={this.showSidebar} setBranchName={this.setBranchName} keepTermsView={this.state.keepTermsView} keepTerms={this.keepTerms} history={this.props?.history}/>} 
        </div>
        <div className={`auth-form-wrapper ${!Cookies.get('Login_Token') && 'w-100'}`}>  

            {/* <NavbarWeb branchName={branchName} addProd={addProd} setAddProd={setAddProd} totalLengthOfOrder={totalLengthOfOrder} history={props?.history} searchedValue={searchedValue} setSearchedValue={setSearchedValue}/> */}
            <NavbarWeb changeIconValue={this.showSidebar} branchName={this.state.branchName} history={this.props?.history}/>
            <div style={{margin:"30px"}}>
            <Box className="div-abouut">
            
                <h4 className="h4-about">Terms and Conditions</h4>
            <div  dangerouslySetInnerHTML={{ __html: this.state.responseContent?.content }}>
            </div>

            </Box>
            </div>
            <Hidden smUp>
            <DashboardFooter/>
            </Hidden>
            <Hidden smDown>
            <div className="terms-footer">
            <DashboardFooter/>
            </div>
            </Hidden>
        </div>
        
        </div>):
        (<>
          <BuyerNavbarWeb />
          <div className="div-about">
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.responseContent?.content,
              }}
            ></div>
          </div>
          <FooterWeb /></>
        )
}</>

    );
  }
}
// Customizable Area End