Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePut = "PUT";
exports.apiMethodTypeDelete = "DELETE";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.btnExampleTitle = "CLICK ME";
exports.productDetailApiEndPoint = "/bx_block_catalogue/catalogues";
exports.relatedProductListApiEndPoint = "/bx_block_catalogue/catalogues/related_products/";
exports.addToCartApiEndPoint = "/bx_block_shopping_cart/cart_items";
exports.getCartItemsListApiEndPoint = "/bx_block_shopping_cart/cart_items";
exports.updateCartItemQuantityApiEndPoint = (cartItemId) => `/bx_block_shopping_cart/cart_items/${cartItemId}/update_quantity`;
exports.removeCartItemApiEndPoint = (cartItemId) => `/bx_block_shopping_cart/cart_items/${cartItemId}`;
exports.getRecentlySoldProductsApiEndPoint = (page, entriesPerPage) => `/bx_block_dashboard/product?page=${page}&per_page=${entriesPerPage}`;

// Customizable Area End