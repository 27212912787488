// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from 'react-toastify';
export const configJSON = require('./config.js');

export interface Props {
	//   navigation: any;
	//   id: string;
	//   history: any
}
interface S {
    refCodeCopied: boolean;
}
interface SS {
	id: any;
}

export default class BuyerReferralController extends BlockComponent<
	Props,
	S,
	SS
> {
	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);
		console.disableYellowBox = true;

		this.subScribedMessages = [
			getName(MessageEnum.AccoutLoginSuccess),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.SessionSaveMessage),
			getName(MessageEnum.SessionResponseMessage),
		];

		this.state = {
            refCodeCopied: false
        };
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

    async componentDidMount() {
    }

	getBuyersOrders() {
		const header = {
			'Content-Type': 'application/json',
			token: Cookies.get('Login_Token'),
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage),
		);
		// this.ApiGetOrdersId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			'bx_block_order_management/orders/order_listing',
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header),
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET',
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

    async receive(from: string, message: Message) {
        try {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                var responseJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage),
                );
                var errorReponse = message.getData(
                    getName(MessageEnum.RestAPIResponceErrorMessage),
                );
                const apiid = message.getData(
                    getName(MessageEnum.RestAPIResponceDataMessage),
                );
                if (responseJson && !responseJson.errors  && !responseJson.error) {
                    
                } else {
                  if(responseJson?.errors[0]){
                    toast.error(responseJson.errors[0].token)
                  }
                  else{
                    toast.error('Something went wrong')
                  }
                }
            }
        } catch (error) {}
    }
}
// Customizable Area End