// Customizable Area Start
import React from 'react';
import BuyerNavbar from './BuyerNavbar.web';

import DashboardBuyerController, {
	Props,
} from './DashboardBuyerController.web';
import Carousel from 'react-material-ui-carousel';
import { Modal, Backdrop, Fade, Button, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import '../assets/DashboardBuyer.scss';
//@ts-ignore
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Footer from './Footer.web';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const rightArrow = require('../assets/rightArrow.png');
const leftArrow = require('../assets/leftArrow.png');
const skel = require('../assets/skel.png');
const prodSkel = require('../assets/prodSkel.png');

export default class Dashboard extends DashboardBuyerController {
	constructor(props: Props) {
		super(props);
	}
	async componentDidMount() {
		this.getDashboardData();
	}
	render() {
		var settings = {
			dots: false,
			infinite:false,
			speed: 500,
			slidesToShow: 5,
			slidesToScroll: 5,
			initialSlide:0,
			className:"slides",
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					}
				},
				{
				  breakpoint: 600,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				  }
				}
			  ]
		  };
		return (
			<>
				<BuyerNavbar />
				
				{!this.state.loading && !this.state.errorMsg && (
					
					<div className='crousal'>
						<Carousel
							navButtonsProps={{
								style: {
									backgroundColor: '#fff',
									borderRadius: '50%',
									color: '#000',
									margin: '0 20px',
								},
							}}
							navButtonsAlwaysVisible={true}
							autoPlay={true}
							animation='slide'
							indicators={false}
							interval={6000}
							stopAutoPlayOnHover={false}
						>
							{this.state.dashboardData.banners.data.map(
								(item: any, i: any) => {
									return (
										<>
											<div className='Banner' key={i}>
												<LazyLoadImage
													src={item.attributes.banner_images[0].url}
													width='100%'
													height='450px'
													alt=''
													effect='opacity'
													placeholderSrc={skel}
													beforeLoad={() => {
														let arr = [...this.state.bannerImgLoaded];
														arr[i].load = false;
														this.setState({
															bannerImgLoaded: arr,
														});
													}}
													afterLoad={() => {
														let arr = [...this.state.bannerImgLoaded];
														arr[i].load = true;
														this.setState({
															bannerImgLoaded: arr,
														});
													}}
												/>
												{this.state.bannerImgLoaded[i].load && (
													<div className='banner-content'>
														<Typography className='banner-heading-text'>
															{item.attributes.name}
														</Typography>
														{/* <Typography className='banner-disc-text'>
															Flat 50% Off
														</Typography> */}
														<Button
															className='banner-btn'
															onClick={() => {
                                                                //@ts-ignore
                                                                this.props.history.push(`/product-details/${item.attributes.product_id}`)
                                                            }}
														>
															SHOP NOW
														</Button>
														{/* <Typography className='banner-tc-text'>
															Terms & Conditions apply
														</Typography> */}
													</div>
												)}
											</div>
										</>
									);
								},
							)}
						</Carousel>
	
						</div>
				)}
				{!this.state.loading && !this.state.errorMsg && (
					<div className='section-buyer'>
						<div className='top-prod-container'>
							<div className='section-top'>
								<Typography
									className="top-products"
								>
									Top Products
								</Typography>
								<Button
									onClick={() => {
										//@ts-ignore
										this.props.history.push('/products/top');
									}}
									className='view-all-btn'
								>
									View All
								</Button>
							</div>
							
							<div className='prod-section'>
								<Slider	{...settings}>

										{this.state.dashboardData.top_products.data.map(
										(data: any, i: any) => {
											return (
												<div key={data.id} className='prod-card' onClick={()=>{
                                                    //@ts-ignore
                                                    this.props.history.push(`/product-details/${data.id}`)
                                                }}>
													<LazyLoadImage
														src={data.attributes.images[0].url}
														alt=''
														width={250}
														height={250}
														style={{
															borderRadius: '12px',
														}}
														effect='opacity'
														placeholderSrc={prodSkel}
														afterLoad={() => {
															let arr = [...this.state.topImgLoaded];
															arr[i].load = true;
															this.setState({
																topImgLoaded: arr,
															});
														}}
													/>
													{this.state.topImgLoaded[i].load && (
														<>
															<Typography className='card-prod-name'>
																{data.attributes.name}
															</Typography>
															<Typography className='card-prod-quan'>
																{data.attributes.value}{' '}
																{data.attributes.quantity}
															</Typography>
															<Typography className='card-prod-price'>
																₹{data.attributes.price}
															</Typography>
														</>
													)}
												</div>
											);

										},
									)}
								</Slider>
									
							</div>
						</div>
							
						<div className='feat-prod-container'>
							<div className='section-top'>
								<Typography
										className="top-products"
								>
									Featured Products
								</Typography>
								<Button
									onClick={() => {
										//@ts-ignore
										this.props.history.push('/products/featured');
									}}
									className='view-all-btn'
								>
									View All
								</Button>
							</div>
							
							<div  className='prod-section'>
								<div className='wrapper1'>
									<Slider {...settings}>
									{this?.state?.dashboardData?.featured_products?.data.map(
										(data: any, id: number) => {
											return (
												<div key={id} className='prod-card' onClick={()=>{
                                                    //@ts-ignore
                                                    this.props.history.push(`/product-details/${data.id}`)
                                                }}>
													<LazyLoadImage
														src={data.attributes.images[0].url}
														alt=''
														width={250}
														height={250}
														style={{
															borderRadius: '12px',
														}}
														effect='opacity'
														placeholderSrc={prodSkel}
														afterLoad={() => {
															let arr = [...this.state.featImgLoaded];
															arr[id].load = true;
															this.setState({
																featImgLoaded: arr,
															});
														}}
													/>
													{this.state.featImgLoaded[id].load && (
														<>
															<Typography className='card-prod-name'>
																{data.attributes.name}
															</Typography>
															<Typography className='card-prod-quan'>
																{data.attributes.value}{' '}
																{data.attributes.quantity}
															</Typography>
															<Typography className='card-prod-price'>
																₹{data.attributes.price}
															</Typography>
														</>
													)}
												</div>
											);
										},
									)}
									</Slider>
								</div>
							</div>
						</div>
					</div>
				)}
                {!this.state.loading && !this.state.errorMsg &&<Footer />}
			</>
		);
	}
}
// Customizable Area End
