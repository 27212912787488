//Customizable Area Start
import React, { useEffect, useState } from "react";

import '../src/SidebarWeb.scss'
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import LogOutModalWeb from "../../blocks/dashboard/src/LogOutModalWeb.web";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Drawer from '@material-ui/core/Drawer';
import  Button  from "@material-ui/core/Button";
toast.configure()
const growCart = require("../src/GrowchartIcon.jpeg")
const growProduct = require("../src/product.png")
const growProduct2 = require("../src/product2.png")
const growInvoice = require("../src/Invoices.png")
const growInvoice2 = require("../src/Invoices2.png")
const growBuy = require("../src/Buy.png")
const growBuy2 = require("../src/Buy2.png")
const growCategory2 = require("../src/Category2.png")
const growCategory = require("../src/Category.png")
const logout = require("../src/Logout.jpg")
type Anchor = 'top' | 'left' | 'bottom' | 'right';
const SidebarWeb= (props:any) => {
  const [openLogoutModal, setopenLogoutModal] = useState<boolean>(false);
  const closeLogoutModal=()=>{
    setopenLogoutModal(false)
  }

  useEffect(() => {
   if(props?.keepProfileView){
    setactiveBranch('')
   }
  
  },[props?.keepProfileView]);

  useEffect(() => {
    if(props?.keepTermsView){
     setactiveBranch('')
    }
   
   },[props?.keepTermsView]);
   useEffect(()=>{
    if(props?.keepTotalEarn){
      setactiveBranch('1')
    }
   },[props?.keepTotalEarn])
   useEffect(()=>{
    if(props?.keepTodaysEarn){
      setactiveBranch('1')
    }
   },[props?.keepTodaysEarn])
   useEffect(() => {
    if(props?.keepPrivacyView){
     setactiveBranch('')
    }
   
   },[props?.keepPrivacyView]);

   useEffect(() => {
    if(props?.keepAboutView){
     setactiveBranch('')
    }
   
   },[props?.keepAboutView]);

  useEffect(() => {
    if(props?.keepNotificationView){
      setactiveBranch('')
     }
   },[props?.keepNotificationView]);
 

  useEffect(() => {
    if(props?.activeBranchState){
    setactiveBranch(props?.activeBranchState)
    props?.setBranchName(props?.activeBranchState)  
  }
   },[props?.activeBranchState]);

  const [activeBranch, setactiveBranch] = useState<string>('1');

const list = (anchor: Anchor) => (
    <Box
    //@ts-ignore
    sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
    role="presentation"
>
    <List>
        <ListItem>
            <ListItemIcon>
                <img src={growCart} 
                  onClick={()=>{
                    //@ts-ignore
                    // this.props.history.push('/dashboard');
                    setactiveBranch('1')
                    props?.setBranchName('1')
                    if(props?.keepProfile)
                    props?.keepProfile(false)
                    if(props?.keepProfileView ||props?.keepNotificationView||props?.keepTermsView||props?.keepPrivacyView||props?.keepAboutView ||props?.keepTotalEarnings || props?.keepTodaysEarning ){
                      props?.history.push({
                        pathname: '/dashboard',
                        state:'1'
                      })
                    }
                }}
                />
              </ListItemIcon>
          </ListItem>
         
          <ListItem 
          //@ts-ignore
          className={activeBranch==='1'&&"list_item"} onClick={()=>{
            setactiveBranch('1')
            props?.setBranchName('1')
            if(props?.keepProfile)
            props?.keepProfile(false)
            if(props?.keepProfileView ||props?.keepNotificationView||props?.keepTermsView||props?.keepPrivacyView||props?.keepAboutView ||props?.keepTotalEarnings || props?.keepTodaysEarning){
              props?.history.push({
                pathname: '/dashboard',
                state:'1'
              })
            }
          }}>
              <ListItemIcon style={{marginTop:'7px'}}>
              <img className="img-icons" src={activeBranch==='1'?growCategory2:growCategory}/>
              </ListItemIcon>
              <ListItemText  className={activeBranch==='1'?"content-lines-active":"content-lines"} primary={'Dashboard'} />
          </ListItem>
         
          <ListItem  
          //@ts-ignore
          className={activeBranch==='2'&&"list_item"} onClick={()=>{
            setactiveBranch('2')
            props?.setBranchName('2')
            if(props?.keepProfile)
            props?.keepProfile(false)
            if(props?.keepProfileView||props?.keepNotificationView||props?.keepTermsView||props?.keepPrivacyView||props?.keepAboutView ||props?.keepTotalEarnings || props?.keepTodaysEarning){
              props?.history.push({
                pathname: '/dashboard',
                state:'2'
              })
            }
          }}>
              <ListItemIcon style={{marginTop:'7px'}}>
                <img className="img-icons" src={activeBranch==='2'?growBuy2:growBuy} alt="" />
              </ListItemIcon>
              <ListItemText className={activeBranch==='2'?"content-lines-active":"content-lines"} primary={'Orders'} />
          </ListItem>
        
          <ListItem  
          //@ts-ignore
          className={activeBranch==='3'&&"list_item"}
          onClick={()=>{
            setactiveBranch('3')
            props?.setBranchName('3')
            if(props?.keepProfile)
            props?.keepProfile(false)
            if(props?.keepProfileView||props?.keepNotificationView||props?.keepTermsView||props?.keepPrivacyView||props?.keepAboutView ||props?.keepTotalEarnings || props?.keepTodaysEarning){
              props?.history.push({
                pathname: '/dashboard',
                state:'3'
              })
            }
          }}>
              <ListItemIcon style={{marginTop:'7px'}}>
                <img className="img-icons" src={activeBranch==='3'?growProduct2:growProduct} alt="" />
              </ListItemIcon>
              <ListItemText className={activeBranch==='3'?"content-lines-active":"content-lines"} primary={'Products'} />
          </ListItem>
        
          <ListItem  
          //@ts-ignore
          className={activeBranch==='4'&&"list_item"}
          onClick={()=>{
            setactiveBranch('4')
            props?.setBranchName('4')
            if(props?.keepProfile)
            props?.keepProfile(false)
            if(props?.keepProfileView||props?.keepNotificationView||props?.keepTermsView||props?.keepPrivacyView||props?.keepAboutView ||props?.keepTotalEarnings || props?.keepTodaysEarning){
              props?.history.push({
                pathname: '/dashboard',
                state:'4'
              })
            }
          }}>
              <ListItemIcon style={{marginTop:'7px'}}>
                <img className="img-icons" src={activeBranch==='4'?growInvoice2:growInvoice} alt="" />
              </ListItemIcon>
              <ListItemText className={activeBranch==='4'?"content-lines-active":"content-lines"} primary={'Invoices'} />
          </ListItem>
          <div className="div-line">
          <ListItem  
          onClick={()=>setopenLogoutModal(true)}
          >
              <ListItemIcon style={{marginTop:'8px'}}>
                <img className="img-icons" src={logout} alt="" />
              </ListItemIcon>
              <ListItemText className="log-out"  primary={'Logout'} />
          </ListItem>
          <LogOutModalWeb 
          //@ts-ignore
          openLogoutModal={openLogoutModal} closeLogoutModal={closeLogoutModal} history={props.history}/>
          </div>
      </List>
    </Box>
  );

  return (
    <div className="main-div">
        <Hidden smUp>

        <React.Fragment key={'left'}>
          <Drawer anchor="left" open={props.openSidebar} variant="temporary" onClose={()=>{props.changeIconValue(false)}}>
            {list('left')}
            </Drawer>
        </React.Fragment>
        </Hidden>
        <Hidden xsDown>
        <Drawer anchor="left" open variant="permanent">
          <React.Fragment key={'left'}>
            {list('left')}
          </React.Fragment>
        </Drawer>
        </Hidden>
    </div>
  );
};
export default SidebarWeb;
