// Customizable Area Start
import React from "react";
import DashboardFooter from "../../../components/src/DashboardFooter";
import SidebarWeb from "../../../components/src/SidebarWeb.web";
import NavbarWeb from "../../../components/src/NavbarWeb.web";
import "../assets/AboutUsWeb.scss";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import AboutUsWebController, { Props } from "./AboutUsWebController.web";
import BuyerNavbarWeb from "./BuyerNavbar.web";
import FooterWeb from "./Footer.web";
export default class AboutUsWeb extends AboutUsWebController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                {this.state.userType === "merchant" ? (
                    <div className="containerDas">
                        <div className="bannerDas">
                            {/* <SidebarWeb setBranchName={setBranchName} activeBranchState={props?.location?.state}/> */}
                            <SidebarWeb
                                //@ts-ignore
                                openSidebar={this.state.menu} 
                                changeIconValue={this.showSidebar}
                                setBranchName={this.setBranchName}
                                keepAboutView={this.state.keepAboutView}
                                keepAbout={this.keepAbout}
                                history={this.props?.history}
                            />
                        </div>
                        <div className="auth-form-wrapper w-50">
                            {/* <NavbarWeb branchName={branchName} addProd={addProd} setAddProd={setAddProd} totalLengthOfOrder={totalLengthOfOrder} history={props?.history} searchedValue={searchedValue} setSearchedValue={setSearchedValue}/> */}
                            <NavbarWeb
                                changeIconValue={this.showSidebar}
                                branchName={this.state.branchName}
                                history={this.props?.history}
                            />
                            <div style={{margin:"30px"}}>
                                <Box className="div-abouut">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.responseContent?.content,
                                    }}
                                ></div>
                                </Box>
                            </div>
                            <Hidden smUp>
            <DashboardFooter/>
            </Hidden>
            <Hidden smDown>
            <div className="terms-footer">
            <DashboardFooter/>
            </div>
            </Hidden>
                        </div>
                    </div>
                ) : (
                    <>
                        <BuyerNavbarWeb />
                        <div className="div-about">
                            <div
                                style={{textAlign:"justify"}}
                                dangerouslySetInnerHTML={{
                                    __html: this.state.responseContent?.content,
                                }}
                            ></div>
                        </div>
                        <FooterWeb />
                    </>
                )}
            </>
        );
    }
}
// Customizable Area End