// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from "js-cookie";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
}
interface S {
  progress: boolean;
  newOrders: number;
  todaysEarning: number;
  totalOrders: number;
  totalEarnings: number;
  errorMsg: string;
  // chartData: any;
  recentSoldProduct: any;
  duration: string;
  options: any;
  series: any;
  loader:boolean;
}
interface SS {
  id: any;
}

export default class HomePageWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiDashboardDataId: string = "";
  apiDashboardProductId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getDashboardData = this.getDashboardData.bind(this);
    this.getRecentSoldProduct = this.getRecentSoldProduct.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      progress: false,
      errorMsg: "",
      newOrders: 0,
      todaysEarning: 0,
      totalOrders: 0,
      totalEarnings: 0,
      // chartData: [],
      recentSoldProduct: [],
      duration: "year",
      options: {},
      series: [],
      loader:false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  earningDuration(e: any) {
    this.setState({ duration: e.target.value });
    this.getDashboardData(e.target.value);
  }
  async componentDidMount() {
    this.getDashboardData(this.state?.duration);
    this.getRecentSoldProduct();
    
  }
  
  getDashboardData(duration: string): boolean {
    this.setState({
      loader:true
    })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: Cookies.get("Login_Token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardAdminGetUrl + `?filter=${duration}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getRecentSoldProduct(): boolean {
    
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: Cookies.get("Login_Token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardProductId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardAdminProductGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let ApiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (ApiCallId === this.apiDashboardDataId) {
        if (responseJson && !responseJson.errors && !responseJson.error) {
          
          this.setState({
            loader:false,
            // chartData: responseJson.data,
            newOrders: responseJson.new_orders,
            todaysEarning: responseJson.todays_earnings,
            totalOrders: responseJson.total_orders,
            totalEarnings: responseJson.total_earnings,
            series: [
              {
                name: `&#x20b9;`,
                data: responseJson?.data?.map(({ value }: any) => {
                  return value;
                }),
              },
            ],
            options: {
              chart: {
                type: "bar",
                toolbar: {
                  show: false,
                },
              },

              colors: ["#58953D"],
              tooltip: {
                // custom: function({series,seriesIndex,dataPointIndex,w}){
                //     return `<div class="arrow-box">`+
                //     `<span>`+series[seriesIndex][dataPointIndex]+``
                // }
              },
              xaxis: {
                categories: responseJson?.data.map(({ label }: any) => {
                  return label;
                }),
              },
              yaxis: {
                labels: {
                  formatter: function (num:number) {
                    if(num > 999 && num < 1000000){
                      return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
                    }else if(num > 1000000){
                      return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
                    }else if(num < 900){
                      return num; // if value < 1000, nothing to do
                    }
                  },
                }
                
              },
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  dataLabels: {
                    position: "top", // top, center, bottom
                  },
                },
              },
              dataLabels: {
                enabled: false,
              },
            },
          });
        } else {
          var errorResponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          if (errorResponse === undefined) {
            this.setState({ errorMsg: "Something went wrong" });
          } else {
            toast.error(errorResponse);
          }
        }
      } else if (ApiCallId === this.apiDashboardProductId) {
        if (responseJson && !responseJson.errors && !responseJson.error) {
          this.setState({
            recentSoldProduct: responseJson.data,
            loader:false
          });
        } else {
          var errorResponse = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          loader:false
          if (errorResponse === undefined) {
            this.setState({ errorMsg: "Something went wrong" });
          } else {
            toast.error(errorResponse);
          }
        }
      }
    }
  }
}
// Customizable Area End
