//Customizable Area Start
import React from "react";
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import '../src/NavbarWeb.scss';
const notificatioBell = require("../src/Notification.png");
const UnreadNotificatioBell = require("../src/UnreadNotif.png");
const translate = require("../src/translate.png");
const serachIcon = require("../src/searchIcon.png");
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import Cookies from 'js-cookie';
toast.configure()
const styles = (() => ({
    noBorder: {
      border: "none",
    },
    }));

import NavbarControllerWeb, { Props } from "./NavbarControllerWeb.web";
import LogOutModalWeb from "../../blocks/dashboard/src/LogOutModalWeb.web";
class NavbarWeb extends NavbarControllerWeb {
  constructor(props: Props) {
    super(props);
  }
    
  render() {
    const {classes}:any = this.props
    console.log(this.props?.totalLengthOfOrder);
    return (
        <div>
        <Box
            //@ts-ignore
            sx={{ flexGrow: 1 }}
        >       
            <AppBar position="static" style={{ background: "#f8fafb" }}>
                <div
                    className='app-bar-div'
                >
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.handleDrawerToggle}
                        className="show-icon"
                    >
                        <MenuIcon />
                    </IconButton>           
                    {this.props?.branchName === "1" && (
                        <>
                            <h3 className="das-heading">Dashboard</h3>
                        </>
                    )}
                    {this.props?.branchName === "2" && (
                        <>
                            <div className='order-div-nav'>
                            <h3 className="das-heading">Orders</h3>
                            <Badge
                                color="secondary"
                                badgeContent={this.props.totalLengthOfOrder}
                            ></Badge>
                            </div>
                            <div className="order-search">
                            <TextField
                                id="outlined-basic"
                                className="input-searrch"
                                placeholder="Search orders"
                                variant="outlined"
                                value={this.props.orderedSearchedValue}
                                onChange={(e) => {
                                    this.props.setOrderedSearchedValue(e.target.value);
                                }}
                                InputProps={{
                                    startAdornment: <img src={serachIcon} alt="" />,
                                    classes:{notchedOutline:classes.noBorder}
                                }}
                            />
                            </div>
                             </>
                    )}
                    {this.props?.branchName === "3" && (
                        <>
                            <h3 className="das-heading">Products</h3>
                            <div className="product-search-icon">
                                    <TextField
                                        id="outlined-basic"
                                        className="input-width"
                                        placeholder="Search"
                                        variant="outlined"
                                        value={this.props.searchedValue}
                                        onChange={(e) => {
                                            this.props.setSearchedValue(e.target.value);
                                        }}
                                        InputProps={{
                                            startAdornment: <img src={serachIcon} alt="" />,
                                            //@ts-ignore
                                            // classes:{notchedOutline:this.props.classes.noBorder}
                                        }}
                                    />
                                    <IconButton onClick={() => {
                                            this.props.setAddProd(true);
                                        }} className="new-product-icon"><AddIcon/></IconButton>
                                    </div>
                             </>
                    )}
                    {this.props?.branchName === "profile" && (
                        <>
                            <h3 className="das-heading">Profile</h3>
                        </>
                    )}
                    {this.props?.branchName === "4" && (
                        <>
                            <h3 className="das-heading">Invoices</h3>
                            <div className="order-search">
                            <TextField
                                id="outlined-basic"
                                className="input-searrch"
                                placeholder="Search Invoices"
                                variant="outlined"
                                value={this.props.insearchedValue}
                                onChange={(e) => {
                                    this.props.setinSearchedValue(e.target.value);
                                }}
                                InputProps={{
                                    startAdornment: <img src={serachIcon} alt="" />,
                                    classes:{notchedOutline:classes.noBorder}
                                }}
                            />
                            </div>
                        </>
                    )}
                            {
                                this.props?.branchName === "TotalEarnings"&&(
                                    <div className='order-div-nav'>
                                    <h3 className="das-heading">Total Earnings</h3>
                                    <Badge
                                        color="secondary"
                                        badgeContent={this.props.totalAmountOfOrder}
                                        max={this.props.totalAmountOfOrder}
                                    ></Badge>
                                    </div>
                                )
                             }
                             {
                        
                                this.props?.branchName === "TodaysEarning"&&(
                                    <div className='order-div-nav'>
                                    <h3 className="das-heading">Today's Earning</h3>
                                    <Badge
                                        color="secondary"
                                        badgeContent={this.props.totalAmountOfOrder}
                                        max={this.props.totalAmountOfOrder}
                                    ></Badge>
                                    </div>
                                )
                             }
                            {
                                this.props?.branchName === "TermsAndCondition"&&(
                                    <>
                                    <h3 className="das-terms-condition">Terms And Conditions</h3>
                                </>
                                )
                             }
                               {
                                this.props?.branchName === "Privacy"&&(
                                    <>
                                    <h3 className="das-terms-condition">Privacy Policy</h3>
                                </>
                                )
                             }
                              {
                                this.props?.branchName === "About"&&(
                                    <>
                                    <h3 className="das-terms-condition">About Us</h3>
                                </>
                                )
                             }
                    {/* <div> */}
                      
                       
                        { this.props?.branchName ==='notifications'&&(
                        <div style={{display:'flex', alignItems:'center'}}>
                        <h3  className='das-heading-notifiaction'>Notifications
                        </h3> <p   className="clear-all" onClick={()=>{this.props?.deleteAllNotifications();this.setState({notificationVisible:!this.state.notificationVisible})}}>
                        CLEAR ALL
                        </p></div>
                        )}
                        <Toolbar className="hello">                        
                            {this.props?.branchName === "3" && (
                                 
                                    <div className="product-search">
                                
                                    <TextField
                                        id="outlined-basic"
                                        className="input-web-search"
                                        // style={{width:'190px',marginRight:"20px"}}
                                        placeholder="Search Products"
                                        variant="outlined"
                                        value={this.props.searchedValue}
                                        onChange={(e) => {
                                            this.props.setSearchedValue(e.target.value);
                                        }}
                                        InputProps={{
                                            startAdornment: <img src={serachIcon} alt="" />,
                                            //@ts-ignore
                                            classes:{notchedOutline:classes.noBorder}
                                            //  classes:{notchedOutline:this.props.classes.noBorder}
                                        }}
                                    />   
                                    <Button
                                        variant="contained"
                                        className="new-product"
                                        onClick={() => {
                                            this.props.setAddProd(true);
                                        }}
                                    >
                                        + ADD NEW PRODUCT
                                    </Button>
                                    </div>
                                    
                                 
                                
                            )}
                            <Box
                                //@ts-ignore
                                sx={{ minWidth: 120 }}
                            >
                                <div className="language-container">
                                    <img
                                        src={translate}
                                        alt="language"
                                    />
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        //@ts-ignore
                                        value={this.state.language}
                                        disableUnderline
                                        //@ts-ignore
                                        onChange={(e) => this.setState({language: e.target.value})}
                                    >
                                        <MenuItem value="english">Eng</MenuItem>
                                        <MenuItem value="hindi">Hindi</MenuItem>
                                        <MenuItem value="kannada">Kannada</MenuItem>
                                    </Select>
                                </div>
                            </Box>
                            
                            {Cookies.get('Login_Token') &&
                                <>
                                    <Tooltip title="Notifications">
                                        <div className="notification-space">
                                        <Badge color="secondary" variant="dot" invisible={this.state.notificationVisible}>
                                        <img onClick={() => {
                                            //@ts-ignore
                                            this.props?.history?.push({
                                                pathname: '/notifications'
                                            })
                                        }}  src={notificatioBell} alt=""  />
                                        </Badge>
                                        </div>
                                        
                                    </Tooltip>
                                    <div
                                        className="div-profile"
                                        style={{ color: "black" }}
                                        onClick={() => {
                                            this.setState({
                                                dropOpen: !this.state.dropOpen
                                            })
                                            // if (this.props?.keepProfile) {
                                            //     this.props?.keepProfile(true);
                                            // } else {
                                            //     //@ts-ignore
                                            //     this.props?.history?.push("/profile");
                                            // }
                                        }}
                                    >
                                        <img
                                            className="profile-img"
                                            src={this?.props?.profileData?.attributes?.avatar?.url || this.state.userDetail?.attributes?.avatar?.url || "https://dreamvilla.life/wp-content/uploads/2017/07/dummy-profile-pic.png"}
                                            alt=""
                                        />
                                        &nbsp;&nbsp;
                                        <div className="profile-line">
                                            <h4 className="user-line">{this?.props?.profileData?.attributes?.account?.fullname || this.state.userDetail?.attributes?.account?.fullname || 'user_name'}</h4>
                                            <p className="account-line">{this?.props?.profileData?.attributes?.account?.user_type || this.state.userDetail?.attributes?.account?.user_type}</p>
                                        </div>
                                        {this.state.dropOpen && (
                                        <>
                                        <div className="overlay2"></div>
                                        <div className="profile-dropdown">
                                        <div className="dropdown-tab" onClick={()=>{
                                            //@ts-ignore
                                            this.props.history.push('/profile')
                                        }}><p className="dropdown-text">Profile</p></div>
                                        <div className="dropdown-tab" onClick={()=>{
                                            this.setState({
                                                dropOpen: false,
                                                showLogoutModal: true
                                            })
                                        }}><p className="dropdown-text logout-text">Logout</p></div>
                                    </div>
                                    </>
                                    )}
                                    </div>
                                </>
                            } 
                        </Toolbar>
                    {/* </div> */}
                </div>
            </AppBar>
            <LogOutModalWeb
                //@ts-ignore
                openLogoutModal={this.state.showLogoutModal} closeLogoutModal={() => this.setState({showLogoutModal: false})} history={this.props.history} />
        </Box>
    </div>
      );
  }
}
export default withStyles(styles)(NavbarWeb);
//Customizable Area End
