import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

import googleController, { GoogleWebDelegate } from "./GoogleWebController";

// Customizable Area Start
//@ts-ignore
import { gapi } from "gapi-script";
//@ts-ignore
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export interface Props {
  navigation: any;
  id: string;
}

interface S {
  loading: boolean;
  isRegistration: boolean;
  accessToken?: any;
}

interface SS {}

export default class SocialMediaAccountWebController
  extends BlockComponent<Props, S, SS>
  implements GoogleWebDelegate {
  createAccountAPICallId: any;
  createAccountFacebookAPICallId:any;
  googleUser: any;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
    ]);

    this.state = {
      loading: false,
      isRegistration: false,
      accessToken:''
    };
  }

  //When facebook sends back the reponse this gets called
  googleUserStatusChanged(userInfo: any): void {
    if (this.state.isRegistration) {
      this.createAccountFromSocial(userInfo.email, userInfo.id, this.props);
    } else {
    const auth2 = gapi.auth2.getAuthInstance();
    if(auth2?.isSignedIn?.c2?.value){
      // console.log("jigar",auth2,gapi);
      auth2?.signOut().then(()=>{
      let  options = new gapi.auth2.SigninOptionsBuilder();
         options.setPrompt('select_account');
        options.setScope('profile').setScope('email');
        auth2?.signIn(options).then(()=>{

          var profile = auth2.currentUser.get().getBasicProfile();
  
          if (profile) {
            var userEmail = profile.getEmail();
            var userId = profile.getId();
            this.logInWithSocial(
            userEmail,
            userEmail,
            userId,
            this.props
            );
          }
          
        })
      })
    }else{
      
        let  options = new gapi.auth2.SigninOptionsBuilder();
           options.setPrompt('select_account');
          options.setScope('profile').setScope('email');
          auth2?.signIn(options).then(()=>{
            var profile = auth2.currentUser.get().getBasicProfile();

            if (profile) {
              var userEmail = profile.getEmail();
              var userId = profile.getId();
              this.logInWithSocial(
                userEmail,
                userEmail,
                userId,
                this.props
                );
            }
        })
    }
     
    }
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  responseFacebook = (response: any) => {  
    this.setState({accessToken:response?.accessToken})
    this.stopLoading();
    if (this.state.isRegistration) {
      this.createAccountFromSocial(response.email, response.id, this.props);
    } else {
        this.logInWithSocialFacebook(
          response.email,
          response.email,
          response.id,
          this.props,
          response
        );
    }
    runEngine.debugLog(response);
  };

  googleLogIn = (isRegistration: boolean) => {
    const self = this;  
    //@ts-ignore
    googleController.googleLogIn(this).then(
      function() {
        self.stopLoading();
        runEngine.debugLog("User SIGNED IN.");
      },
      function(error: any) {
        self.stopLoading();
        if (error.error === "popup_closed_by_user") {
          //handle window closed event
        }
      }
    );
  };

  async receive(from: string, message: Message) {
    try {
      this.stopLoading();

      if (getName(MessageEnum.SessionSaveMessage) === message.id) {
        this.openInfoPage();
      } else if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.createAccountAPICallId != null &&
        this.createAccountAPICallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (responseJson && responseJson.meta && responseJson.meta.token) {
           Cookies.set('Login_Token', responseJson?.meta?.token, {expires: 1})
           Cookies.set('Login_Account_Id', responseJson?.data?.id)
           let email = responseJson.data.attributes.email
           let phone_number = responseJson.data.attributes.full_phone_number
           let is_document_uploaded = responseJson.data.attributes.is_document_uploaded
           let is_bank_details_filled = responseJson.data.attributes.is_bank_details_filled
           let activated = responseJson.data.attributes.activated
           let userType = responseJson.data.attributes.user_type
            Cookies.set('is_otp_verified', true);
            Cookies.set('Signup_Email',  email)
            Cookies.set('Signup_Phone', phone_number)
            Cookies.set('is_document_uploaded', is_document_uploaded);
            Cookies.set('is_bank_details_filled', is_bank_details_filled);
            
            if(userType==='buyer'){
              localStorage.setItem('user_type','buyer')
              Cookies.set('account_activated', 'yes');
              Cookies.set('is_otp_verified', true);
              Cookies.set('is_document_uploaded', true);
              Cookies.set('is_bank_details_filled', true);
              Cookies.set('Login_Token', responseJson.meta.token, {expires: 1})
              Cookies.set('Signup_Email', email)
              Cookies.set('Signup_Phone', phone_number)
              toast.success('Login Successful',{position: toast.POSITION.BOTTOM_RIGHT,autoClose:2000})
              
              //@ts-ignore
              this?.props?.history?.push("/")
            }else if(!is_document_uploaded){
              //@ts-ignore
              this.props.history.push({
              pathname: '/docsCompany'
            })
            }else if(!is_bank_details_filled){
              //@ts-ignore
              this.props.history.push({
                pathname: '/docsCompany'
              })
            }else if(!activated){
              //@ts-ignore
              this.props.history.push({
                pathname: '/profileReview'
              })
            }
            if(is_document_uploaded && is_bank_details_filled &&activated){
              Cookies.set('account_activated', 'yes');
              toast.success('Login successful.',{position: toast.POSITION.BOTTOM_RIGHT,autoClose:2000})
               //@ts-ignore
               this.props.history.push({
                pathname: '/dashboard'
              })
            }
          
          //this.saveLoggedInUserData(responseJson);
        } else if (responseJson && responseJson.errors) {
          console.log("user-data-err");
          this.parseApiErrorResponse(responseJson);
        } else {
          var errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }else if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createAccountFacebookAPICallId != null &&
      this.createAccountFacebookAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
  
          var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            Cookies.set('Login_Token', responseJson?.meta?.token, {expires: 1})
            Cookies.set('Login_Account_Id', responseJson?.data?.id)
            let email = responseJson.data.attributes.email
            let phone_number = responseJson.data.attributes.full_phone_number
            let is_document_uploaded = responseJson.data.attributes.is_document_uploaded
            let is_bank_details_filled = responseJson.data.attributes.is_bank_details_filled
            let activated = responseJson.data.attributes.activated
            let userType = responseJson.data.attributes.user_type
             Cookies.set('is_otp_verified', true);
             Cookies.set('Signup_Email',  email)
             Cookies.set('Signup_Phone', phone_number)
             Cookies.set('is_document_uploaded', is_document_uploaded);
             Cookies.set('is_bank_details_filled', is_bank_details_filled);
              
            if(userType==='buyer'){
              localStorage.setItem('user_type','buyer')
              Cookies.set('account_activated', 'yes');
              Cookies.set('is_otp_verified', true);
              Cookies.set('is_document_uploaded', true);
              Cookies.set('is_bank_details_filled', true);
              Cookies.set('Login_Token', responseJson.meta.token, {expires: 1})
              Cookies.set('Signup_Email', email)
              Cookies.set('Signup_Phone', phone_number)
              toast.success('Login Successful',{position: toast.POSITION.BOTTOM_RIGHT,autoClose:2000})
              
              //@ts-ignore
              this?.props?.history?.push("/")
            }else if(!is_document_uploaded){
               //@ts-ignore
               this.props.history.push({
               pathname: '/docsCompany'
             })
             }else if(!is_bank_details_filled){
               //@ts-ignore
               this.props.history.push({
                 pathname: '/docsCompany'
               })
             }else if(!activated){
               //@ts-ignore
               this.props.history.push({
                 pathname: '/profileReview'
               })
             }
             if(is_document_uploaded && is_bank_details_filled &&activated){
               Cookies.set('account_activated', 'yes');
              
               toast.success('Login successful.',{position: toast.POSITION.BOTTOM_RIGHT, autoClose:2000})
                //@ts-ignore
                this.props.history.push({
                 pathname: '/dashboard'
               })
             } 
  
           // this.saveLoggedInUserData(responseJson);
          } else if (responseJson && responseJson.errors) {
            this.parseApiErrorResponse(responseJson);
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            this.parseApiCatchErrorResponse(errorReponse);
          }
  
      } 
      else {
        runEngine.debugLog("GOIT");
      }
    } catch (error) {
      console.log(error);
      
    }

  }

  async createAccountFromSocial(

    incomingEmail: String,
    incomingId: String,
    props: Props
  ) {
   
     
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();

    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAccountURL
    );

    const data = {
      type: "social_account",
      attributes: {
        email: incomingEmail,
        password: incomingEmail,
        unique_auth_id: incomingId
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async logInWithSocialFacebook(
    incomingEmail: string,
    incomingPassword: string,
    incomingId: string,
    response:any,
    props: Props

  ) {
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();
   
   
    const header = {
      "Content-Type": "application/json"
    };

    const attrs = {
      email: incomingEmail,
      password: incomingPassword,
      unique_auth_id: incomingId
    };
    let user=localStorage.getItem('user_type')
    let data={}
    if(user==="buyer"){
      data = {
       type: "social_account",
        attributes: {
        // email: incomingEmail,
        social_type: 'facebook',
        "user_type": "buyer",
        "access_token":this.state.accessToken
      }
      };
    }else if(user==="merchant"){
      data = {
        type: "social_account",
        attributes: {
          // email: incomingEmail,
          social_type: 'facebook',
          "user_type": "merchant",
          "access_token":this.state.accessToken
        }
      };
    }else{
      data = {
        type: "social_account",
        attributes: {
          // email: incomingEmail,
          social_type: 'facebook',
          //"user_type": "merchant",
          "access_token":this.state.accessToken
        }
      };
    }
     
    // const data = {
    //   type: "social_account",
    //   attributes: {
    //     // email: incomingEmail,
    //     social_type: 'facebook',
    //     "user_type": "merchant",
    //     "access_token":this.state.accessToken
    //   }
    // };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountFacebookAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEndUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async logInWithSocial(
    incomingEmail: string,
    incomingPassword: string,
    incomingId: string,
    props: Props
  ) {
     
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();
    const token =gapi.auth2.getAuthInstance().currentUser?.le?.xc?.id_token
    const header = {
      "Content-Type": "application/json"
    };

    const attrs = {
      email: incomingEmail,
      password: incomingPassword,
      unique_auth_id: incomingId
    };
    let user=localStorage.getItem('user_type')
    let data={}
    if(user==="buyer"){
      data = {
        type: "social_account",
        attributes: {
          email: incomingEmail,
          social_type: 'google',
          "user_type" : "buyer",
          "access_token":token
        }
      };
    }else if(user==="merchant"){
      data = {
        type: "social_account",
        attributes: {
          email: incomingEmail,
          social_type: 'google',
          "user_type" : "merchant",
          "access_token":token
        }
      };
    }else{
      data = {
        type: "social_account",
        attributes: {
          email: incomingEmail,
          social_type: 'google',
          //"user_type" : "merchant",
          "access_token":token
        }
      };
    }
     

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEndUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigate() {
    runEngine.debugLog("this.isRegistration");
    runEngine.debugLog(this.state.isRegistration);
    if (this.state.isRegistration) {
      runEngine.debugLog("Registration");
      runEngine.debugLog(this.state.isRegistration);
      this.navigateToSignup();
    } else {
      runEngine.debugLog("Registration");
      runEngine.debugLog(this.state.isRegistration);
      this.navigateToLogin();
    }
  }

  navigateToSignup() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );

      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg = new Message(
      getName(
        this.state.isRegistration
          ? MessageEnum.AccoutResgistrationSuccess
          : MessageEnum.AccoutLoginSuccess
      )
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  //Create response callback.
  responseInfoCallback(error: any, result: any) {
    if (error) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorDescription + error.toString()
      );
    } else {
      runEngine.debugLog("Result Name: " + result.name);
    }
  }

  btnFacebookLogInProps = {
    onPress: () => {
      this.startLoading();
    },
    callback: this.responseFacebook
  };

  btnGoogleLogInProps = {
    onPress: () => {
      this.googleLogIn(this.state.isRegistration);
      this.startLoading();
    }
  };

  btnNavigateProps = {
    onPress: () => this.navigate()
  };
}
// Customizable Area End