// Customizable Area Start
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import SidebarWeb from "../../../components/src/SidebarWeb.web";
import NavbarWeb from "../../../components/src/NavbarWeb.web";
import "../assets/PrvacyPolicyWeb.scss";
import Divider from '@material-ui/core/Divider';
import DashboardFooter from "../../../components/src/DashboardFooter";
//@ts-ignore
import Cookies from 'js-cookie';
import { logOut } from "./assets"
import PrvacyPolicyWebController, { Props } from "./PrvacyPolicyWebController.web";
import BuyerNavbarWeb from "./BuyerNavbar.web";
import FooterWeb from "./Footer.web";
export default class PrvacyPolicyWeb extends PrvacyPolicyWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>{
        this.state.userType === 'merchant' ? (
          <div className="containerDas">
            <div className="bannerDas">
              {/* <SidebarWeb setBranchName={setBranchName} activeBranchState={props?.location?.state}/> */}
              {Cookies.get('Login_Token') &&
                <SidebarWeb
                  //@ts-ignore
                  openSidebar={this.state.menu} changeIconValue={this.showSidebar} setBranchName={this.setBranchName} keepPrivacyView={this.state.keepPrivacyView} keepPrivacy={this.keepPrivacy} history={this.props?.history} />
              }
            </div>
            <div className={`auth-form-wrapper ${!Cookies.get('Login_Token') && 'w-100'}`}>

              {/* <NavbarWeb branchName={branchName} addProd={addProd} setAddProd={setAddProd} totalLengthOfOrder={totalLengthOfOrder} history={props?.history} searchedValue={searchedValue} setSearchedValue={setSearchedValue}/> */}
              <NavbarWeb changeIconValue={this.showSidebar} branchName={this.state.branchName} history={this.props?.history} />
              <div style={{margin:"30px"}}>
              <Box className="div-privacy-about">
                <h2 className="h4-about">Privacy Policy</h2>
                <div  dangerouslySetInnerHTML={{ __html: this.state.responseContent?.content }}>
                </div>
              </Box>
              </div>
              <Hidden smUp>
              <DashboardFooter />
              </Hidden>
              <Hidden smDown>
                <div className="terms-footer">
                  <DashboardFooter/>
                </div>
              </Hidden>
            </div>
          </div>) : (<>
            <BuyerNavbarWeb />
            <div className="div-about">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.responseContent?.content,
                }}
              ></div>
            </div>
            <FooterWeb /></>
        )
      }</>
    );
  }
}
// Customizable Area End