// Customizable Area Start
import { Route, Redirect, withRouter } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
const configURL = require("../../../framework/src/config");
import axios from "axios";

function Wrapper({ element, history, match, routeMap, closeModal }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach((param) => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      alert("dspl");
      history.push(url);
      // if the route is a modal
    } else {
      alert("go");
      // checking if the url ends with a slash or not
      url = match.url + slash + url;
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack },
    closeModal,
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object,
};

const PrivateRoute = withRouter((props) => {
  const { children, allowedRole, flag } = props;

  async function api() {
    if (!Cookies.get("Login_Token")) {
      localStorage.removeItem("user_type");
      Cookies.remove("Login_Token");
      Cookies.remove("is_otp_verified");
      Cookies.remove("is_bank_details_filled");
      Cookies.remove("is_document_uploaded");
      Cookies.remove("is_otp_verified");
      Cookies.remove("Signup_Email");
      Cookies.remove("Signup_Phone");
      Cookies.remove("Login_Phone");
      Cookies.remove("account_activated");
      Cookies.remove("Login_Email");
      Cookies.remove("Login_Account_Id");
      Cookies.remove("user_type");
    }
  }

  const userRole = localStorage.getItem("user_type");
  if (flag === "signup") {
    return children;
  } else if (allowedRole === "public") {
    // api();
    return children;
  } else if (allowedRole !== userRole) {
    toast.error("You are not authorised to access this page..!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 6000,
    });
    return <Redirect to="/login" />;
  } else if (
    !userRole &&
    (allowedRole === "merchant" || allowedRole === "buyer")
  ) {
    api();
    toast.error("You are not authorised to access this page..!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 6000,
    });
    return <Redirect to="/login" />;
  } else {
    api();
    if (Cookies.get("Login_Token") === undefined) {
      toast.error("Please login to continue...");
      return <Redirect to="/login" />;
    }
    return children;
  }
});

const WebRoutesGenerator = (props) => {
  const { routeMap } = props;

  return Object.keys(routeMap).map((route) => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={(props) => (
            <Wrapper
              element={<Component {...props} />}
              {...props}
              routeMap={routeMap}
            />
          )}
        />
      );
    } else {
      return (
        <Route
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={(props) => (
            <Wrapper
              element={
                <PrivateRoute
                  allowedRole={currentRoute.userType}
                  flag={currentRoute.flag}
                >
                  <Component {...props} />
                </PrivateRoute>
              }
              {...props}
              routeMap={routeMap}
            />
          )}
        />
      );
    }
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object,
};

export default withRouter(WebRoutesGenerator);
// Customizable Area End
