// Customizable Area Start
import React, { ChangeEvent } from "react";
//@ts-ignore
import ReactTable from "react-table-v6";
import Pagination from "./CustomReactTablePagination";
import "react-table-v6/react-table.css";
import "./styles.scss";

interface CustomTableProps {
  id: string;
  className?: string;
  loading: boolean;
  data: [object];
  columns: object;
  totalEntries: number;
  recordStartIndex: number;
  recordEndIndex: number;
  recordsPerPage: number;
  currentPage: number;
  changePage: (e: ChangeEvent, page: number) => void;
  noDataText:string;
}

const CustomReactTable = (props: CustomTableProps) => {
  const {
    id,
    className,
    loading,
    data,
    columns,
    totalEntries,
    recordStartIndex,
    recordEndIndex,
    recordsPerPage,
    currentPage,
    changePage,
    noDataText
  } = props;

  return (
    <ReactTable
      loading={loading}
      id={id}
      className={`${className ? className : ""}`}
      data={data}
      columns={columns}
      noDataText={
        !loading?noDataText: ''
      }
      PaginationComponent={Pagination}
      defaultPageSize={data?.length}
      totalEntries={totalEntries}
      recordStartIndex={recordStartIndex}
      recordEndIndex={recordEndIndex}
      recordsPerPage={recordsPerPage}
      currentPage={currentPage}
      changePage={changePage}
    />
  );
};

export default CustomReactTable;
// Customizable Area End
