import React, {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  MouseEventHandler,
  ReactChild,
} from "react";
import Button from "@material-ui/core/Button";
import "./styles.scss";
import CircularProgress from '@material-ui/core/CircularProgress';
import { RouteComponentProps } from 'react-router-dom';

interface CustomInputProps {
  id: string;
  className?: string;
  variant: "contained" | "outlined" | "text" | undefined;
  name: string;
  onClick?: MouseEventHandler;
  onBlur?: FocusEventHandler;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean | undefined;
  history?: RouteComponentProps["history"];
  children: ReactChild;
  loading?: boolean
}

const CustomInput = (props: CustomInputProps) => {
  // const history = useHistory();
  const {
    id,
    name,
    type,
    variant,
    className,
    disabled,
    onClick,
    onBlur,
    children,
    loading
  } = props;
  return (
    <Button
      id={id}
      name={name}
      className={`${className}`}
      variant={variant}
      type={type}
      onClick={onClick}
      onBlur={onBlur}
      disabled={disabled || loading}
    >
      <>
      {/* {children} */}
      {loading ? <CircularProgress size={24} className="loader" /> : children}
      </>
    </Button>
  );
};

export default CustomInput;
