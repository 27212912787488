// Customizable Area Start
import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export interface Props {
  
}
interface S {
  dashboardData: any;
  loader: boolean;
  listing: string;
  catLoading: boolean;
  count: number;
  productsListEnd: boolean;
  searchQuery: any;
  uniqueSearch: string;
}
interface SS {
  id: any;
}

export default class SearchProductListController extends BlockComponent<Props, S, SS> {
    apiSearchProductListId:any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.callback = this.callback.bind(this)
    console.disableYellowBox = true;
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
        dashboardData: [],
        loader: true,
        listing: '',
        catLoading: true,
        count: 1,
        productsListEnd: false,
        searchQuery: '',
        uniqueSearch: ''
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

   callback = (entries:any, observer:any) => {
    if(entries[0].isIntersecting){
        this.setState({
            count: this.state.count + 1
        })
        entries.forEach((entry:any) => {
            if(entry.isIntersecting){
                this.getProducts()
            }
        });
    }
  };

  async componentDidMount() {
      this.setState({
        //@ts-ignore
        searchQuery: new URLSearchParams(this.props.location.search).get('search')
    })
    this.getProducts()
    this.setState({
        catLoading: false
    })
  }

  shouldComponentUpdate(nextProps:any, nextState:any){
      //@ts-ignore
    let {search} = this.props.location;
    let val = new URLSearchParams(search).get('search')
    //@ts-ignore
    if(nextProps.location.search !== this.props.location.search){
        this.setState({
            count: 1,
            loader:true,
            //@ts-ignore
            searchQuery: new URLSearchParams(this.props.location.search).get('search')
        })
        setTimeout(() => {
            this.getProducts()
        },0);
        return true;
    }
    return true
    }

    componentDidUpdate(prevProps:any, prevState:any){
        if(this.state.loader !== prevState.loader && !this.state.loader && !this.state.productsListEnd && this.state.dashboardData){
        let loaderDiv:any = document.getElementById('loader')
        let options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
          }
          
          let observer = new IntersectionObserver(this.callback, options);
          //@ts-ignore
          observer.observe((loaderDiv));
          return true
    }
    }

  getProducts(): boolean {
      //@ts-ignore
    let {search} = this.props.location;
    let val = new URLSearchParams(search).get('search')
    let apiEndPoint = `bx_block_catalogue/catalogues?search=${val}&page=${this.state.count}&per_page=4`
    const header = {
        'Content-Type': 'application/json',
        // token: Cookies.get('Login_Token'),
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
    );
    this.apiSearchProductListId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndPoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header),
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET',
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}

  async receive(from: string, message: Message) {
    try {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors  && !responseJson.error) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.apiSearchProductListId &&
            responseJson !== undefined
          ) {
                //@ts-ignore
                if(responseJson.total_entries === 0){
                    this.setState({
                        dashboardData: null,
                        loader:false,
                    })
                }
                else{
                    //@ts-ignore
                    if(this.state.dashboardData.length===0 || new URLSearchParams(this.props.location.search).get('search') !== this.state.uniqueSearch){
                        this.setState({
                            dashboardData: responseJson.data,
                            loader:false,
                            productsListEnd: responseJson.end_to === responseJson.total_entries
                        })
                    }
                    else
                    {
                        this.setState({
                            dashboardData:[...this.state.dashboardData, ...responseJson.data],
                            loader:false,
                            productsListEnd: responseJson.end_to === responseJson.total_entries
                        })
                    }
                }
                this.setState({
                    //@ts-ignore
                    uniqueSearch: new URLSearchParams(this.props.location.search).get('search')
                })
            } 
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.error;
        toast.error(errors)
        
      }
    }
      
    } catch (error) {
    
    }
    
  }
}
// Customizable Area End