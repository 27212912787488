Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OrderDetailView";
exports.labelBodyText = "OrderDetailView Body";

exports.btnExampleTitle = "CLICK ME";
exports.allOrderEndPoints="bx_block_order_management/orders/order_listing"
exports.statusUpdateEndpoint="bx_block_order_management/orders/"
exports.allListStatusOrder="bx_block_order_management/orders/order_listing?filter[status]="
// Customizable Area End