//Customizable Area Start
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import { DropzoneArea } from 'material-ui-dropzone';
import axios from 'axios';
import '../assets/DocumentSubmitBankDetailsWeb.scss'
//@ts-ignore
import Cookies from 'js-cookie';
//@ts-ignore
import AuthBanner from '../../../web/public/AuthBanner.jpg'
//@ts-ignore
import Logo from '../../../web/public/Logo.png'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
export const configJSON = require("./config");
const configURL = require('../../../framework/src/config')
const DocumentSubmitCompanyDetailWeb= (props:any) => {
  const [disable, setdisable] = useState<boolean>(false);
  const [imageName, setimageName] = useState<boolean>(false);
  
  const validations=  Yup.object().shape({
    bankName: Yup.string()
    .required(configJSON.bankNameRequired).matches(configJSON.nameReg,configJSON.nameInvalid),
    accountNumber:  Yup.string()
    .required(configJSON.bankAccountNumRequired)
    .matches(configJSON.bankAccountReg, configJSON.bankAccountNumValid),
    confirmAccountNumber:  Yup.string()
    .required(configJSON.bankConfrimAccountNumRequired)
    .oneOf(
      [Yup.ref("accountNumber"), null],
      configJSON.errorBothAccountsNotSame
    ),
    ifscCode:  Yup.string()
    .required(configJSON.codeIFSCRequired)
    .matches(configJSON.ifscReg, configJSON.codeIFSCValid),
  })
 
  const doSubmit=async (value?:any)=>{
    setdisable(true)
    const data = {
        "bank_details": {
          "bank_name": value?.bankName,
          "account_number": value?.accountNumber,
          "confirm_account_number": value?.confirmAccountNumber,
          "ifsc_code": value?.ifscCode,
          "primary": true
      }
    }
    
    await axios
      .post(`${configURL.baseURL}`+'/'+`${configJSON.endPointBankDetail}`,data,{
        headers:{
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "token": Cookies.get('Login_Token')
          
        }
      } )
      .then((res:any) => {
        setdisable(false)
        toast.success('Bank details updated successfully.',{position: toast.POSITION.BOTTOM_RIGHT, autoClose:2000})
        Cookies.set('is_bank_details_filled', 'true')
        props?.history?.push('/profileReview')
      })
      .catch((err:any) => {
        
        try {
          if(err?.response?.data?.errors[0]?.token){
            toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
          } else if(err?.message==='Request failed with status code 500'){
            toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
          }else if(err?.message==='Request failed with status code 400'){
            toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
          }
        } catch (error) {
          
        }
      
        setdisable(false)
      }).finally(() => {
        setdisable(false)
       })
}

const useStyles = makeStyles((theme:any) => createStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
}));

const classes = useStyles();

  return (
       <Formik
      initialValues={{
        ifscCode:"",
        bankName:"",
        accountNumber:"",
        confirmAccountNumber:""
      }}
      validationSchema={
        validations
      }
      onSubmit={(values): any => 
        {
          doSubmit(values)
        }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        setFieldValue
      }) => (
        //@ts-ignore
        <Form >
                <div className="container">
    <div className="banner w-50">
      <img className="logo" src={Logo} />
      <img className="banner-img" src={AuthBanner} />
      <p className="rights-reserved">
        Grow Cart © 2021. All Rights Reserved
      </p>
    </div>
    <div className="auth-form-wrapper w-50">
    <div className="auth-form">
              <div className="login-form">
            <div className="docs-Line-div">
            <h2 className="docs-line"> Document submit only in 3 Steps</h2>
            </div>
            <p className="provide-line">Provide your company details &amp; document</p>
            <div>
              <br />&nbsp;
            <p className="step-line">Step 3 of 3</p>
            <h3 className="bank-detail-line">Bank Details</h3>
            <br />
              <h5 className="banke-details">Bank Name</h5>
              <TextField
                className="w-100"
                placeholder="Bank Name"
                variant="outlined"
                name="bankName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bankName}
                helperText={errors.bankName}
                error={touched.bankName && errors.bankName ? true : false}
              />
            </div>
            <br/>
            <div>
              <h5 className="banke-details">Account Number</h5>
              <TextField
              className="w-100"
                placeholder="Account Number"
                variant="outlined"
                name="accountNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.accountNumber}
                helperText={errors.accountNumber}
                error={touched.accountNumber && errors.accountNumber ? true : false}
              />
            </div>
            <br/>
            <div>
              <h5 className="banke-details">Confirm Account Number</h5>
              <TextField
              className="w-100"
                placeholder="Confirm Account Number"
                variant="outlined"
                name="confirmAccountNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmAccountNumber}
                helperText={errors.confirmAccountNumber}
                error={touched.confirmAccountNumber && errors.confirmAccountNumber ? true : false}
              />
            </div> 
            <br/>
            <div>
              <h5 className="banke-details">IFSC Code</h5>
              <TextField
              className="w-100"
                placeholder="IFSC Code"
                variant="outlined"
                name="ifscCode"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ifscCode}
                helperText={errors.ifscCode}
                error={touched.ifscCode && errors.ifscCode ? true : false}
              />
            </div>&nbsp;
            <br/>
            <div>
              {disable?<Button variant="contained" className="login-button"
               >
                <CircularProgress style={{color:'#FFFFFF'}}/>
              </Button>:<Button variant="contained" className="login-button" disabled={disable}
               onClick={(e) => {
                  handleSubmit();
              }}>
                SAVE
              </Button>}
            </div>
          
              </div>
            </div>
    </div>
  </div>
          
        </Form>
      )}
    </Formik>
  );
};
export default DocumentSubmitCompanyDetailWeb;
