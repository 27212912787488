// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config.js");

export interface Props {
    isCartUpdate?: boolean;
    readNotif?: boolean;
    
//   navigation: any;
//   id: string;
//   history: any
}

interface S {
    categoriesList: any;
    searchValue: string;
    isSearchModalOpen: boolean;
    searchSuggestions: any;
    userDetails: any;
    loader: boolean;
    dropOpen: boolean;
    showLogoutModal: boolean;
    cartCount: number;
    IsNotifRead: boolean;
}

interface SS {
  id: any;
}

export default class BuyerNavbarController extends BlockComponent<Props, S, SS> {
	categoriesListApiCallId: string = '';
    apiSearchProductCallId: string = '';
    ApiGetProfileDataId: string = '';
    apiCartItemCountId: string = '';
    getNotificationApiCallId: string = '';

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.getProductsByCategory = this.getProductsByCategory.bind(this);
    this.getProfileData = this.getProfileData.bind(this);
    
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      categoriesList: [],
      searchValue: '',
      isSearchModalOpen: false,
      searchSuggestions: [],
      userDetails: [],
      loader: true,
      dropOpen: false,
      showLogoutModal: false,
      cartCount: 0,
      IsNotifRead: true
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
   this.getCategoriesData();
   if(Cookies.get('Login_Token'))
   {
    if(localStorage.getItem('user_type')==='buyer'){
        this.getCartItemsCount();
    }
    this.getNotifications()
    this.getProfileData();
   }
   
  }

   shouldComponentUpdate(nextProps: any, nextState: any): boolean {
    if(Cookies.get('Login_Token'))
    {
        if(nextProps.isCartUpdate !== this.props.isCartUpdate){
            if(localStorage.getItem('user_type')==='buyer'){
                this.getCartItemsCount();
            }
        }
        if(nextProps.readNotif !== this.props.readNotif){
            this.getNotifications();
        }
    }
    return true;
  }

  async componentWillUnmount(): Promise<void> {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node:any) {
    //@ts-ignore
    this.wrapperRef = node;
  }

  handleClickOutside(event:any) {
    //@ts-ignore
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.target.id!=='searchbar') {
      this.setState({
        isSearchModalOpen:false
      })
    }
  }

  getCartItemsCount = () => {
    const header = {
    "Content-Type": configJSON.dashboarContentType,
      token: Cookies.get('Login_Token')
    };
    const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCartItemCountId = requestMessage.messageId;
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    'bx_block_shopping_cart/cart_items'
    );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
    );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getNotifications(){
    const header = {
      "Content-Type": 'application/json',
      token: Cookies.get('Login_Token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_notifications/notifications'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  getCategoriesData = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    //   token: Cookies.get('Login_Token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoriesListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  searchProd(e:any):any{
    let val = e.target.value===' '? '': e.target.value.replace('  ', ' ');
    this.setState({
        searchValue : val,
        isSearchModalOpen : val.length>2 ? true : false,
    })

    if(val.length<=2){
        this.setState({
            searchSuggestions: []
        })
    }else{
    if(val!==this.state.searchValue){
        const header = {
            'Content-Type': configJSON.dashboarContentType,
            token: Cookies.get('Login_Token'),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );
        this.apiSearchProductCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.productsGetUrl + `?per_page=7&search=${val}&suggestions=true`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header),
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType,
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    }
}

getProductsByCategory(id:any){
    //@ts-ignore
    this.props.history.push({pathname:'/category/${id}'})
}

getProfileData(){
    const header = {
        "Content-Type": 'application/json',
        token: Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ApiGetProfileDataId = requestMessage.messageId;
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    'account_block/accounts/:id'
    );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
    );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}

async receive(from: string, message: Message){
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        var errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (responseJson && !responseJson.errors && responseJson.data) {
            if (apiRequestCallId === this.categoriesListApiCallId) {
                if (responseJson.data.length === 0) {
                    this.setState({
                        //   loading: false,
                    });
                } else {
                    this.setState({
                        categoriesList: [...responseJson.data],
                        //   loading: false,
                    });
                }
            }
            else if(apiRequestCallId === this.apiCartItemCountId) {
                this.setState({
                    cartCount: responseJson.data.length
                })
            }
            else if (apiRequestCallId === this.getNotificationApiCallId){
                    this.setState({
                        IsNotifRead: false
                    })
                console.log(responseJson)
            }
            else if(apiRequestCallId === this.apiSearchProductCallId){
                if (responseJson.data && !responseJson.errors && responseJson.data.length !==0) {
                    let arr = [];
                    for(let i = 0 ;i < responseJson.data.length ; i++){
                        arr.push({
                            id: responseJson.data[i].id,
                            name: responseJson.data[i].attributes.name,
                            category: responseJson.data[i].attributes.category.name,
                            image: responseJson.data[i].attributes.images[0].url,
                            is_featured: responseJson.data[i].attributes.is_featured
                        });
                    }
                    this.setState({
                        searchSuggestions: arr
                    })
                } else {
                    var errorReponse = message.getData(
                        getName(MessageEnum.RestAPIResponceErrorMessage),
                    );
                    if(responseJson.data?.length===0){
                       this.setState({
                        searchSuggestions:null
                       }) 
                    }
                    else if(responseJson.errors[0]){
                        toast.error(responseJson.errors[0].token)
                    }
                    if (errorReponse === undefined) {
                        this.setState({
                        });
                    } else {
                        toast.error(errorReponse)

                    }
                }
            }else if(apiRequestCallId === this.ApiGetProfileDataId){
                this.setState({
                    userDetails: [{fullname: responseJson.data.attributes.fullname, avatar: responseJson.data.attributes.avatar?.url}],
                    loader: false
                })
            }
        } else {
            console.log(responseJson);
            if(responseJson?.errors === 'There is no push notification.'){
                this.setState({
                    IsNotifRead: true
                })
            }   
            // ? error response
            if (errorReponse === undefined) {
                this.setState({});
            } else {
                toast.error(errorReponse)
            }
        }
    }
}


}
// Customizable Area End