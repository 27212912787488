//Customizable Area Start
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import {imgUploadArrow} from "./assets";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import Button from "@material-ui/core/Button";
import '../assets/DocumentSubmitCompanyDetailWeb.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import {Popover, Typography} from '@material-ui/core';
import axios from 'axios';
//@ts-ignore
import AuthBanner from '../../../web/public/AuthBanner.jpg'
//@ts-ignore
import Logo from '../../../web/public/Logo.png'
import DocumentSubmitKycDetailsWeb from "./DocumentSubmitKycDetailsWeb.web";
import DocumentSubmitBankDetailsWeb from "./DocumentSubmitBankDetailsWeb.web";
//@ts-ignore
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyle = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1.5),
    background:'#000',
    borderRadius:'10px'
  },
}));

toast.configure()
export const configJSON = require("./config");
const configURL = require('../../../framework/src/config')
const DocumentSubmitCompanyDetailWeb= (props:any) => {
  const [disable, setdisable] = useState<boolean>(false);
  const [stepOneResData, setstepOneResData] = useState<any>([]);
  const [imageName, setimageName] = useState<boolean>(false);
  const [showSteps, setshowSteps] = useState<any>('1');
  const [companyName, setcompanyName] = useState<any>('');
  const [GST_number, setgstNumber] = useState<any>('');
  const [loader, setLoader] =useState<boolean>(true)
  const [closeImage, setcloseImage] = useState<boolean>(false);
  const [selectedCompanyImg, setselectedCompanyImg] = useState<any>('');
  const [isPdfFile, setisPdfFile] = useState<any>('');
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLImageElement | null>(null)
  const open = Boolean(anchorEl)
  const classs = useStyle()

  const validations=  Yup.object().shape({
    companyName: Yup.string()
    .required(configJSON.companyNameRequired).matches(configJSON.nameReg,configJSON.nameInvalid),
    GST_number:  Yup.string()
    .required(configJSON.gstNumRequired)
    .matches(configJSON.gstNumberReg, configJSON.gstinInvalid)
    .max(15),
    selectedFile:Yup.string()
    .required()
  })
  
  let token = Cookies.get('Login_Token')
    useEffect(() => {
        if(Cookies.get("is_document_uploaded") === "true" && Cookies.get("is_bank_details_filled") === "true"){
            return props?.history?.push("/dashboard");
        }
        if (!token) {
            props?.history?.push("/login");
        } else {
            if (Cookies.get("is_document_uploaded") === "false") {
                setshowSteps("1");
            } else if (Cookies.get("is_document_uploaded") === "true") {
                setshowSteps("3");
            }
            toast.warning(
                `Please complete the KYC process for the account linked with +${Cookies.get(
                    "Signup_Phone"
                )}!`,
                { autoClose: 3500 }
            );
            setLoader(false);
        }
    }, []);
 
  const changePage = (page:any) =>
  {
    setshowSteps(page)
  
  }

  const doSubmit=async (value?:any)=>{ 
    setgstNumber(value.GST_number)
    setcompanyName(value.companyName)
    setshowSteps('2')
}

const useStyles = makeStyles((theme:any) => createStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
}));

const classes = useStyles();
  return (!loader && (
    showSteps==='1'?( 
       <Formik
      initialValues={{
        companyName: companyName,
        GST_number:GST_number,
        selectedFile:""
      }}
      validationSchema={
        validations
      }
      onSubmit={(values): any => 
        {
          doSubmit(values)
        }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        setFieldValue
      }) => (
        //@ts-ignore
        <Form >
            <div className="container">
    <div className="banner w-50">
      <img className="logo" src={Logo} />
      <img className="banner-img" src={AuthBanner} />
      <p className="rights-reserved">
        Grow Cart © 2021. All Rights Reserved
      </p>
    </div>
    <div className="auth-form-wrapper w-50">
    <div className="auth-form">
              <div className="login-form">

            <div className="docs-Line-div">
            <h2 className="docs-line"> Document submit only in 3 Steps</h2>
            </div>
            <p className="provide-line">Provide your company details &amp; document</p>
            <div>
              <br />&nbsp;
            <p className="step-line">Step 1 of 3</p>
            <h3 className="bank-detail-kyc-line">Company Details</h3>
            <br />
              <h5 className="banke-details">Company Name</h5>
              <TextField
                className="all-input"
                id="company_name"
                placeholder="Company Name"
                variant="outlined"
                name="companyName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyName}
                helperText={errors.companyName}
                error={touched.companyName && errors.companyName ? true : false}
              />
            </div>
            <br />
            <div>
              <h5 className="banke-details" style={{display:'flex', alignItems:'center'}}>GST Number{"  "}
              <img src="https://p.kindpng.com/picc/s/292-2923100_macintosh-question-mark-application-software-icon-number-2.png" width={20} style={{marginLeft:'5px', cursor:'pointer'}} alt=""
              aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={(e)=>{setAnchorEl(e.currentTarget)}}
                onMouseLeave={()=>{setAnchorEl(null)}}
            /></h5>
            <Popover
                id="mouse-over-popover"
                style={{pointerEvents:'none', color:'black'}}
                className={classs.popover}
                classes={{
                paper: classs.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                onClose={()=>{setAnchorEl(null)}}
                disableRestoreFocus
            >
            <Typography style={{color:'#eee', fontFamily:'Poppins', fontSize:'12px'}}>• It should be 15 characters long.</Typography>
            <Typography style={{color:'#eee', fontFamily:'Poppins', fontSize:'12px'}}>• The first 2 characters should be a number.</Typography>
            <Typography style={{color:'#eee', fontFamily:'Poppins', fontSize:'12px'}}>• The next 10 characters should be your PAN number.</Typography>
            <Typography style={{color:'#eee', fontFamily:'Poppins', fontSize:'12px'}}>• The 13th and 15th character should be a digit</Typography>
            <Typography style={{color:'#eee', fontFamily:'Poppins', fontSize:'12px'}}>{"  "}or an alphabet.</Typography>
            <Typography style={{color:'#eee', fontFamily:'Poppins', fontSize:'12px'}}>• The 14th character should be 'Z'.</Typography>
             </Popover>
              <TextField
              className="all-input"
              id="gst_number"
                placeholder="GST Number"
                variant="outlined"
                name="GST_number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.GST_number}
                helperText={errors.GST_number}
                error={touched.GST_number && errors.GST_number ? true : false}
              />
            </div>&nbsp;
            {
            closeImage?(<>
            <h5 className="upload-line">Upload Company Licence</h5><br />
            <CancelIcon className="cross-circle-cancel" onClick={()=>{setcloseImage(false);setFieldValue("selectedFile",''),setselectedCompanyImg('')}}/>
            {isPdfFile==='pdf'?(
            <embed
            src={selectedCompanyImg}
            width="600"
            height="200"
            className="pdf-class"
            />
            ):(<img src={selectedCompanyImg} alt="" className="DocsImg"  />)}
            </>
            ) :(<div>
              <h5 className="banke-details">Upload Company Licence</h5>&nbsp;
              <DropzoneAreaBase
                  onAdd={(fileObjs:any) =>{ 
                    setcloseImage(true)
                    if(fileObjs[0]?.file?.type==="application/pdf"){
                      setisPdfFile('pdf')
                    }else if(fileObjs[0]?.file?.type==='image/jpeg'){
                      setisPdfFile('jpeg')
                    }else if(fileObjs[0]?.file?.type==='image/png'){
                      setisPdfFile('png')
                    }
                    setselectedCompanyImg(fileObjs[0]?.data)
                    setFieldValue("selectedFile",fileObjs)
                    setimageName(false)
                }}
                  onDelete={(fileObj) => {}}
                   fileObjects={[]}
                   name="selectedFile"
                   onChange={(e:any)=>{
                    //getBaseUrl(e)
                    setFieldValue("selectedFile",e)
                    setimageName(false)
                  
                   }}
                   showAlerts={true}
                  //@ts-ignore
                  Icon={ArrowUpwardIcon}
                  acceptedFiles={['.png, .jpg, .jpeg, .pdf']}
                  showFileNames
                  //showAlerts={false}
                  maxFileSize={5242880}
                   //@ts-ignore
                dropzoneText={<div className="dropzone-text-div"><img src={imgUploadArrow}/> <p>Upload Company Licence</p></div>}
               
                  filesLimit={1}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  />
                  {imageName&&<p style={{fontSize:'12px',color:'#f44336'}}>Company license is required</p>}
            </div>)
        }
            &nbsp;
            <div>
              {disable?<Button variant="contained" className="login-button"
               >
                <CircularProgress style={{color:'#FFFFFF'}}/>
              </Button>:<Button variant="contained" className="login-button" disabled={disable}
               onClick={(e) => {
                 //@ts-ignore
                if(values?.selectedFile&&values?.companyName&&values?.GST_number || selectedCompanyImg&&values?.companyName&&values?.GST_number){
                  if(!errors.companyName && !errors.GST_number){
                      setimageName(false)
                    // handleSubmit();
                      doSubmit(values)
                }
                }else{
                      setimageName(true)
                      handleSubmit();
                }
              }}>
                NEXT
              </Button>}
            </div>
          
              </div>
            </div>
    </div>
  </div>
          
        </Form>
      )}
    </Formik>):(
      showSteps==='2'?(
        <DocumentSubmitKycDetailsWeb stepOneResData={stepOneResData} history={props?.history} changePage={changePage} 
         companyName={companyName} gstNumber={GST_number} selectedCompanyImg={selectedCompanyImg} isPdfFile={isPdfFile}/>
      ):(showSteps==='3'?(<DocumentSubmitBankDetailsWeb history={props?.history}/>): null)
    )
  
  ));
};
export default DocumentSubmitCompanyDetailWeb;
//Customizable Area End
