import React from "react";

// Customizable Area Start
import OTPInputAuthController, { Props } from "./OTPInputAuthController.web";
import {
	Typography,
	Button,
	Modal,
	Box,
	CircularProgress,
	TextField,
} from '@material-ui/core';
import '../assets/otpauth.scss'
//@ts-ignore
import AuthBanner from '../../../web/public/AuthBanner.jpg'
//@ts-ignore
import Logo from '../../../web/public/Logo.png'
//@ts-ignore
import OtpInput from "react-otp-input";
//@ts-ignore    
import Cookies from 'js-cookie'
// Customizable Area End

export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 // Customizable Area Start
  render() {
    return (
			<>
				<div className='container'>
					<div className='banner w-50'>
						<img className='logo' src={Logo} />
						<img className='banner-img1' src={AuthBanner} />
						<p className='rights-reserved'>
							Grow Cart © 2021. All Rights Reserved
						</p>
					</div>
					<div className='auth-form-wrapper w-50'>
						<div className='otpauth-form'>
							<h2 className='otp-heading'>OTP</h2>
							<p style={{ color: '#565555' }}>
								A Verification code has been sent to your phone +
								{Cookies.get('Signup_Phone')}.
							</p>
							<p
								style={{
									color: '#221172',
									cursor: 'pointer',
									fontFamily: 'Poppins',
									fontSize: '14px',
									//@ts-ignore
									fontWeight: '600',
								}}
								onClick={() => {
                                    this.setState({openModal: true})
                                }}
							>
								Change Phone number?
							</p>
							<div>
								<h4 className='input-lable'>Enter OTP</h4>
								<OtpInput
									separator={
										<span>
											<strong> </strong>
										</span>
									}
									numInputs={4}
									inputStyle={{
										width: '100%',
										height: '3rem',
										margin: '0 12px',
										fontSize: '2rem',
										borderRadius: 12,
										border: '1px solid rgba(0,0,0,0.3)',
									}}
									value={this.state.otp}
									onChange={(num: any) => {
										if (num.toString().length === 4 && !isNaN(num)) {
											this.setState({ disableVerifyBtn: false, otp: num });
										} else {
											this.setState({ disableVerifyBtn: true, otp: '' });
										}
									}}
								/>
								&nbsp;
							</div>
							<div>
								{this.state.sendingOypBtn ? (
									<Button
										variant='contained'
										className='submit-button'
										disabled={true}
									>
										<p style={{ display: 'flex' }}>
											<CircularProgress style={{ color: '#FFFFFF' }} />{' '}
											<p style={{ marginTop: '12px', marginLeft: '10px' }}>
												SENDING OTP
											</p>
										</p>
									</Button>
								) : (
									<Button
										variant='contained'
										className='submit-button'
										disabled={this.state.disableVerifyBtn}
										onClick={(e) => {
											this.submitOtp();
										}}
									>
										VERIFY
									</Button>
								)}
							</div>
							<br />
							{this.state.showResendButton ? (
								<div style={{ textAlign: 'center' }}>
									<h4>
										RESEND IN{' '}
										{this.state.seconds === 60
											? '01:00'
											: '00:' + this.state.seconds}
									</h4>
								</div>
							) : (
								<Button
									variant='contained'
									className='submit-button'
									disabled={this.state.ResendButtonDisable}
									onClick={(e) => {
										this.setDisable();
										this.getOtp();
									}}
								>
									RESEND OTP
								</Button>
							)}
						</div>
					</div>
				</div>

				{this.state.openModal && (
					<Modal
						open={this.state.openModal}
						aria-labelledby='modal-modal-title'
						aria-describedby='modal-modal-description'
					>
						<Box className='box-modal'>
							<div className='div-modal'>
								<img src={''} alt='' />
							</div>
							<h4 className='div-modal-h4'>Change phone number</h4>
							<div className='field-div' style={{marginBottom:'50px'}}>
								<Typography className='addr-form-label'>
									Mobile Number
								</Typography>
								<TextField
									id='mobile-number'
									className='add-addr-field'
									placeholder='10 Digit Mobile Number'
									InputLabelProps={{
										style: { color: 'hsl(0deg 0% 50%) !important' },
									}}
									// InputProps={{
									// 	//@ts-ignore
									// 	classes: { notchedOutline: this.props.classes.noBorder },
									// }}
									type='number'
									variant='outlined'
									value={this.state.changed_phone_number}
									onChange={(e) => {
										if (e.target.value.length === 10) {
											this.setState({
												phoneErr: false,
												changed_phone_number: e.target.value,
											});
										}
										if (e.target.value.length < 10) {
											if (e.target.value.length !== 0) {
												this.setState({
													phoneErr: false,
												});
											} else {
												this.setState({
													phoneErr: true,
												});
											}
											this.setState({
												phoneErr: true,
												changed_phone_number: e.target.value,
											});
										}
									}}
								/>
								{this.state.phoneErr && (
									<div className='err-text'>
										Phone number should be exact 10 digits
									</div>
								)}
							</div>
							<div
								style={{
									textAlign: 'center',
									marginBottom: '25px',
									display: 'flex',
                                    justifyContent: 'space-between'
								}}
							>
								<Button
									variant='contained'
									className='modal-cancel-btn'
									onClick={() => {
										this.setState({
											openModal: false,
										});
									}}
								>
									Cancel
								</Button>
								&nbsp;
								<Button
									variant='contained'
									className='modal-remove-btn'
									onClick={() => {
										this.changePhoneNumber();
									}}
								>
									{this.state.Loader ? <CircularProgress /> : 'Update'}
								</Button>
							</div>
						</Box>
					</Modal>
				)}
			</>
			//   </div>
			//   </div>
		);
  }
 // Customizable Area End 
}

// Customizable Area Start
// Customizable Area End
