import React from "react";

//Customizable Area Start
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CircularProgress from '@material-ui/core/CircularProgress';
export const configJSON = require("./config");
import ForgotPasswordController, { Props } from "./ForgotPasswordControllerWeb.web";
import {withRouter,Link} from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import '../assets/forgetPass.scss'
//@ts-ignore
import AuthBanner from '../../../web/public/AuthBanner.jpg'
//@ts-ignore
import Logo from '../../../web/public/Logo.png'
//Customizable Area End

 class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
        <div className="container">
        <div className="banner w-50">
          <img className="logo" src={Logo} />
          <img className="banner-img1" src={AuthBanner} />
          <p className="rights-reserved">
            Grow Cart © 2021. All Rights Reserved
          </p>
        </div>
        <div className="auth-form-wrapper w-50">
        <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={
          Yup.object().shape({
            email: Yup.string()
              .required(configJSON.emailRequire)
              .matches(configJSON.emailRegex, configJSON.errorEmailNotValid),
          })
        }
        onSubmit={(values): any => this.doSubmit(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          handleBlur,
        }) => (
          //@ts-ignore
          <Form >
            
            <div className="login-form">
              <div style={{ display:'flex' }}>
              <Link to={'/login'}><ArrowBackIcon className="back-arrow"/>&nbsp;</Link>
              <h2 className="forget-heading"> Forgot Password</h2>
              </div>
              <p style={{ color:'#565555' }}>Enter your registered email to receive password</p>
              <p style={{ color:'#565555' }}> reset link on your email address.</p>
              <div>
                <h4 className='input-lable'>Email Id</h4>
                <TextField
                  id="email"
                  placeholder="Email ID"
                  variant="outlined"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  helperText={errors.email}
                  error={touched.email && errors.email ? true : false}
                />
              </div>&nbsp;
              <div>
                {this.state.disableBtn?<Button variant="contained" className="submit-button" ><CircularProgress className="loader"/></Button>:(
                   <Button variant="contained" className="submit-button"  disabled={this.state.disableBtn}
                   onClick={(e) => {
                    handleSubmit();
                  }}>
                    SUBMIT
                  </Button>
                )}
               
              </div>
            </div>
          </Form>
        )}
      </Formik>
      </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ForgotPassLoginStyles = {
} as any;
// Customizable Area End
// Customizable Area Start
//@ts-ignore
const ForgotPassWithRouter =withRouter(ForgotPassword)
export default withStyles(ForgotPassLoginStyles)(ForgotPassWithRouter)
// Customizable Area End
