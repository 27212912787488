//Customizable Area Start
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import "./ResetPasswordModal.scss"
const success = require("../src/image_success_icon.jpg")
const ResetPasswordModal= (props: any) => {
    const [closeModal, setcloseModal] = useState<boolean>(false);
    
  return (
    <Modal
    open={props?.openModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box className="box-modal">
    <div className="div-modal">
    <img src={success}/>
    </div>
     <h4 className="div-modal-h4">Password {props?.userProfile==='userProfile'?'Changed':'Reset'}</h4>
      <Typography >
        <p  className="p-tag-modal">Your password has been changed successfully.</p>
      </Typography>
      <div style={{textAlign:'center',marginBottom:'25px'}}>
      <Button variant="contained" className="modal-btn" onClick={()=>{
      if(!props?.userProfile  && props?.userProfile !=='userProfile'){  
      props?.doClose()
      props?.history?.push('/login')
      }else{
        props?.doClose()
      }
     }} >
              {props?.userProfile==='userProfile'?'DONE':'Back TO Login'}
      </Button>
      </div>
    </Box>
  </Modal>
  );
};
export default ResetPasswordModal;
// Customizable Area End
