//Customizable Area Start
import React from "react";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
export default function DashboardFooter() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: mobile ? "center" : "space-between",
        paddingTop: "10px",
        flexDirection: mobile ? "column" : "row",
      }}
    >
      <Typography
        style={{
          //   marginLeft: mobile ? "0px" : "250px",
          // paddingLeft: "24px",
          fontSize: mobile ? "15px" : "1rem",
          color: "#aaa",
          fontFamily: "Poppins",
        }}
      >
        Grow Cart @ 2021. All Rights Reserved
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#aaa",
          fontFamily: "Poppins",
          // paddingLeft: mobile ? "24px" : "0px",
        }}
      >
        <div>
          <Link to="/terms">
            <Typography
              style={{
                color: "#aaa",
                fontFamily: "Poppins",
                cursor: "pointer",
              }}
            >
              Terms & Conditions
            </Typography>
          </Link>
        </div>
        <div
          style={
            {
              // marginLeft: "10px",
              // marginRight: "10px",
            }
          }
        >
          |
        </div>
        <div>
          <Link to="/privacy">
            <Typography
              style={{
                color: "#aaa",
                fontFamily: "Poppins",
                cursor: "pointer",
              }}
            >
              Privacy Policy
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );
}
//Customizable Area End
