//Customizable Area Start
import React from 'react';
//@ts-ignore
import ReactTable from 'react-table-v6';
import CustomReactTablePagination from '../../../components/src/CustomReactTablePagination';
import DashboardFooter from '../../../components/src/DashboardFooter';
import { threeDots } from './assets';

export default function ProductTable({
	data,
	showProductDetails,
	loader,
	totalEntries,
	recordStartIndex,
	recordEndIndex,
	currentPage,
	changePage,
    searchedValue,
    columns
}: any) {

	return (
        <>
		<ReactTable
			data={data}
			loading={loader}
			columns={columns}
			className='-highlight'
			PaginationComponent={CustomReactTablePagination}
			defaultPageSize={6}
			totalEntries={totalEntries}
			recordStartIndex={recordStartIndex}
			recordEndIndex={recordEndIndex}
			recordsPerPage={6}
			currentPage={currentPage}
			changePage={changePage}
			noDataText={
				!loader
					? searchedValue
						? `No product found with the name containing "${searchedValue}"`
						: 'No products found!'
					: ''
			}
			style={{ display: 'flex', alignItems: 'center', cursor: 'auto' }}
		/>
        <DashboardFooter/>
        </>
	);
}
//Customizable Area End
