//Customizable Area Start
import React from 'react';
import {Link } from 'react-router-dom'
import BuyerNavbarWeb from '../../../blocks/dashboard/src/BuyerNavbar.web';
import SearchProductListController from './SearchProductListController.web';
import { Typography, CircularProgress } from '@material-ui/core';
//@ts-ignore
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../assets/ProductsListing.scss';
import Footer from '../../dashboard/src/Footer.web';
const prodSkel = require('../../../blocks/dashboard/assets/prodSkel.png');

export default class SearchProductList extends SearchProductListController {
	constructor(props: any){
		super(props);
	}
	render() {
        //@ts-ignore
        let {search} = this.props.location;
        let val = new URLSearchParams(search).get('search')
		return (
			<>
				<BuyerNavbarWeb />
				<div className="top-prod-container">
                    <div className="title">
                        <Typography
                            style={{
                                fontSize: "16px",
                                //@ts-ignore
                                fontWeight: "400",
                                fontFamily:'Poppins'
                            }}
                        >
                            Showing search results for{" "}
                            <span
                                style={{
                                    color:'#c45500',
                                    //@ts-ignore
                                    fontWeight: "600",
                                    fontFamily:'Poppins'
                                }}>
                                "{val}"
                            </span>
                        </Typography>
                    </div>
                    {!this.state.loader && this.state.dashboardData ? (
                        <>
                            <div className="product-section">
                                {this.state.dashboardData?.length !== 0 &&
                                    this.state.dashboardData.map((data: any, i: any) => {
                                        return (
                                            <div
                                                key={data.id}
                                                className="product-card"
                                                onClick={() => {
                                                    //@ts-ignore
                                                    this.props.history.push(
                                                        `/product-details/${data.id}`
                                                    );
                                                }}
                                            >
                                                <LazyLoadImage
                                                    src={data.attributes?.images[0].url}
                                                    alt=""
                                                    className="prod-img"
                                                    height={250}
                                                    style={{
                                                        borderRadius: "12px",
                                                    }}
                                                    effect="opacity"
                                                    placeholderSrc={prodSkel}
                                                />
                                                <>
                                                    <Typography className="card-prod-name">
                                                        {data.attributes.name}
                                                    </Typography>
                                                    <Typography className="card-prod-quan">
                                                        {data.attributes.value}{" "}
                                                        {data.attributes.quantity}
                                                    </Typography>
                                                    <Typography className="card-prod-price">
                                                        ₹{data.attributes.price}
                                                    </Typography>
                                                </>
                                            </div>
                                        );
                                    })}
                            </div>
                            {!this.state.productsListEnd && this.state.dashboardData && (
                                <div
                                    id="loader"
                                    style={{ display: "block", margin: "10px 48%" }}
                                >
                                    <CircularProgress />
                                </div>
                            )}
                        </>
                    ) : !this.state.loader && this.state.dashboardData === null ? (
                        <h2 style={{
                            margin:'80px 0 80px 40px',
                            fontSize: "32px",
                            //@ts-ignore
                            fontWeight: "600",
                            fontFamily:'Poppins',
                            minHeight:'100%'
                        }}>No Product Found</h2>
                    ) : (
                        <CircularProgress style={{ margin: "10% 49%" }} />
                    )}
                </div>
                {/* @ts-ignore */}
                <Footer hasData={this.state.dashboardData} />
			</>
		);
	}
}
//Customizable Area End
