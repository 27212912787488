//Customizable Area Start
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import DashboardFooter from "../../../components/src/DashboardFooter";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormControl, FormHelperText} from '@material-ui/core';
import axios from 'axios';
//@ts-ignore
import Cookies from 'js-cookie';
import '../assets/UserProfileWeb.scss'
import "../../../components/src/styles.scss";
import {menu,bitmap,copy,profile,closeIcon} from "./assets"
import {toast} from 'react-toastify';
import SidebarWeb from "../../../components/src/SidebarWeb.web";
import NavbarWeb from "../../../components/src/NavbarWeb.web";
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from '@material-ui/core/MenuItem';
//@ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
//import Menu from '@material-ui/core/Menu';
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItems,
  MenuPopover,
  MenuLink,
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import ResetPasswordModal from "../../../components/src/ResetPasswordModal.web";
import DeleteBankAccountModalweb from "../../../components/src/DeleteBankAccountModalweb.web";
import { DropzoneAreaBase } from 'material-ui-dropzone';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

toast.configure()
export const configJSON = require("./config");
const configURL = require('../../../framework/src/config')
type Anchor = 'top' | 'left' | 'bottom' | 'right';
const DashboardWeb= (props:any) => {
  const [branchName, setbranchName] = useState<any>('profile');
  const [addProd, setAddProd] = useState(false);
  const [totalLengthOfOrder, settotalLengthOfOrder] = useState<any>('');
  const [keepProfileView, setkeepProfileView] = useState<boolean>(true);
  const [value, setValue] = useState(0);
  const [openMenu, setopenMenu] = useState<boolean>(false);
  const [disablePass, setdisablePass] = useState<boolean>(false);
  const [addNewBank, setaddNewBank] = useState<boolean>(false);
  const [profileData, setprofileData] = useState<any>([]);
  const [openModal, setopenModal] = useState<boolean>(false);
  const [disableProfileBtn, setdisableProfileBtn] = useState<boolean>(false);
  const [profileImg, setprofileImg] = useState<any>('');
  const [profileImgType, setprofileImgType] = useState<any>('');
  const [profileImgPreview, setprofileImgPreview] = useState<any>('');
  const [profileRequire, setprofileRequire] = useState<boolean>(false);
  const [bankDetails, setbankDetails] = useState<any>([]);
  const [bankID, setbankID] = useState<any>('');
  const [deleteBankModal, setdeleteBankModal] = useState<boolean>(false);
  const [copiedText, setcopiedText] = useState<boolean>(false);
  const [sideMenu,setSideMenu] = useState<boolean>(false);
  const [existingPhone, setExistingPhone] = useState<any>(false)
  const setBranchName =(num:any)=>{
    setbranchName(num)
  }
  const theme = useTheme();
  const token = Cookies.get('Login_Token')
  const id = Cookies.get('Login_Account_Id')
  useEffect(() => {
    if(!token || !id){
      props?.history?.push("/login")
    }
    if(token) {
        getProfileDetails()
        //@ts-ignore
        if(props.location.state === 'verified'){
            toast.success('Phone number verified successfully!')
        }
        //@ts-ignore    
        props.history.push({state:''})
        getBankDetails()
    }
  },[]);
  const showSidebar = (childData:any)=>{
    setSideMenu(childData);
  }
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
        //@ts-ignore
        sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

  const doClose=async (value?:any)=>{
  setopenModal(false)
  setdeleteBankModal(false)
  }

  const validationsBank=  Yup.object().shape({
  bankName: Yup.string()
  .required(configJSON.bankNameRequired).matches(configJSON.nameReg,configJSON.nameInvalid),
  accountNumber:  Yup.string()
  .required(configJSON.bankAccountNumRequired)
  .matches(configJSON.bankAccountReg, configJSON.bankAccountNumValid),
  confirmAccountNumber:  Yup.string()
  .required(configJSON.bankConfrimAccountNumRequired)
  .oneOf(
    [Yup.ref("accountNumber"), null],
    configJSON.errorBothAccountsNotSame
  ),
  ifscCode:  Yup.string()
  .required(configJSON.codeIFSCRequired)
  .matches(configJSON.ifscReg, configJSON.codeIFSCValid),
  })


  const getProfileDetails=async ()=>{
  const id = Cookies.get('Login_Account_Id')
  
  await axios
    .get(`${configURL.baseURL}`+'/'+`${configJSON.getProfile}`+id,{
      headers:{
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "token":Cookies.get('Login_Token')
      }
    } )
    .then((res:any) => {
       setprofileData(res?.data?.data)
        setExistingPhone(res?.data?.data?.attributes?.account?.full_phone_number)
      //toast.success('Otp sent to your email address.',{position: toast.POSITION.BOTTOM_RIGHT})
    })
    .catch((err:any) => {
      if(err?.message==='Request failed with status code 500'){
        toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
      }
      if(err?.response?.data?.errors[0]){
      toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
      }
      if(err?.response?.data?.errors[0]){
        toast.error(err?.response?.data?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
        }
     
    })
  }

  const getBankDetails=async ()=>{
  
  await axios
  .get(`${configURL.baseURL}`+'/'+`${configJSON.bankDetailsUrl}`,{
    headers:{
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "token":Cookies.get('Login_Token')
    }
  } )
  .then((res:any) => {
    setbankDetails(res?.data?.data)
   })
  .catch((err:any) => {
    if(err?.message==='Request failed with status code 500'){
      toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
    }
    if(err?.response?.data?.errors[0]){
    toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
    }
    if(err?.response?.data?.errors[0]){
      toast.error(err?.response?.data?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
      }
   
  })
  }

  const converFileToBase64 = (fileObject:any) =>
    new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileObject);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const fileUploadHandler = async (e:  React.FormEvent<EventTarget>) => {
    let target = e.target as HTMLInputElement;
    let files = target.files as any;
    let url=URL.createObjectURL(files[0])
    setprofileImgPreview(url)
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
          let urlProfile;
          // eslint-disable-next-line no-await-in-loop
          const fileInBase64 = await converFileToBase64(file);
          if(file?.type==='image/jpeg'){
              //@ts-ignore
              urlProfile = fileInBase64.substr(23);
              setprofileImgType(file?.type)
              setprofileImg(urlProfile)
              setprofileRequire(false)
          }else if(file?.type==='image/png'){
              //@ts-ignore
              urlProfile = fileInBase64.substr(22);
              setprofileImgType(file?.type)
              setprofileImg(urlProfile)
              setprofileRequire(false)
          }
       
       
      }
  };

  function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
  }

  const keepProfile=(value:any)=>{
    setkeepProfileView(value)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    
    setValue(newValue);
    setcopiedText(false)
  };

  const list = (anchor: Anchor) => (
    <div className='list-div'>
        {/* order details */}
        <div className="bank-header">
      <List>
          <ListItem >
              <ListItemText primary={'Add new bank account'} className='title-lines'/>
          </ListItem>
      </List>
              <div className='div-closee'>
              <img src={closeIcon} alt="" className='img-close' onClick={()=>{
                  setaddNewBank(false)
              }} />
              </div>
      </div>
      <Formik
      initialValues={{
        ifscCode:"",
        bankName:"",
        accountNumber:"",
        confirmAccountNumber:""
      }}
      validationSchema={
        validationsBank
      }
      onSubmit={(values): any => 
        {
          doCreateBanksDetails(values)
        }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        setFieldValue
      }) => (
        //@ts-ignore
        <Form >
    <div className="auth-form-wrapper-profile">
    <div className="auth-form">
              <div className="bank-form-profile">
            <div>
            <h5 className="banke-details">Bank Name</h5>
              <TextField
                className="w-100"
                placeholder="Bank Name"
                variant="outlined"
                name="bankName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bankName}
                helperText={errors.bankName}
                error={touched.bankName && errors.bankName ? true : false}
              />
            </div><br />
            <div>
              <h5 className="banke-details">Account Number</h5>
              <TextField
              className="w-100"
                placeholder="Account Number"
                variant="outlined"
                name="accountNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.accountNumber}
                helperText={errors.accountNumber}
                error={touched.accountNumber && errors.accountNumber ? true : false}
              />
            </div>
            <br/>
            <div>
              <h5 className="banke-details">Confirm Account Number</h5>
              <TextField
              className="w-100"
                placeholder="Confirm Account Number"
                variant="outlined"
                name="confirmAccountNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmAccountNumber}
                helperText={errors.confirmAccountNumber}
                error={touched.confirmAccountNumber && errors.confirmAccountNumber ? true : false}
              />
            </div> 
            <br/>
            <div>
              <h5 className="banke-details">IFSC Code</h5>
              <TextField
              className="w-100"
                placeholder="IFSC Code"
                variant="outlined"
                name="ifscCode"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ifscCode}
                helperText={errors.ifscCode}
                error={touched.ifscCode && errors.ifscCode ? true : false}
              />
            </div>&nbsp;
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br />
            <br/>
            <div className="button-divider">
          <Button variant="contained"  className="prev-button"  onClick={()=>{
                  setaddNewBank(false)
              }}>
              CANCEL
            </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {disablePass?<Button variant="contained" className="login-button" disabled={disablePass}
             >
              <CircularProgress style={{color:'#FFFFFF'}}/>
            </Button>:(
            
            <Button variant="contained" className="login-button" disabled={disablePass} onClick={()=>{
              handleSubmit()
          }}>
             SAVE
           </Button>
           )}
          </div>
          
              </div>
            </div>
    </div>
  
          
        </Form>
      )}
    </Formik>
    </div>
  );

  const validations=  Yup.object().shape({
    name: Yup.string()
    .required(configJSON.nameRequire).
    min(8, configJSON.fullNameInvalid)
    .max(20, configJSON.fullNameInvalid).matches(configJSON.nameReg,configJSON.nameInvalid),
    phoneNumber:  Yup.string()
    .required(configJSON.phoneRequire).
    min(10, 'Phone number should be in length of 10 numbers'),
    companyName:  Yup.string()
    .required(configJSON.companyRequire).matches(configJSON.nameReg,configJSON.nameInvalid),
    gstNumber:   Yup.string()
    .required(configJSON.gstNumRequired)
    .matches(configJSON.gstNumberReg, configJSON.gstinInvalid),
    emailId: Yup.string()
    .required(configJSON.emailRequire)
    .matches(
    configJSON.emailRegex,
    configJSON.errorEmailNotValid
    ),
  })

  const validationsContact=  Yup.object().shape({
    messege: Yup.string()
    .required(configJSON.messegeRequire),
    emailId: Yup.string()
    .required(configJSON.emailRequire)
    .matches(
    configJSON.emailRegex,
    configJSON.errorEmailNotValid
    ),
  })

  const validationsChangePass=  Yup.object().shape({
    oldPassword: Yup.string()
    .required(configJSON.oldpassWordRequire)
    .min(8, configJSON.passwordMinlength)
    .matches(
      configJSON.passwordRegex,
      configJSON.passwordInValid
    ),
    newPassword:  Yup.string()
    .required(configJSON.newpassWordRequire)
    .min(8, configJSON.passwordMinlength)
    .matches(
      configJSON.passwordRegex,
      configJSON.passwordInValid
    ).notOneOf([Yup.ref("oldPassword"), null],
    configJSON.samePasswordErr),
    confirmNewpassword:  Yup.string()
    .required(configJSON.confirmpassWordRequire)
    .oneOf(
      [Yup.ref("newPassword"), null],
      configJSON.confirmPasswordInvalid
    ),
  })

  const doChangePass=async(value?:any)=>{
    setdisablePass(true)
    try {
      const data={
        "data": {
          "current_password": value?.oldPassword,
          "new_password": value?.newPassword
      }
      }
      await axios
      .put(`${configURL.baseURL}`+'/'+`${configJSON.updatePassEndpoint}`,data,{
        headers:{
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "token":Cookies.get('Login_Token')
        }
      } )
      .then((res:any) => {
        setopenModal(true)
        setdisablePass(false)
       })
      .catch((err:any) => {
        if(err?.message==="Request failed with status code 422")
        {
          toast.error("Current Password is Wrong",{position: toast.POSITION.BOTTOM_RIGHT})
        }
        if(err?.message==='Request failed with status code 500'){
          toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        if(err?.response?.data?.errors[0]){
        toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        if(err?.response?.data?.errors[0]){
          toast.error(err?.response?.data?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
          }
          setdisablePass(false)
       // setdisable(false)
      }).finally(() => {
       // setdisable(false)
       })
    } catch (error) {
      
    }
  }

  const doSubmitProfile=async (value?:any)=>{
    // if( profileImgType && profileImg){
    setdisableProfileBtn(true)
    const id = Cookies.get('Login_Account_Id')
    let data={};
    if( profileImgType && profileImg){
      data = {
        "seller_account": {
          "company_name": value?.companyName,
          "gstin_number": value?.gstNumber
      },
      "account": {
          "fullname": value?.name,
          "email":value?.emailId,
          "full_phone_number":'+' + value?.phoneNumber
      },
      "image": {
          "avatar": {
              "url": profileImg,
              "content_type": profileImgType
          }
      }
      }
    }else{
      data = {
        "seller_account": {
          "company_name": value?.companyName,
          "gstin_number": value?.gstNumber
      },
      "account": {
          "fullname": value?.name,
          "email":value?.emailId,
          "full_phone_number":'+' + value?.phoneNumber
      },
      "image": {
          "avatar": {
          }
      }
      }
    }

    await axios
      .put(`${configURL.baseURL}`+'/'+`${configJSON.getProfile}`+id,data,{
        headers:{
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "token":Cookies.get('Login_Token')
        }
      } )
      .then((res:any) => {
        setdisableProfileBtn(false)
        setprofileData(res?.data?.data)
        setprofileImgPreview('')
        if(existingPhone !== value.phoneNumber || Cookies.get('is_otp_verified') === 'false'){
            Cookies.set('is_otp_verified', false)
            Cookies.set('Signup_Phone', value?.phoneNumber)
            props.history.push({pathname:'/otp-verification', state:'merchant-profile'})
        }
        toast.success('Profile details are updated',{position: toast.POSITION.BOTTOM_RIGHT});
        (existingPhone !== value.phoneNumber || Cookies.get('is_otp_verified') === 'false') && toast.success('Please verify phone number',{position: toast.POSITION.BOTTOM_RIGHT})
      })
      .catch((err:any) => {
        if(err?.response?.data?.errors[0]==="Full phone number has already been taken")
        {
        toast.error("Mobile number is already in use",{position: toast.POSITION.BOTTOM_RIGHT})
        }
        else if(err?.response?.data?.errors[0]?.account){
        toast.error(err?.response?.data?.errors[0].account,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        else
        {
          toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        // if(err?.response?.data?.errors[0]){
        //   toast.error(err?.response?.data?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
        //   }
          setdisableProfileBtn(false)
      })
  }
  const doSubmitContactUsDetail=async (value?:any)=>{
  setdisablePass(true)
  const data = {
    "data": {
      "email": value?.emailId,
      "description": value?.messege
  }
  }
  
  await axios
    .post(`${configURL.baseURL}`+'/'+`${configJSON.contactUsUrl}`,data,{
      headers:{
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "token":Cookies.get('Login_Token')
      }
    } )
    .then((res:any) => {
      setdisablePass(false)
      toast.success('Details are updated.',{position: toast.POSITION.BOTTOM_RIGHT})
    })
    .catch((err:any) => {
      if(err?.message==='Request failed with status code 500'){
        toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
      }
      if(err?.response?.data?.errors[0]){
      toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
      }
      if(err?.response?.data?.errors[0]){
        toast.error(err?.response?.data?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
        }
      setdisablePass(false)
    })
  }
  const doCreateBanksDetails=async (value?:any)=>{
    setdisablePass(true)
    const data = {
      "bank_details": {
        "account_name": '',
        "bank_name": value?.bankName,
        "account_number": value?.accountNumber,
        "confirm_account_number": value?.confirmAccountNumber,
        "ifsc_code": value?.ifscCode,
        "primary": true
    }
    }
    
    await axios
      .post(`${configURL.baseURL}`+'/'+`${configJSON.bankDetailsUrl}`,data,{
        headers:{
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "token":Cookies.get('Login_Token')
        }
      } )
      .then((res:any) => {
        setdisablePass(false)
        setaddNewBank(false)
        getBankDetails()
        toast.success('Bank details are added',{position: toast.POSITION.BOTTOM_RIGHT})
      })
      .catch((err:any) => {
        if(err?.message==='Request failed with status code 500'){
          toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        if(err?.response?.data?.errors[0]){
        toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        if(err?.response?.data?.errors[0]){
          toast.error(err?.response?.data?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
          }
        setdisablePass(false)
      })
  }

  const makePriamry=async(id?:any)=>{
    
    try {
      const data={
        "bank_details": {
          "primary": true
      }
      }
      await axios
      .put(`${configURL.baseURL}`+'/'+`${configJSON.bankDetailsUrl}`+'/'+id,data,{
        headers:{
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "token":Cookies.get('Login_Token')
        }
      } )
      .then((res:any) => {
        getBankDetails()
        toast.success('This bank account is now primary',{position: toast.POSITION.BOTTOM_RIGHT})
       })
      .catch((err:any) => {
        if(err?.message==='Request failed with status code 500'){
          toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        if(err?.response?.data?.errors[0]){
        toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        if(err?.response?.data?.errors[0]){
          toast.error(err?.response?.data?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
          }
          setdisablePass(false)
       // setdisable(false)
      }).finally(() => {
       // setdisable(false)
       })
    } catch (error) {
      
    }
  }
  const deleteBankAccount=async(id:any,closeProgress:any)=>{
    closeProgress()
   await axios.delete(`${configURL.baseURL}`+'/'+`${configJSON.bankDetailsUrl}`+'/'+id,{
    headers:{
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "token":Cookies.get('Login_Token')
    }
  } ).then((res:any) => {
      setdeleteBankModal(false)
      closeProgress()
      getBankDetails()
      toast.success('This bank account is deleted',{position: toast.POSITION.BOTTOM_RIGHT})
     })
    .catch((err:any) => {
      if(err?.message==='Request failed with status code 500'){
        toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
      }
      if(err?.response?.data?.errors[0]){
      toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
      }
      if(err?.response?.data?.errors[0]){
        toast.error(err?.response?.data?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        closeProgress()
     // setdisable(false)
    }).finally(() => {
      closeProgress()
     })
  }

  const renderProfileImage = () => {
    if (!profileImgPreview && !profileData?.attributes?.avatar?.url) return 'https://dreamvilla.life/wp-content/uploads/2017/07/dummy-profile-pic.png'
    else if (profileImgPreview) return profileImgPreview
    else return profileData?.attributes?.avatar?.url
  }

  return (
    <div className="containerDas">
    <div className="bannerDas w-50">
    <SidebarWeb openSidebar={sideMenu} changeIconValue={showSidebar} setBranchName={setBranchName} keepProfileView={keepProfileView} keepProfile={keepProfile} history={props?.history}/>
      {/* <p className="rights-reserved">
        Grow Cart © 2021. All Rights Reserved
      </p> */}
    </div>
    <div className="auth-form-wrapper w-50">
    
        <NavbarWeb changeIconValue={showSidebar} branchName={branchName} addProd={addProd} setAddProd={setAddProd} totalLengthOfOrder={totalLengthOfOrder} keepProfile={keepProfile} history={props?.history} profileData={profileData}/>
        <Box 
      //@ts-ignore
    //  sx={{ width: '100%' }}
        
      >
      <Box 
      //@ts-ignore
      className="table-box"
      sx={{ borderBottom: 1, borderColor: 'divider' }}>

        <Tabs value={value} 
        //@ts-ignore
        onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Profile" {...a11yProps(0)} />
          <Tab label="change Password" {...a11yProps(1)} />
          <Tab label="Bank details" {...a11yProps(2)} />
          <Tab label="Referrals" {...a11yProps(3)} />
          <Tab label="Contact Us" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
       <div className="profile-div">
              <div className="div-profile-img">
                <img className="img-profile" src={renderProfileImage()} alt="" />
                <label className="img-profile" htmlFor="icon-button-file">
                  <div className="update-icon-container">
                    <img className="update-icon" src={profile} alt="" aria-label="upload picture" />
                  </div>
                </label>
                <div style={{ width: "50%", margin: "0 auto", position: "relative" }}>

                  <input
                    accept=".png, .jpg, .jpeg"
                    id="icon-button-file"
                    type="file"
                    style={{ display: "none" }}
                    name="profilePic"
                    onChange={(event: any) => {
                      fileUploadHandler(event)
                      // setprofileImg(event)
                    }}
                  />
                </div>
              </div>
      
        <Formik
      initialValues={{
        gstNumber:profileData?.attributes?.gstin_number,
        name:profileData?.attributes?.account?.fullname,
        phoneNumber:profileData?.attributes?.account?.full_phone_number,
        companyName:profileData?.attributes?.company_name,
        emailId:profileData?.attributes?.account?.email
      }}
      validationSchema={
        validations
      }
      onSubmit={(values): any => 
        {
          doSubmitProfile(values)
        }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        setFieldValue
      }) => (
        //@ts-ignore
        <Form >
        <div className="auth-form-wrapper-profile">
        <div className="auth-form">
                <div className="login-form-profile">
                <h5 className="banke-details">Name</h5>
                <TextField
                    className="w-100"
                    placeholder="Name"
                    variant="outlined"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    helperText={errors.name}
                    error={touched.name && errors.name ? true : false}
                />
                <br/><br />
                  <FormControl>
                  <h5 style={{marginBottom: 5}} className="banke-details">Phone</h5>
                    <PhoneInput
                    //@ts-ignore
                      id='phoneNumber'
                      name="phoneNumber"
                      country={'in'}
                      value={values.phoneNumber}
                      onlyCountries={['in']}
                      onChange={(e) => setFieldValue('phoneNumber', e)}
                      error={touched.phoneNumber && errors.phoneNumber ? true : false}
                      helperText={errors.phoneNumber}
                      countryCodeEditable={false}
                      isValid={(value, country:any) => 
                        {
                          //@ts-ignore
                          const phoneLenght = value.replace(country.countryCode, "")?.length
                          if(phoneLenght < 10) return 'Please enter valid phone number'
                          else return true
                        }}
                    />
                   {touched.phoneNumber && errors.phoneNumber && <FormHelperText id="my-helper-text">{errors.phoneNumber}</FormHelperText>}
                  </FormControl>
                  <br />
                {/* <div>
                <h5 className="banke-details">Phone</h5>
                <TextField
                className="w-100"
                    placeholder="Phone Number"
                    variant="outlined"
                    name="phoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                    helperText={errors.phoneNumber}
                    error={touched.phoneNumber && errors.phoneNumber ? true : false}
                />
                </div> */}
                <br/>
                <div>
                <h5 className="banke-details">Email ID</h5>
                <TextField
                className="w-100"
                    placeholder="Email ID"
                    variant="outlined"
                    name="emailId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emailId}
                    helperText={errors.emailId}
                    error={touched.emailId && errors.emailId ? true : false}
                />
                </div> 
                <br/>
                <div>
                <h5 className="banke-details">Company Name</h5>
                <TextField
                className="w-100"
                    placeholder="Company Name"
                    variant="outlined"
                    name="companyName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyName}
                    helperText={errors.companyName}
                    error={touched.companyName && errors.companyName ? true : false}
                />
                </div> 
                <br/>
                <div>
                <h5 className="banke-details">GST Number</h5>
                <TextField
                className="w-100"
                    placeholder="GST Number"
                    variant="outlined"
                    name="gstNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.gstNumber}
                    helperText={errors.gstNumber}
                    error={touched.gstNumber && errors.gstNumber ? true : false}
                />
                </div>
                <br/>
              
                <div>
                 {disableProfileBtn?<Button variant="contained" className="login-button" disabled={disableProfileBtn}>
                <CircularProgress className="loader"/>
               </Button>:<Button variant="contained" className="login-button" disabled={disableProfileBtn} 
                onClick={(e) => {
                    handleSubmit();
                }}>
                    SAVE
                </Button>}
                </div>
                </div>
        </div>
        </div>      
        </Form>
        )}
        </Formik>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <div className="password-div">
        <Formik
      initialValues={{
        oldPassword:"",
        newPassword:"",
        confirmNewpassword:"",
      }}
      validationSchema={
        validationsChangePass
      }
      onSubmit={(values): any => 
        {
          doChangePass(values)
        }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        setFieldValue
      }) => (
        //@ts-ignore
        <Form >
        <div className="auth-form-wrapper-profile">
        <div className="auth-form">
                <div className="login-form-profile">
                <h5 className="banke-details">Old Password</h5>
                <TextField
                    className="w-100"
                    placeholder="Old Password"
                    variant="outlined"
                    name="oldPassword"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.oldPassword}
                    helperText={errors.oldPassword}
                    error={touched.oldPassword && errors.oldPassword ? true : false}
                />
                <br/><br />
                <div>
                <h5 className="banke-details">New Password</h5>
                <TextField
                className="w-100"
                    placeholder="New Password"
                    variant="outlined"
                    name="newPassword"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                    helperText={errors.newPassword}
                    error={touched.newPassword && errors.newPassword ? true : false}
                />
                </div>
                <br/>
                <div>
                <h5 className="banke-details">Confirm New Password</h5>
                <TextField
                className="w-100"
                    placeholder="Confirm New Password"
                    variant="outlined"
                    type="password"
                    name="confirmNewpassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmNewpassword}
                    helperText={errors.confirmNewpassword}
                    error={touched.confirmNewpassword && errors.confirmNewpassword ? true : false}
                />
                </div> 
                <br/>
              
                <div>
                 {disablePass?<Button variant="contained" className="login-button" disabled={disablePass}>
                <CircularProgress className="loader"/>
               </Button>:<Button variant="contained" className="login-button"  disabled={disablePass}
                onClick={(e) => {
                    handleSubmit();
                }}>
                    UPDATE
                </Button>}
                </div>
                </div>
        </div>
        </div>      
        </Form>
        )}
        </Formik>
        <ResetPasswordModal openModal={openModal} doClose={doClose} history={props?.history} userProfile={'userProfile'}/>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <div className="password-div">
        { 
        bankDetails?.map((bank:any,index:number) => {
          return(
            <>
       <div className="bank-details-data" key={index}>
        {/* <h4 className="data-bank" key={index+'in'}>{bank?.attributes?.bank_name} {bank?.attributes?.primary&&<div className="content-bank">Primary</div>}<img className="menuImg" src={menu} alt="" onClick={()=>{setopenMenu(true);setbankID(bank?.id)}}/></h4> */}
        <Menu className="bank-primary-menu" key={index+'menu'}>
          <h4 className="data-bank" key={index+'in'}>
          {bank?.attributes?.bank_name} {bank?.attributes?.primary&&<div className="content-bank">Primary</div>}
          <MenuButton className="menuButton" key={index+'menuButton'}>
          <img className="menuImg" key={index+'menuImg'} src={menu} alt="" onClick={()=>{setopenMenu(true);setbankID(bank?.id)}}/>
          </MenuButton></h4>
          <MenuList  style={{width: "240px"}} key={index+'menuList'}>
            {!bank?.attributes?.primary&&
          <MenuItem onClick={()=>{
          setopenMenu(false)
          makePriamry(bank?.id)
          }} key={index+'menuPrimary'}>Make Primary</MenuItem>}
          <MenuItem onClick={()=>{
          setopenMenu(false)
          setdeleteBankModal(true)
          }} key={index+'menuDel'}>Delete Account</MenuItem>
          </MenuList>
      </Menu>
      <div key={index+'div'}>
        <h4 className="account-detail" key={index+'h4'}>Account Name</h4>
        <h4 className="bank-account-detail-h4" key={index+'bank-h4'}>{bank?.attributes?.account_name||'-'}</h4>
      </div>
      <div className="account-number-details" key={index+'details'}>
        <div>
        <h4 className="account-detail" key={index+'account'}>Account Number</h4>
        <h4 className="bank-account-detail-h4" key={index+'number'}>{bank?.attributes?.account_number}</h4>
        </div>
        <div>
          <h4 className="account-detail"key={index+'ifsc'}>IFSC Code</h4>
          <h4 className="bank-account-detail-h4" key={index+'ifsc-code'}>{bank?.attributes?.ifsc_code}</h4>
        </div>
      </div>
        </div><br /></>
          )
        })
        }
        <h4 className="add-new-bank" onClick={()=>setaddNewBank(true)}>+ Add new bank account</h4>
        </div>
        <div>
        <React.Fragment >
        <div className='main-div'>
          <Drawer
            anchor={'right'}
            open={addNewBank}
          >
            {list('right')}
          </Drawer>
          </div>
        </React.Fragment>
    </div>
    <DeleteBankAccountModalweb deleteBankModal={deleteBankModal} doClose={doClose} history={props?.history} bankID={bankID} deleteBankAccount={deleteBankAccount} disablePass={disablePass}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <div className="refferal-div">
       <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img src={bitmap} className="refferal-image" alt="" />
        <div className="h4-ref-div">
        <h4 className="ref-h4">Refer your friends & get 10% discounts 
         on Products.
        </h4>
        <div className="ref-code-div">
        <h4 className="h4-ref-code">
        {profileData?.attributes?.account?.share_key||'-'} 
        <CopyToClipboard text={profileData?.attributes?.account?.share_key}
          onCopy={(val:any) => setcopiedText(true)}>
          <img src={copy} alt="" className="img-ref" />
        </CopyToClipboard>
        </h4>
        </div>{copiedText&&<p className="copy-p">Copied &#x2713;</p>}
        <h4 className="ref-h4-2">Your Referral Code</h4>
        <Button variant="contained" className="login-button" >
                    SHARE NOW
                </Button>
        </div>
      </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <div className="password-div">
        <Formik
      initialValues={{
        emailId:"",
        messege:""
      }}
      validationSchema={
        validationsContact
      }
      onSubmit={(values): any => 
        {
          doSubmitContactUsDetail(values)
        }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        setFieldValue
      }) => (
        //@ts-ignore
        <Form >
        <div className="profile-tabs-content">
        <div className="auth-form-wrapper-profile">
        <div className="auth-form">
                <div className="login-form-profile">
                <h5 className="banke-details">Email</h5>
                <TextField
                    className="w-100"
                    placeholder="Email"
                    variant="outlined"
                    name="emailId"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emailId}
                    helperText={errors.emailId}
                    error={touched.emailId && errors.emailId ? true : false}
                />
                <br/>
                <br/>
                <div>
                <h5 className="banke-details">Message</h5>
                <TextField
                className="w-100 multiline-text"
                    placeholder="Message"
                    variant="outlined"
                    multiline
                    rows={5}
                    name="messege"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.messege}
                    helperText={errors.messege}
                    error={touched.messege && errors.messege ? true : false}
                />
                </div> 
                <br/>
              
                <div>
                 {disablePass?<Button variant="contained" className="login-button" disabled={disablePass}>
                <CircularProgress className="loader"/>
               </Button>:<Button variant="contained" className="login-button" disabled={disablePass}
                onClick={(e) => {
                    handleSubmit();
                }}>
                    UPDATE
                </Button>}
                </div>
                </div>
        </div>
        </div>   
        </div>   
        </Form>
        )}
        </Formik>
        </div>
      </TabPanel>
    </Box>
    {/* <div className="footer-line">
    <p className="grow-cart-auth">Grow Cart @ 2021. All Rights Reserved</p>
    <p className="terms-privacy">
    Tearm & Condition | Privacy policy
    </p>
    </div> */}
    {/* <div className="footer-line">
            <p className="grow-cart-auth">Grow Cart @ 2021. All Rights Reserved</p>
            <p className="terms-privacy">
            <p onClick={()=>{props?.history.push("/terms")}}>Tearm & Condition</p>&nbsp; <p>|</p> <p onClick={()=>{props?.history.push("/privacy")}}> &nbsp; Privacy policy</p>
            </p>
            </div> */}
            <div className="footer-space">
            <DashboardFooter/>
            </div>
     </div>
   
  </div>
  
  );
};
export default DashboardWeb;

//Customizable Area End
