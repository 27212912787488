// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  history:any
}
interface S {
  email: string;
  message: string;
  loader: boolean;
}
interface SS {
  id: any;
}

export default class ContactUsBuyerController extends BlockComponent<Props, S, SS> {
    apiContactUsBuyerFormId:string
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      email: '',
      message: '',
      loader: false
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  submitContactForm(values: any): boolean {
    this.setState({
        loader: true
    })

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
    "Content-Type": "application/json",
    token: Cookies.get('Login_Token')
    };

    this.apiContactUsBuyerFormId = requestMessage.messageId;

    const data = {
        "data": {
          "email": values?.email,
          "description": values?.message
      }
      }
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    'bx_block_contact_us/contacts'
    );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
    );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(data)
    );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'POST'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.apiContactUsBuyerFormId &&
            responseJson !== undefined
          ){
             this.setState({
                loader: false,

             })
             toast.success('Message sent successfully')
          }
        }
      } else {
          
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
       
        if (errors[0]?.token === 'Invalid token' || errors[0]?.token==="Token has Expired" ) {
          Cookies.remove('Login_Token')
           //@ts-ignore
           this.props.history.push({
            pathname: '/login'
          })
          // this.setState({ loginLoader: false })
          toast.error(errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
      } 
      }
    }
    // Customizable Area End
  }

}
// Customizable Area End