import React, { useState, useEffect } from "react";
import { getToken } from "./firebaseInit";
import Cookies from 'js-cookie';
const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);

  // To load once
  useEffect(() => {
    if(!Cookies.get('push_notification_token')){
      let data;

      async function tokenFunc() {
        data = await getToken(setTokenFound);
        if (data) {
          Cookies.set('push_notification_token', data)
        }
        return data;
      }
  
      tokenFunc();
    }

  });

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;
