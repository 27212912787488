// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  history: any
}
interface S {
  progress: boolean;

}
interface SS {
  id: any;
}

export default class LogOutModalWebController extends BlockComponent<Props, S, SS> {
  logoutApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      progress: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleLogout(): boolean {
    const header = {
      "token": Cookies.get('Login_Token')
    };

    const formData = new FormData();
    formData.append('account[device_id]', Cookies?.get('push_notification_token'));

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.logoutApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarPostApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.removeTokens()
    return true;
  }

  removeTokens = async () => {
    var cookies = await document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    await localStorage.removeItem('user_type')
    await Cookies.remove('Login_Token')
    await Cookies.remove('is_otp_verified')
    await Cookies.remove('is_bank_details_filled')
    await Cookies.remove('is_document_uploaded')
    await Cookies.remove('is_otp_verified')
    await Cookies.remove('Signup_Email')
    await Cookies.remove('Signup_Phone')
    await Cookies.remove('Login_Phone')
    await Cookies.remove('account_activated')
    await Cookies.remove('Login_Email')
    await Cookies.remove('Login_Account_Id')
    await Cookies.remove('user_type')
    await Cookies.remove('push_notification_token')
    //@ts-ignore
    await this.props?.closeLogoutModal()
    await this.props?.history?.push("/login")
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (apiRequestCallId === this.logoutApiCallId) {
          }
        }
      } else {
        // this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
      }
    }
  }



}
// Customizable Area End