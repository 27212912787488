// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
export const configJSON = require('./config.js')

// export const configJSON = require("./config.js");

export interface Props {
//   navigation: any;
//   id: string;
//   history: any
}
interface S {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    oldErr: boolean;
    newErr: boolean;
    newSameErr: boolean;
    confirmNewErr: boolean;
    loader: boolean;
    changePassModal: boolean;
}
interface SS {
  id: any;
}

export default class BuyerChangePassController extends BlockComponent<Props, S, SS> {

    ApiChangePasswordId: string = ''
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.updatePassword = this.updatePassword.bind(this)
        console.disableYellowBox = true;
        
        this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            oldErr: false,
            newErr: false,
            newSameErr: false,
            confirmNewErr: false,
            loader: false,
            changePassModal: false
        };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

updatePassword(){
    if(this.state.oldPassword === '' ||
    this.state.newPassword === '' ||
    this.state.confirmNewPassword === '' ||
    this.state.oldErr === true ||
    this.state.newErr === true ||
    this.state.newSameErr === true ||
    this.state.confirmNewErr === true){
        return toast.error('please fill all the fields correctly')
    }
    this.setState({
        loader: true
    })
    const header = {
        "Content-Type": 'application/json',
        token: Cookies.get('Login_Token')
    };

    const data = {
        "current_password": this.state.oldPassword,
        "new_password": this.state.confirmNewPassword
    }

    const httpBody = {
        data: data
    }

    const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ApiChangePasswordId = requestMessage.messageId;
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    'update_password'
    );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
    );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'PUT'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}

async receive(from: string, message: Message) {
    try {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
            );
            const apiid = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage),
            );
            if (responseJson && !responseJson.errors  && !responseJson.error) {
                if(apiid === this.ApiChangePasswordId){
                    this.setState({
                        oldPassword: '',
                        newPassword: '',
                        confirmNewPassword: '',
                        loader: false,
                        changePassModal: true
                    })
                }
            } else {
              if(responseJson?.errors[0]){
                toast.error('Current password is incorrect!')
              }
              else{
                toast.error('Something went wrong')
              }
              this.setState({
                loader: false
            })
              
            }
        }
    } catch (error) {}
}


  

}
// Customizable Area End