// Customizable Area Start
import React from 'react';
import BuyerMyAccountController, {
	Props,
} from './BuyerMyAccountController.web';
import 'react-toastify/dist/ReactToastify.css';
import {
	Typography,
	Button,
	Modal,
	Box,
	CircularProgress,
} from '@material-ui/core';
import BuyerNavbarWeb from '../../dashboard/src/BuyerNavbar.web';
import BuyerSidebar from '../../info-page/src/BuyerSidebar.web';
import '../assets/BuyerMyAccount.scss';
import { threeDots } from '../../ProductQuickview/src/assets';
import AddNewAddress from './AddNewAddress.web';
import FooterWeb from '../../dashboard/src/Footer.web';
const trash = require('../../../components/src/trash.jpg');

export default class BuyerMyAccount extends BuyerMyAccountController {
	constructor(props: Props) {
		super(props);
	}

	render() {
		return (
			<>
				<BuyerNavbarWeb />
				<div className='buyer-container'>
                    {/* @ts-ignore */}
					<BuyerSidebar activeTab={2}/>
					{!this.state.loader && (
						<div className='profile-container'>
							<div>
								<Typography className='top-heading'>
									Manage Addresses
								</Typography>
							</div>
							{/* @ts-ignore */}
																			 
							<AddNewAddress openModal='add' newAddressAddedCallback={() => this.getAddresses()}/>
							{this.state.allAddresses &&
							this.state.allAddresses.length !== 0 ? (
								<>
									{/* DEFAULT ADDRESS */}
									<div className='addr-block-container'>
										<Typography className='addr-heading'>
											DEFAULT ADDRESS
										</Typography>
										{this.state.allAddresses.map((address: any) => {
											if (address.attributes.is_default) {
												return (
													<div className='addr-block'>
														<div className='name-phone'>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'space-between',
																	marginBottom: '10px',
																}}
															>
																<Typography className='person-name'>
																	{address.attributes.name}
																	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																	{address.attributes.phone_number}
																</Typography>
																<img
																	src={threeDots}
																	alt=''
																	width={30}
																	style={{
																		cursor: 'pointer',
																	}}
																	onClick={() => {
																		this.setState({
																			clickedRowId: address.id,
																			isSearchModalOpen: true,
																		});
																		setTimeout(() => {
                                                                            let elem = document.querySelector(
                                                                                '.info-modal',
                                                                                )
                                                                            // @ts-ignore
                                                                            elem.style.display = 'block'; 
																		}, 0);
																	}}
																/>
															</div>
															<Typography className='address'>
																Address - {address.attributes.address},{' '}
																{address.attributes.city},{' '}
																{address.attributes.state}, India
															</Typography>
															{address.attributes.landmark && (
																<Typography className='landmark'>
																	Landmark - {address.attributes.landmark}
																</Typography>
															)}
															<Typography className='pincode'>
																Pincode - {address.attributes.zip_code}
															</Typography>
															{this.state.isSearchModalOpen &&
																address.id === this.state.clickedRowId && (
																	<div className='info-modal'>
																		<div
																			className='overlay'
																			onClick={() => {
																				this.setState({
																					isSearchModalOpen: false,
																				});
																			}}
																		/>
																		<AddNewAddress
                                                                        //@ts-ignore
																			openModal='edit'
																			addressID={address.id}
																			newAddressAddedCallback={() => this.getAddresses()} 
																		/>
																	</div>
																)}
														</div>
													</div>
												);
											}
										})}
									</div>
									{/* OTHER ADDRESS */}
									<div className='addr-block-container'>
										<Typography className='addr-heading other-addr'>
											OTHER ADDRESS
										</Typography>
										{this.state.allAddresses.map((address: any) => {
											if (!address.attributes.is_default) {
												return (
													<div className='addr-block'>
														<div className='name-phone'>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'space-between',
																	marginBottom: '10px',
																}}
															>
																<Typography className='person-name'>
																	{address.attributes.name}
																	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																	{address.attributes.phone_number}
																</Typography>
																<img
																	src={threeDots}
																	alt=''
																	width={30}
																	style={{
																		cursor: 'pointer',
																	}}
																	onClick={() => {
																		this.setState({
																			clickedRowId: address.id,
																			isSearchModalOpen: true,
																		});
																		setTimeout(() => {
																			let elem = document.querySelector(
                                                                                '.info-modal',
                                                                                )
                                                                            // @ts-ignore
                                                                            elem.style.display = 'block';
																		}, 0);
																	}}
																/>
															</div>
															<Typography className='address'>
																Address - {address.attributes.address},{' '}
																{address.attributes.address_line_2},{' '}
																{address.attributes.city},{' '}
																{address.attributes.state}, India
															</Typography>
															{address.attributes.landmark && (
																<Typography className='landmark'>
																	Landmark - {address.attributes.landmark}
																</Typography>
															)}
															<Typography className='pincode'>
																Pincode - {address.attributes.zip_code}
															</Typography>
															{this.state.isSearchModalOpen &&
																address.id === this.state.clickedRowId && (
																	<div className='info-modal'>
																		<div
																			className='overlay'
																			onClick={() => {
																				this.setState({
																					isSearchModalOpen: false,
																				});
																			}}
																		/>
																		<AddNewAddress
																		//@ts-ignore
																			openModal='edit'
																			addressID={address.id}
																			newAddressAddedCallback={() => this.getAddresses()}
																		/>

																		<Typography
																			style={{
																				margin: '5px 5px',
																				cursor: 'pointer',
																				padding: '2px 5px',
																			}}
																			onClick={() => {
																				this.setState({
																					openDeleteModal: true,
																					isSearchModalOpen: false,
																					selectedAddrId: address.id,
																				});
																			}}
																		>
																			Delete Address
																		</Typography>
																		<Typography
																			style={{
																				margin: '5px 5px',
																				cursor: 'pointer',
																				padding: '2px 5px',
																			}}
																			onClick={() => {
																				this.setState({
																					isSearchModalOpen: false,
																				});
																				this.makePrimary(address.id);
																			}}
																		>
																			Make Default
																		</Typography>
																	</div>
																)}
														</div>
													</div>
												);
											}
										})}
									</div>
								</>
							) : (
								<Typography className='addr-heading'>
									No Address Found
								</Typography>
							)}
						</div>
					)}
				</div>
                <FooterWeb />
				{this.state.openDeleteModal && (
					//@ts-ignore
					<Modal
						open={this.state.openDeleteModal}
						aria-labelledby='modal-modal-title'
						aria-describedby='modal-modal-description'
					>
						<Box className='box-modal'>
							<div className='div-modal'>
								<img src={trash} alt='' />
							</div>
							<h4 className='div-modal-h4'>Delete Address</h4>
							<Typography>
								<p className='p-tag-modal'>
									This address will be removed permanently. Are you sure you
									want to delete it?
								</p>
							</Typography>
							<div
								style={{
									textAlign: 'center',
									marginBottom: '25px',
									display: 'flex',
                                    justifyContent:'space-between'
								}}
							>
								<Button
									variant='contained'
									className='modal-cancel-btn'
									onClick={() => {
										this.setState({
											openDeleteModal: false,
										});
									}}
								>
									Cancel
								</Button>
								&nbsp;
								<Button
									variant='contained'
									className='modal-remove-btn'
									onClick={() => {
										this.deleteAddress(this.state.selectedAddrId);
									}}
								>
									{this.state.deleteLoader ? (
										<CircularProgress />
									) : (
										'Delete'
									)}
								</Button>
							</div>
						</Box>
					</Modal>
				)}
			</>
		);
	}
}
// Customizable Area End