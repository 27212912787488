// Customizable Area Start
import React, { useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import "./styles.scss";

interface CustomPaginaionProps {
  id: string;
  className?: string;
  totalEntries: number;
  currentEntries: number;
  currentPage: number;
  recordStartIndex: number;
  recordEndIndex: number;
  recordsPerPage: number;
  changePage: () => void;
}

const CustomReactTablePagination = (props: CustomPaginaionProps) => {
  const {
    id,
    className,
    totalEntries,
    currentPage,
    recordStartIndex,
    recordEndIndex,
    recordsPerPage,
    changePage,
  } = props;

  return (
    <div className="pagination-wrapper">
      <span>
        Showing {recordStartIndex} to {recordEndIndex} of {totalEntries} entries
      </span>
      <Pagination
        id={id}
        className={className}
        siblingCount={1}
        count={Math.ceil(Number(totalEntries / recordsPerPage))}
        page={currentPage}
        onChange={changePage}
        variant="outlined"
        shape="rounded"
      />
    </div>
  );
};

export default CustomReactTablePagination;
// Customizable Area End
