// Customizable Area Start
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, CircularProgress } from '@material-ui/core'
import '../assets/PrvacyPolicyWeb.scss';
import BuyerNavbarWeb from './BuyerNavbar.web';
import FooterWeb from './Footer.web';
import ContactUsBuyerController, {
	Props,
} from './ContactUsBuyerController.web';
const configJSON = require('./config');
import '../assets/ContactUs.scss'
export default class ContactUsBuyer extends ContactUsBuyerController {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const validationsContact = Yup.object().shape({
			message: Yup.string().required(configJSON.messegeRequire),
			email: Yup.string()
				.required(configJSON.emailRequire)
				.matches(configJSON.emailRegex, configJSON.errorEmailNotValid),
		});
		return (
			<>
				<BuyerNavbarWeb />
				<div className='password-div1'>
					<Formik
						initialValues={{
							email: '',
							message: '',
						}}
						validationSchema={validationsContact}
						onSubmit={(values): any => {
							this.submitContactForm(values);
                            values.email= '',
                            values.message = ''
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleSubmit,
							isSubmitting,
							handleBlur,
							setFieldValue,
						}) => (
							//@ts-ignore
							<Form>
								<div className='profile-tabs-content'>
									<div className='auth-form-wrapper-profile'>
										<div className='auth-form'>
											<div className='login-form-profile'>
												<h5 className='banke-details'>Email</h5>
												<TextField
													className='w-100'
													placeholder='Contact Email'
													variant='outlined'
													name='email'
													type='text'
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.email}
													helperText={errors.email}
													error={
														touched.email && errors.email ? true : false
													}
												/>
												<br />
												<br />
												<div>
													<h5 className='banke-details'>Message</h5>
													<TextField
														className='w-100 multiline-text'
														placeholder='Message'
														variant='outlined'
														multiline
														rows={5}
														name='message'
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.message}
														helperText={errors.message}
														error={
															touched.message && errors.message ? true : false
														}
													/>
												</div>
												<br />

												<div>
													{this.state.loader ? (
														<Button
															variant='contained'
															className='contact-submit-btn'
															disabled={this.state.loader}
														>
															<CircularProgress className='loader' />
														</Button>
													) : (
														<Button
															variant='contained'
															className='contact-submit-btn'
															disabled={this.state.loader}
															onClick={(e) => {
																handleSubmit();
															}}
														>
															SUBMIT
														</Button>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
				<FooterWeb />
			</>
		);
	}
}
// Customizable Area End
