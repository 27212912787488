import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
//@ts-ignore
import Cookies from 'js-cookie';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  disableBtn:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  apiEmailsubmitCallId:any;
  apiEmailsubmitLinkCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    this.state = {
      disableBtn:false
    };
    // Customizable Area End
  }
// Customizable Area Start
  async componentDidMount() {
   
  }
  doSubmit(values:any): boolean {
    Cookies.set('Login_Email',  values.email)

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: values.email
    };

    const data = {
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailsubmitCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpGenerateEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
   this.setState({disableBtn:true})
    return true;
  }
  doSendLink(token:any): boolean {
    

    const url =window.location.href
    let mainUrl = url.substring(0, url.length - 11);

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
        "email": Cookies.get('Login_Email'),
        "url": mainUrl+'/resetPass?token='+token
    };

    const data = {
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailsubmitLinkCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendUrlLinkToUser
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
   this.setState({disableBtn:true})
    return true;
  }
  // Customizable Area End
  // Customizable Area Start
  async receive(from: string, message: Message) {
    try {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors  && !responseJson.error) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.apiEmailsubmitCallId &&
            responseJson !== undefined
          ) {
              Cookies.set('Forgot_Token', responseJson?.data?.attributes?.token)
              //toast.success('Otp sent to your email address.',{position: toast.POSITION.BOTTOM_RIGHT})
              this.doSendLink( responseJson?.meta?.token)
              
          }
          if (
            apiRequestCallId === this.apiEmailsubmitLinkCallId &&
            responseJson !== undefined
          ) {
              
              toast.success('Reset password link has been sent to your email address.',{position: toast.POSITION.BOTTOM_RIGHT,autoClose:2500})
              this.setState({disableBtn:false})
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.error;
        this.setState({disableBtn:false})
        if (errors==='Bad Request') {
          // this.setState({ loginLoader: false })
          toast.error('Something went wrong.',{position: toast.POSITION.BOTTOM_RIGHT})
          return
        }
        if (responseJson?.errors[0].otp) {
          // this.setState({ loginLoader: false })
          toast.error(responseJson?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
          return
        }
        if (responseJson?.errors[0].account) {
          // this.setState({ loginLoader: false })
          toast.error(responseJson?.errors[0].account,{position: toast.POSITION.BOTTOM_RIGHT})
          return
        }
      }
    }
      
    } catch (error) {
      toast.error('Something went wrong',{position: toast.POSITION.BOTTOM_RIGHT}) 
      this.setState({disableBtn:false})
    }
    
  }
  // Customizable Area End
}
