// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import Cookies from 'js-cookie';
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

// export const configJSON = require("./config.js");

export interface Props {
//   navigation: any;
//   id: string;
//   history: any
}
interface S {
    id: string;
    name: string;
    phone: string;
    email: string;
    nameErr: boolean;
    phoneErr: boolean;
    emailErr: boolean;
    saveLoader: boolean;
    imgPreview: string;
    profileImg: string;
    imgType: string;
    currentPhone: string;
    btnDisable: boolean;
}
interface SS {
  id: any;
}

export default class BuyerProfileController extends BlockComponent<Props, S, SS> {

    ApiGetProfileDataId: string = ''
    ApiUpdateProfileId: string = ''

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.fileUploadHandler = this.fileUploadHandler.bind(this)
        this.getProfileData = this.getProfileData.bind(this)
        this.updateProfile = this.updateProfile.bind(this)
        this.converFileToBase64 = this.converFileToBase64.bind(this)
        console.disableYellowBox = true;
        
        this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
        id: '',
        name: '',
        phone: '',
        email: '',
        nameErr: false,
        phoneErr: false,
        emailErr: false,
        saveLoader: false,
        imgPreview: '',
        profileImg: '',
        imgType: '',
        currentPhone: '',
        btnDisable: false
        };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

   converFileToBase64 = (fileObject:any) =>
    new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileObject);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

   fileUploadHandler = async (e: React.FormEvent<EventTarget>) => {
    let target = e.target as HTMLInputElement;
    let files = target.files as any;
    let url = URL.createObjectURL(files[0]);
    this.setState({
        imgPreview: url
    })
      for (const file of files) {
          let urlProfile;
          const fileInBase64 = await this.converFileToBase64(file);
          if(file?.type==='image/jpeg'){
              //@ts-ignore
              urlProfile = fileInBase64.substr(23);
              this.setState({
                profileImg: urlProfile,
                imgType: file?.type
              })
          }else if(file?.type==='image/png'){
              //@ts-ignore
              urlProfile = fileInBase64.substr(22);
              this.setState({
                profileImg: urlProfile,
                imgType: file?.type
              })
          }
      }
};

async componentDidMount(): Promise<void> {
    this.getProfileData()
    //@ts-ignore
    if(this.props.location.state === 'verified'){
        toast.success('Phone number verified successfully!')
        toast.success('Profile Updated successfully!')
    }
    //@ts-ignore    
    this.props.history.push({state:''})
}

getProfileData(){
    const header = {
        "Content-Type": 'application/json',
        token: Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ApiGetProfileDataId = requestMessage.messageId;
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    'account_block/accounts/:id'
    );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
    );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}

async updateProfile(id:string){
    if(this.state.nameErr || this.state.phoneErr || this.state.emailErr){
        return toast.error('Please fill all fields correctly!')
    }
    this.setState({
        saveLoader: true,
        btnDisable: true
    })

    const header = {
        "Content-Type": 'application/json',
        token: Cookies.get('Login_Token')
    };
    const attrs = {
        fullname: this.state.name,
        full_phone_number: '+' + this.state.phone,
        email: this.state.email
    };

    let data;
    if(this.state.profileImg && this.state.imgType){
        data = {
            "attributes": attrs,
            "image": {
                "avatar": {
                    "content-type": this.state.imgType,
                    "url": this.state.profileImg
                }
            }
        };
    }else{
        data = {
            "attributes": attrs,
            "image": {
                "avatar": {
                }
            }
        };
    }

    const httpBody = {
    data: data
    };

    const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ApiUpdateProfileId = requestMessage.messageId;
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    'account_block/accounts/' + id
    );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
    );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'PUT'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}

async receive(from: string, message: Message) {
    try {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
            );
            const apiid = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage),
            );
            if (responseJson && !responseJson.errors  && !responseJson.error) {
                if(apiid === this.ApiGetProfileDataId){                    
                    this.setState({
                        id: responseJson.data.id,
                        email: responseJson.data.attributes.email,
                        phone: responseJson.data.attributes.full_phone_number,
                        currentPhone: responseJson.data.attributes.full_phone_number,
                        name: responseJson.data.attributes.fullname,
                        imgPreview: responseJson.data.attributes.avatar?.url
                    })
                }
                else if(apiid === this.ApiUpdateProfileId){
                    this.setState({
                        saveLoader:false,
                        btnDisable: false
                    })
                    if(this.state.phone === this.state.currentPhone){
                        toast.success('Profile updated successfully')
                        this.getProfileData()
                    }
                    else{
                        //@ts-ignore
                        this.props.history.push({pathname: '/otp-verification', state:'profile'})
                    }
                }
            } else {
                if(responseJson?.errors?.full_phone_number){
                    this.setState({
                        saveLoader: false,
                    })
                    toast.error('Entered phone number is already taken')
                }
                if(responseJson?.errors[0].full_phone_number){
                    this.setState({
                        phoneErr: true
                    })
                }
                if(responseJson?.errors[0].account){
                    this.setState({
                        saveLoader: false,
                    })
                    toast.error('Entered email is already taken')
                }
              if(responseJson?.errors[0]){
                toast.error(responseJson.errors[0].token)
              }
              else{
                toast.error('Something went wrong')
              }
              
            }
        }
    } catch (error) {}
}


  

}
// Customizable Area End