//Customizable Area Start
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
//@ts-ignore
import Cookies from 'js-cookie';
import ResetPasswordModal from "../../../components/src/ResetPasswordModal.web";
import '../assets/resetPass.scss'
import "../../../components/src/styles.scss";
//@ts-ignore
import AuthBanner from '../../../web/public/AuthBanner.jpg'
//@ts-ignore
import Logo from '../../../web/public/Logo.png'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
export const configJSON = require("./config");
const configURL = require('../../../framework/src/config')
const ResetPasswordWeb= (props:any) => {
  const [disable, setdisable] = useState<boolean>(false);
  const [openModal, setopenModal] = useState<boolean>(false);
  const urlLink= window.location.href
  let url = new URL(urlLink);
  let token = url.searchParams.get("token");

  useEffect(() => {
    if(!token){
      props?.history?.push('/login')
    }
  },[]);
  
  const validations=  Yup.object().shape({
    newPassword: Yup.string()
    .required(configJSON.passwordRequire)
    .min(8, configJSON.passwordMinlength)
    .matches(configJSON.passwordRegex, configJSON.passwordInValid) ,
    confirmNewPassword:  Yup.string()
    .required(configJSON.confirmpasswordRequire)
    .oneOf(
      [Yup.ref("newPassword"), null],
      configJSON.errorBothPasswordsNotSame
    ),
  })

 
  const doSubmit=async (value?:any)=>{
    setdisable(true)
    const data = {
      "data": {
        "email": Cookies.get('Login_Email'),
        "new_password": value.newPassword,
        "token": token
      }
    }
    
    await axios
      .post(`${configURL.baseURL}`+'/'+`${configJSON.changePassword}`,data,{
        headers:{
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        }
      } )
      .then((res:any) => {
        setdisable(false)
        setopenModal(true)
        //toast.success('Otp sent to your email address.',{position: toast.POSITION.BOTTOM_RIGHT})
      })
      .catch((err:any) => {
        if(err?.message==='Request failed with status code 500'){
          toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        if(err?.response?.data?.errors[0]){
        toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        if(err?.response?.data?.errors[0]){
          toast.error(err?.response?.data?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
          }
        setdisable(false)
      }).finally(() => {
        setdisable(false)
       })
}

const doClose=async (value?:any)=>{
  setopenModal(false)
}
  return (
    <div className="container">
    <div className="banner w-50">
      <img className="logo" src={Logo} />
      <img className="banner-img1" src={AuthBanner} />
      <p className="rights-reserved">
        Grow Cart © 2021. All Rights Reserved
      </p>
    </div>
    <div className="auth-form-wrapper w-50">
    <Formik
    initialValues={{
      newPassword: "",
      confirmNewPassword:""
    }}
    validationSchema={
      validations
    }
    onSubmit={(values): any => doSubmit(values)}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleSubmit,
      isSubmitting,
      handleBlur,
    }) => (
      //@ts-ignore
      <Form >
        
        <div className="reset-form">
          <div style={{ display:'flex' }}>
          <h2 className="reset-heading"> Reset Password</h2>
          </div>
          <p style={{ color:'#565555' }}>Lorem Ipsum is simply dummy text of the printing and</p>
          <p style={{ color:'#565555' }}>typesetting industry.</p>
          <div>
            <h4 className='input-lable'>New Password</h4>
            <TextField
              type='password'
              className="custom-input w-100"
              placeholder="New Password"
              variant="outlined"
              name="newPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPassword}
              helperText={errors.newPassword}
              error={touched.newPassword && errors.newPassword ? true : false}
            />
          </div>
          <div>
            <h4 className='input-lable'>Confirm New Password</h4>
            <TextField
              type='password'
              placeholder="Confirm New Password"
              variant="outlined"
              name="confirmNewPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmNewPassword}
              helperText={errors.confirmNewPassword}
              error={touched.confirmNewPassword && errors.confirmNewPassword ? true : false}
            />
          </div>&nbsp;
          <div>
            {disable?<Button variant="contained" className="submit-button" 
             >
              <CircularProgress className="loader"/>
            </Button>:<Button variant="contained" className="submit-button"  disabled={disable}
             onClick={(e) => {
              handleSubmit();
            }}>
              SUBMIT
            </Button>}
          </div>
          <ResetPasswordModal openModal={openModal} doClose={doClose} history={props?.history}/>
        </div>
      </Form>
    )}
  </Formik>
  </div>
  </div>
  );
};
export default ResetPasswordWeb;
