//Customizable Area Start
import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/src/CustomButton";
export const configJSON = require("./config");
import { Link } from "react-router-dom";
import "../assets/signupOptions.scss";
//@ts-ignore
import AuthBanner from "../../../web/public/AuthBanner.jpg";
//@ts-ignore
import Logo from "../../../web/public/Logo.png";
//@ts-ignore
import merchantLogo from "../assets/merchantAvatar.png";
//@ts-ignore
import buyerLogo from "../assets/buyerAvatar.png";
//@ts-ignore
import nextIcon from "../assets/nextIcon.png";
//@ts-ignore
import Cookies from 'js-cookie'

const SignUpOptions = ({history} : any) => {
  const [selectedOption, setSelectedOption] = useState<any>(localStorage.getItem('user_type'));

  useEffect(()=>{
      localStorage.setItem('user_type',selectedOption)
      if(Cookies.get('Login_Token')){
        localStorage.getItem('user_type')==='buyer' ?
        history.push('/') : history.push('/dashboard');
    }
  },[selectedOption])

  return (
    <div className="container">
      <div className="banner w-50">
        <img className="logo" src={Logo} />
        <img className="banner-img" src={AuthBanner} />
        <p className="rights-reserved">Grow Cart © 2021. All Rights Reserved</p>
      </div>
      <div className="auth-form-wrapper w-50">
        <div className="auth-form">
          <div className="signup-link">
            <p>
              Already have an account ? &nbsp;
              <Link style={{cursor:'pointer'}} to="/login">Login</Link>
            </p>
          </div>
          <div className="login-form">
            <h2 className="welcome">Sign up as a</h2>
            <p style={{ color: "#565555" }}>Select your interest</p>
            <br />
            <div
              onClick={() => setSelectedOption('merchant')}
              className={`option-container ${
                selectedOption === 'merchant' ? "selected" : "unSelected"
              }`}
            >
              <div className="logo-container">
                <img src={merchantLogo} />
              </div>
              <div className="info-container">
                <h2>Merchant</h2>
                <p>Lorem ipsum is dummy text for used for printing.</p>
                <img src={nextIcon} />
              </div>
            </div>
            <br />
            <div
              onClick={() => setSelectedOption('buyer')}
              className={`option-container ${
                selectedOption === 'buyer' ? "selected" : "unSelected"
              }`}
            >
              <div className="logo-container">
                <img src={buyerLogo} />
              </div>
              <div className="info-container">
                <h2>Buyer</h2>
                <p>Lorem ipsum is dummy text for used for printing.</p>
                <img src={nextIcon} />
              </div>
            </div>
            <br />
            <div>
              <CustomButton
                className="login-button"
                name="login-loading"
                id="login-loading"
                variant="contained"
                disabled={selectedOption === 0}
                onClick={(e) => {
                  selectedOption && history.push('/EmailAccountRegistration')
                }}
              >
                Signup
              </CustomButton>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpOptions;
//Customizable Area End
