import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
//@ts-ignore
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  disableVerifyBtn:boolean;
  sendingOypBtn:boolean;
  seconds:number;
  showResendButton:boolean;
  ResendButtonDisable:boolean;
  openModal: boolean;
  Loader: boolean;
  changed_phone_number: string;
  phoneErr: boolean;
  receivedOtp: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string;
  ApiChangeNumId: string;
  submitButtonColor: any = configJSON.submitButtonColor;
  token:string = Cookies.get('Login_Token')

  async componentDidMount() {
    if(!this.token){
        //@ts-ignore
       return this.props?.history?.push({pathname:'/login'})
    }
    else if(Cookies.get('is_otp_verified') === 'true'){
        if(localStorage.getItem('user_type')==='buyer'){
        //@ts-ignore
       return this.props?.history?.goBack()
        }
        else {
            if(Cookies.get('is_bank_details_filled') === 'true' && Cookies.get('is_document_uploaded') === 'true'){
               //@ts-ignore
                return this.props?.history?.push({pathname:'/dashboard'}) 
            }else{
                //@ts-ignore
                return this.props?.history?.push({pathname:'/docsCompany'})
            }
        }
    }
    this.getOtp()
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    //Customizable Area Start
    this.changePhoneNumber = this.changePhoneNumber.bind(this)
    //Customizable Area End

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      disableVerifyBtn:true,
      sendingOypBtn:true,
      seconds:60,
      showResendButton:true,
      ResendButtonDisable:false,
      openModal: false,
      Loader:false,
      changed_phone_number: '',
      phoneErr: false,
      receivedOtp: ''
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }
  //Customizable Area Start
  changePhoneNumber(){
   if(!this.state.phoneErr){
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        'token': Cookies.get('Login_Token')
        };

        this.ApiChangeNumId = requestMessage.messageId;

        const attrs = {
            full_phone_number: '+91'+this.state.changed_phone_number,
        }

        const data = {
            attributes: attrs
        }
        
        const httpBody = {
            data:data
        }
        requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'account_block/accounts/update_phone'
        );

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
        );

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
        );

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'POST'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
   }
  }
  //Customizable Area End

  async receive(from: String, message: Message) {
    // Customizable Area Start
    var interval:any;
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
    )
    if(this.ApiChangeNumId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
        if(this.state.changed_phone_number && responseJson?.data){
            Cookies.set('Signup_Phone', `91${this.state.changed_phone_number}`)
        }
        if(responseJson?.data){
        this.setState({
            seconds: 60,
            openModal: false,
            changed_phone_number: '',
            otpAuthToken: responseJson.data.attributes.token,
            otp: ''
        })
        }
    else {
        responseJson?.errors && toast.error(responseJson?.errors?.full_phone_number[0] || 'Invalid or Unrecognised Phone Number')
        this.setState({
            otp: ''
        })
    }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpAuthApiCallId != null &&
      this.otpAuthApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
        this.setState({sendingOypBtn:false})
        this.setState({showResendButton:true})
        this.setState({ResendButtonDisable:false})
        responseJson.errors && toast.error(responseJson.errors[0]?.pin)
    
        interval = setInterval(() => {
          this.setState({seconds:this.state.seconds - 1})
          if(this.state.seconds ===0){
          this.setState({showResendButton:false})
          clearInterval(interval)
          }
        }, 1000);
      if (
        !responseJson.errors
      ) {
        if (responseJson.data.attributes && responseJson.data.attributes.token) {
          this.setState({
            otpAuthToken: responseJson.data.attributes.token
          });
        }
        else{
            if(responseJson.meta && responseJson.meta.token){
                // Cookies.set("Login_Token",responseJson.meta.token)
                Cookies.set('is_otp_verified',true)
                let userType=localStorage.getItem('user_type')
                if(userType==='buyer'){
                  Cookies.set('account_activated', 'yes');
                  Cookies.set('is_otp_verified', true);
                  Cookies.set('is_document_uploaded', true);
                  Cookies.set('is_bank_details_filled', true);
                //@ts-ignore
                  if(this.props.location?.state === 'profile'){
                //@ts-ignore
                    this.props.history.push({
                        pathname: '/account/profile',
                        state: 'verified'
                      })
                  }
                  //@ts-ignore
                  else if(this.props.location?.state === 'otp-verify'){
                    //@ts-ignore
                        this.props.history.push({
                            pathname: '/cart',
                            state: 'verified'
                          })
                      }
                  else{

                      //@ts-ignore
                      this.props.history.push({
                          pathname: '/'
                        })
                    }
              }else if(userType==='merchant'){
                //@ts-ignore
                if(this.props.location?.state === 'merchant-profile'){
                    //@ts-ignore
                       return this.props.history.push({
                            pathname: '/profile',
                            state: 'verified'
                          })
                      }
                //@ts-ignore
                this.props.history.push({
                  pathname: '/docsCompany'
                })
              }
            }
        }
        if (this.state.isFromForgotPassword) {
          // runEngine.debugLog("about to send NavigationNewPasswordMessage");
          const msg: Message = new Message(
            getName(MessageEnum.NavigationNewPasswordMessage)
          );
          msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.otpAuthToken
          );

          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

          this.send(msg);
        } else {
          const msg: Message = new Message(
            getName(MessageEnum.NavigationMobilePhoneAdditionalDetailsMessage)
          );

          msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.otpAuthToken
          );

          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

          this.send(msg);
        }
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
        this.setState({seconds:0,showResendButton:false})
        clearInterval(interval)
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (errorReponse != null) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const phoneAuthToken = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      const phoneNumber = message.getData(
        getName(MessageEnum.AuthTokenPhoneNumberMessage)
      );

      const forgotPasswordBool = message.getData(
        getName(MessageEnum.EnterOTPAsForgotPasswordMessage)
      );

      const emailValue = message.getData(
        getName(MessageEnum.AuthTokenEmailMessage)
      );

      const userAccountID = phoneNumber ? "" + phoneNumber : "" + emailValue;

      let updatedLabel = this.state.labelInfo;
      if (userAccountID && userAccountID !== "undefined") {
        updatedLabel = updatedLabel.replace("phone", userAccountID);
      }

      this.setState({
        otpAuthToken:
          phoneAuthToken && phoneAuthToken.length > 0
            ? phoneAuthToken
            : this.state.otpAuthToken,
        userAccountID: userAccountID,
        labelInfo: updatedLabel,
        isFromForgotPassword:
          forgotPasswordBool === undefined
            ? this.state.isFromForgotPassword
            : forgotPasswordBool
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async getOtp() {
    this.setState({seconds:60})
    const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
        const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType
        };

        //GO TO REQUEST STATE
        this.otpAuthApiCallId = requestMessage.messageId;

        requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyForgotPasswordOtpEndPoint
        );

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
        );

        const data = {
        token: this.state.otpAuthToken ? this.state.otpAuthToken : "",
        otp_code: this.state.otp ? this.state.otp : ""
        };

        const httpBody = {
        data: data
        };

        //requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
        );
    } else {
        const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        };

        this.otpAuthApiCallId = requestMessage.messageId;

        const attrs = {
            full_phone_number: Cookies.get("Signup_Phone"),
            email: Cookies.get("Signup_Email")
        }

        const data = {
            attributes: attrs
        }
        
        const httpBody = {
            data:data
        }
        requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiGetSignupOtpEndPoint
        );

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
        );

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
        );
    }

    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiVerifyOtpMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

  setDisable=()=>{
  this.setState({ResendButtonDisable:true})
  }

  async submitOtp() {
    if (!this.state.otp || this.state.otp.length < 4) {
      this.showAlert(configJSON.errorTitle, configJSON.errorOtpNotValid);
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType
      };

      //GO TO REQUEST STATE
      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyForgotPasswordOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        token: this.state.otpAuthToken ? this.state.otpAuthToken : "",
        otp_code: this.state.otp ? this.state.otp : ""
      };

      const httpBody = {
        data: data
      };

      //requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };

      const attrs = {
          token: this.state.otpAuthToken,
          pin: this.state.otp
      }

      const httpBody = {
          attrs
      }

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(attrs)
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp()
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text })
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric"
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  // Customizable Area End
}
