import React, {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
} from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "./styles.scss";

interface CustomInputProps {
  id: string;
  className: string;
  placeholder: string;
  variant: "standard" | "filled" | "outlined" | undefined;
  name: string;
  onChange: ChangeEventHandler;
  onBlur: FocusEventHandler;
  value: string;
  helperText: string | undefined;
  error: boolean;
  type?: string | undefined;
}

const CustomInput = (props: CustomInputProps) => {
  const {
    id,
    name,
    type,
    variant,
    value,
    className,
    placeholder,
    onChange,
    onBlur,
    helperText,
    error,
  } = props;

  const useStyles = makeStyles({
    root: {
      [`& fieldset`]: {
        borderRadius: '15px',
      },
    },
  });
  
  const classes = useStyles();

  return (
    <TextField
      id={id}
      className={`${classes.root} custom-input ${className}`}
      placeholder={placeholder}
      variant={variant}
      name={name}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      helperText={helperText}
      error={error}
    />
  );
};

export default CustomInput;
