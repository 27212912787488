//Customizable Area Start
import React from 'react'
import AddEditProduct from './AddEditProduct.web';
import ShowProdDetails from './ShowProdDetails.web';

export default function ProductDialog(props: any) {
	const {
		rowInfo,
		addProd,
		setAddProd,
		showDetails,
		setShowDetails,
		editProd,
		setEditProd,
		updateProdList,
		setUpdateProdList,
		allCategories,
		setAllCategories,
	} = props;

	return showDetails ? (
		<ShowProdDetails
			rowInfo={rowInfo}
			setEditProd={setEditProd}
			setShowDetails={setShowDetails}
            updateProdList={updateProdList}
            setUpdateProdList={setUpdateProdList}
		/>
	) : (
		<AddEditProduct
			addProd={addProd}
			setAddProd={setAddProd}
			editProd={editProd}
			setEditProd={setEditProd}
			rowInfo={rowInfo}
            updateProdList={updateProdList}
            setUpdateProdList={setUpdateProdList}
            allCategories={allCategories}
            setAllCategories={setAllCategories}
		/>
	);
}
//Customizable Area End
