Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "bx_block_dashboard/product";
exports.dashboardAdminGetUrl = "bx_block_dashboard/home_page";
exports.TodaysEarningGetUrl = "bx_block_dashboard/earning?earning=todays";
exports.dashboardAdminProductGetUrl =
  "bx_block_dashboard/product?page=2&per_page=6";
exports.productsGetUrl = "bx_block_catalogue/catalogues";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.TodaysEarningApiMethodType = "GET";
exports.dashboarPostApiMethodType = "POST";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.nameRequire = "Name is required";
exports.nameReg = /^[a-zA-Z ]*$/;
exports.nameInvalid = "Only letters are allowed";
exports.phoneRegex = /^[1-9]+[0-9]*$/;
exports.phoneRequire = "Phone number is required";
exports.phoneInvalid = "Phone number should contain only numbers";
exports.gstNumRequired = "GST Number is required";
exports.gstNumberReg = /[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/;
exports.gstinInvalid = "GST Number is not valid";
exports.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
exports.emailRequire = "Email is required.";
exports.errorEmailNotValid = "Email not valid.";
exports.getProfile = "bx_block_custom_form/seller_accounts/";
exports.oldpassWordRequire = "Old password is required";
exports.newpassWordRequire = "New password is required";
exports.samePasswordErr = "New password can not be same as old password!";
exports.confirmpassWordRequire = "Confirm password is required";
exports.passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
exports.passwordMinlength =
  "Password is too short - should be 8 chars minimum.";
exports.passwordInValid =
  "Password should contain  at least a symbol, upper and lower case letters and a number.";
exports.confirmPasswordInvalid = "Password doesn't match";
exports.messegeRequire = "Please write messege to continue";
exports.companyRequire = "Company name is required";
exports.bankNameRequired = "Bank Name is required";
exports.bankAccountNumRequired = "Account Number is required";
exports.bankConfrimAccountNumRequired = "Confirm Account Number is required";
exports.bankAccountReg = /^\d{9,18}$/;
exports.bankAccountNumValid = "Account Number is not valid";
exports.errorBothAccountsNotSame =
  "Account Number and Confirm Account Number should match.";
exports.ifscReg = /^[A-Za-z]{4}0[A-Z0-9]{6}$/;
exports.codeIFSCRequired = "IFSC code is required";
exports.updatePassEndpoint = "/update_password";
exports.contactUsUrl = "bx_block_contact_us/contacts";
exports.bankDetailsUrl = "seller_accounts/bank_details";
exports.fullNameInvalid = "Name should be 8-20 characters.";
exports.endPointTermsPrivacy = "bx_block_admin/static_content/";
exports.getCategoriesListApiEndPoint = "bx_block_categories/categories";
exports.logoutApiEndPoint = "logout_device";
// Customizable Area End
