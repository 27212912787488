import React from "react";

// Customizable Area Start
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CustomButton from "../../../components/src/CustomButton";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from "yup";
export const configJSON = require("./config");
import {withRouter} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { Link } from "react-router-dom";
import '../assets/login.scss'
//@ts-ignore
import AuthBanner from '../../../web/public/AuthBanner.jpg'
//@ts-ignore
import Logo from '../../../web/public/Logo.png'
import SocialMediaLogin from '../../../blocks/social-media-account-login/src/SocialMediaAccontLoginScreen.web-v1'
import CustomInput from "../../../components/src/CustomInput";
import CustomFacebookLogInButton from "../../social-media-account/src/CustomFacebookLogInButton";
import CustomGoogleLogInButton from "../../social-media-account/src/CustomGoogleLogInButton";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";
// Customizable Area Start
class EmailAccountLoginBlock extends EmailAccountLoginController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    return (
      <div className="container">
        <div className="banner w-50">
          <img className="logo" src={Logo} />
          <img className="banner-img" src={AuthBanner} />
          <p className="rights-reserved">
            Grow Cart © 2021. All Rights Reserved
          </p>
        </div>
        <div className="auth-form-wrapper w-50">
          <Formik
            initialValues={{
              password: "",
              email: "",
              remember: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .required(configJSON.emailRequire)
                .matches(
                  configJSON.emailRegex,
                  configJSON.errorEmailNotValid
                ),
              password: Yup.string()
                .required(configJSON.passwordRequire)
                .min(8, configJSON.passwordMinlength)
                .matches(
                  configJSON.passwordRegex,
                  configJSON.passwordInValid
                ),
              remember: Yup.string(),
            })}
            onSubmit={(values): any => this.doLogin(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              handleBlur,
            }) => (
              //@ts-ignore
              <Form>
                <div className="auth-form">
                  <div className="signup-link">
                  <p>Don't have an account ? &nbsp;<Link style={{cursor:"pointer"}} to="/signup-options">Signup</Link></p>
                  </div>
                  <div className="login-form">
                    <h2 className="welcome">Welcome Back !</h2>
                    <p style={{color:'#565555'}}>Login in to your account</p>
                    <br />
                    <div>
                      <h4 className="input-lable">Email Id</h4>
                      <CustomInput
                        className="w-100"
                        id="email"
                        placeholder="Email ID"
                        variant="outlined"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        helperText={errors.email}
                        error={touched.email && errors.email ? true : false}
                      />
                    </div>
                    <br />
                    <div>
                      <h4>Password</h4>
                      
                      <CustomInput
                        className="w-100"
                        id="password"
                        placeholder="Password"
                        variant="outlined"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        helperText={errors.password}
                        error={
                          touched.password && errors.password ? true : false
                        }
                      />
                    </div>
                    <br />
                    <div className="remember-me-section">
                      <FormControlLabel
                        className="remember-text"
                        label={this.state.keepMeSign}
                        control={
                          <Checkbox
                            className="remember-checkbox"
                            name="remember"
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                          />
                        }
                      />
                      <Link className="forgot-password" to="/forgotPass">
                        Forgot password?
                      </Link>
                    </div>
                    <br />
                    <div>
                      <CustomButton
                        className="login-button"
                        name="login-loading"
                        id="login-loading"
                        variant="contained"
                        loading={this.state.disableLogin}
                        onClick={(e) => {
                          handleSubmit();
                        }}
                      >
                        Login
                      </CustomButton>
                    </div>
                    <br />
                    <div className="divider-section">
                      <Divider className="divider" />
                      <p>Or</p>
                      <Divider className="divider" />
                    </div>
                    <br />
                   <SocialMediaLogin 
                    //@ts-ignore
                    navigation={this.props.navigation} id={"login"} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const EmailAccountLoginStyles = {
} as any;
//@ts-ignore
const emailLoginWithRouter =withRouter(EmailAccountLoginBlock)
export default withStyles(EmailAccountLoginStyles)(emailLoginWithRouter)
// Customizable Area End
