//Customizable Area Start
import React from 'react';
import BuyerNavbarWeb from '../../dashboard/src/BuyerNavbar.web';
import BuyerSidebar from './BuyerSidebar.web';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import FooterWeb from '../../dashboard/src/Footer.web';
import BuyerReferralController from './BuyerReferralController.web';
//@ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
import '../assets/BuyerReferral.scss';
const referral_banner = require('../assets/referral_banner.png');
const copy_icon = require('../assets/copy_icon.png');

const styles = () => ({
	noBorder: {
		border: '1px solid #e4e4e4',
	},
	input: {
		color: '#414141',
		fontSize: '16px',
	},
});

class BuyerReferral extends BuyerReferralController {
	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<>
				<BuyerNavbarWeb />
				<div className='buyer-container'>
					{/* @ts-ignore */}
					<BuyerSidebar activeTab={6} />

					<div className='profile-container'>
						<div>
							<Typography className='top-heading'>Referrals</Typography>
						</div>
						<div className='referral_container'>
							<div className='referral_banner'>
								<img src={referral_banner} alt='' />
							</div>
							<Typography className='referral-text-1'>
								Refer your friends & get 10% discounts on Products.
							</Typography>
							<div className='referral-code-div'>
								<Typography className='referral-code'>
									#00AXy789DSDSFGHBF
								</Typography>
								<CopyToClipboard
									text={'#00AXy789DSDSFGHBF'}
									onCopy={() => {
										this.setState({ refCodeCopied: true });
										setTimeout(() => {
											this.setState({ refCodeCopied: false });
										}, 1000);
									}}
								>
									<img
										src={copy_icon}
										alt=''
										style={{ cursor: 'pointer' }}
										width='12px'
										height='15px'
                                        aria-disabled={this.state.refCodeCopied}
									/>
								</CopyToClipboard>
							</div>
							{this.state.refCodeCopied && (
								<p className='copy-p'>Copied &#x2713;</p>
							)}
							<Typography className='referral-text-2'>
								Your Referral Code
							</Typography>
							<Button className='referral-share'>Share now</Button>
						</div>
					</div>
				</div>
				<FooterWeb />
			</>
		);
	}
}
//@ts-ignore
export default withStyles(styles)(withRouter(BuyerReferral));
//Customizable Area End
