//Customizable Area Start
import React from 'react';
import BuyerNavbarWeb from '../../dashboard/src/BuyerNavbar.web';
import BuyerSidebar from './BuyerSidebar.web';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button } from '@material-ui/core';
import FooterWeb from '../../dashboard/src/Footer.web';
import BuyerSavedCardsController from './BuyerSavedCardsController.web';

const styles = () => ({
	noBorder: {
		border: '1px solid #e4e4e4',
	},
	input: {
		color: '#414141',
		fontSize: '16px',
	},
});

class BuyerSavedCards extends BuyerSavedCardsController {
	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<>
				<BuyerNavbarWeb />
				<div className='buyer-container'>
					{/* @ts-ignore */}
					<BuyerSidebar activeTab={3} />

					<div className='profile-container'>SAVED CARDS</div>
				</div>
				<FooterWeb />
			</>
		);
	}
}
//@ts-ignore
export default withStyles(styles)(withRouter(BuyerSavedCards));
//Customizable Area End
