// Customizable Area Start
import React from "react";
import CartPageController, { configJSON, Props } from "./CartPageController.web";
import "../assets/CartPage.scss";
import BuyerNavbarWeb from "../../dashboard/src/BuyerNavbar.web";
import { Button, CircularProgress, Backdrop, Divider, Radio,	Modal,
	Box, } from '@material-ui/core'
//@ts-ignore
import Cookies from "js-cookie";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Drawer from '@material-ui/core/Drawer';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import Select from "react-select";
import FooterWeb from "../../dashboard/src/Footer.web";
import AddNewAddressWeb from "../../LandingPage2/src/AddNewAddress.web";
const trash = require('../../../components/src/trash.jpg');
const checked = require('../assets/checked.png');
export default class CartPage extends CartPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { activeStep, cartItems, orderData, radioBtn, name, addressLine1, addressLine2, postalCode } = this.state;
    const steps = [{ name: 'Cart', icon: <ShoppingCartIcon /> },
    { name: 'Delivery Address', icon: <LocationOnIcon /> },
    { name: 'Payment', icon: <AccountBalanceWalletIcon /> },]
    
    const renderViewAsPerStep = () => {
      switch (activeStep) {
        case 1:
          return renderCartItems();
        case 2:
          return renderAddress();
        case 3:
          return renderPayment();
          case 4:
            return renderOrderSuccessPage();
        default:
          break;
      }
    };
    const renderCartItems = () => {
      return (
        <>

          <div className="cartitems-container">
            <p className="cartitems-count">Items ({this.state.cartItems.length})</p>
            <div className="cartitems-list">

              {this.state.cartItems.map((item: any, i: number) =>
                <>
                  <div className="cartitem">
                    <div className="details-wrapper">
                      <span>
                        <img alt='product-image' className="image" src={item?.attributes?.images?.[0]?.url || 'https://picsum.photos/200/300'} />
                      </span>
                      <span className="details">
                        <p className="name">{item?.attributes?.catalogue?.name}</p>
                        <p className="quantity" >{item?.attributes?.quantity} {item?.attributes?.catalogue.quantity}</p>
                        <p className="price">₹ {item?.attributes?.total_price}</p>
                      </span>
                      <span className='stock'>
                        {item?.attributes?.out_of_stock == true && <p className="out-of-stock">Out Of Stock</p>}
                      </span>
                    </div>
                    <div className="actions-wrapper">
                      <div className='added-to-cart'>
                        <button
                          disabled={item?.attributes?.out_of_stock == true}
                          onClick={() => this.updateCartItemQuantity(item?.id, 'subtract', 1)}
                          className={`remove ${item?.attributes?.out_of_stock == true ? 'disabled' : ''}`}>
                          <RemoveIcon fontSize="small" />
                        </button>
                        <span className='cart-added-text'>
                          {item?.attributes?.quantity}
                        </span>
                        <button
                          disabled={item?.attributes?.out_of_stock == true}
                          onClick={() => this.updateCartItemQuantity(item?.id, 'add', 1)}
                          className={`add ${item?.attributes?.out_of_stock == true ? 'disabled' : ''}`}>
                          <AddIcon fontSize="small" />
                        </button>
                      </div>
                      <button onClick={() => this.setState({itemOnfocus: item, removeItemModal: true})} className="remove-wrapper">
                        <span><DeleteIcon /></span>
                        <span>Remove</span>
                      </button>
                    </div>
                  </div>
                  {i + 1 !== this?.state?.cartItems?.length && <Divider />}
                </>
              )}
            </div>
            {/* <Button onClick={() => this.setActiveStep(activeStep + 1)}>Countinue</Button> */}
          </div>
          {renderOrderSummary()}
        </>
      );
    };

    const renderAddress = () => {
      return (
        <>
          <div className="address-container">
            <p className="saved-addre">Saved Address</p>
            <div className="address-list">

              {this?.state?.addressList?.map((address: any, index: number) =>
                <>
                  <div className="address-detail">
                    <Radio
                      checked={this.state.selectedAddressId == address?.id}
                      // value={1}
                      onChange={(e: any) => {
                        this.setState({ selectedAddressId: address?.id });
                      }}
                      checkedIcon={<CheckIcon />}
                      className={
                        this.state.selectedAddressId == address?.id
                          ? "checked-radio-shape"
                          : "unchecked-radio-shape"
                      }
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                    <div>
                      <p className="address-header">{address?.attributes?.name}</p>
                      <p className="address-area">
                        {`${address?.attributes?.address}, `}
                        {`${address?.attributes?.landmark}, `}
                        {`${address?.attributes?.city}-${address?.attributes?.zip_code}, `}
                        {`${address?.attributes?.state}, `}
                        {`${address?.attributes?.country}.`}
                      </p>
                    </div>
                  </div>
                  {index + 1 < this?.state?.addressList?.length && <Divider className="address-divider" />}
                </>
              )}
            </div>
            <div className="new-address">
              <AddNewAddressWeb
                //@ts-ignore
                newAddressAddedCallback={() => this.getAllAddressList()}
                openModal='add'
              />
            </div>
          </div>
          {renderOrderSummary()}
        </>
      );
    };

    const renderPayment = () => {
      return (
        <>
          <div className='payment-options'>
            <h2 className='payment-header'>Payment Methods</h2>
            <div className="payment-radio-group">
              <div className="payment-radio-item">
                <Radio
                  checked={this.state.selectedpaymentOption === configJSON.paymentTypeRazorPay}
                  onChange={(e: any) => {
                    this.setState({ selectedpaymentOption: configJSON.paymentTypeRazorPay });
                  }}
                  checkedIcon={<CheckIcon />}
                  className={
                    this.state.selectedpaymentOption === configJSON.paymentTypeRazorPay
                      ? "checked-radio-shape"
                      : "unchecked-radio-shape"
                  }
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                &nbsp;
                <div>
                  <p className="payment-option-label">Razor Pay</p>
                </div>
              </div>

              <Divider className="payment-option-divider" />
              <div className="payment-radio-item">
                <Radio
                  checked={this.state.selectedpaymentOption === configJSON.paymentTypeCOD}
                  onChange={(e: any) => {
                    this.setState({ selectedpaymentOption: configJSON.paymentTypeCOD });
                  }}
                  checkedIcon={<CheckIcon />}
                  className={
                    this.state.selectedpaymentOption === configJSON.paymentTypeCOD
                      ? "checked-radio-shape"
                      : "unchecked-radio-shape"
                  }
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                &nbsp;
                <div>
                  <p className="payment-option-label">COD</p>
                </div>
              </div>

            </div>
          </div>
          {/* <Button onClick={() => this.displayRazorpay()}>Payment</Button> */}

          {/* <Button onClick={() => this.setActiveStep(activeStep + 1)}>Countinue</Button> */}
          {renderOrderSummary()}
        </>
      );
    };

    const renderOrderSummary = () => {
      return (
        <div className="order-summary-container">
          <div className="order-summary-wrapper">
            <div style={{display:'flex', justifyContent:'space-between'}}>
            {!this.state.couponApplied ? <><input type="text" placeholder="Coupon Code" className="coupon-input" value={this.state.couponCode} onChange={(e)=>{
                this.setState({
                    couponCode: e.target.value
                })
            }}/>
            <Button variant="outlined" color="primary" disabled={this.state.couponLoader} onClick={()=>{
                this.verifyCoupon()
            }}>Apply</Button></> : 
            <>
                <div style={{display:'flex', alignItems:'center', fontFamily:'fantasy',
                //@ts-ignore
                fontWeight:'600'}}>
                    <img src="https://cdn3.iconfinder.com/data/icons/flat-actions-icons-9/792/Tick_Mark_Circle-512.png" alt="" width={30} height={30}/>
                    Coupon Applied
                </div>
                <Button variant="outlined" color="secondary" disabled={this.state.couponLoader} onClick={()=>{
                this.removeCoupon()
            }}>Remove</Button>
            </>}
            </div>
            <p className="order-title">Order summary</p>
            <div className="order-row">
              <span className="item-name">Items</span>
              <span className="item-price">₹ {this?.state?.orderData?.amount}</span>
            </div>
            <div className="order-row">
              <span className="item-name">Shipping Charges</span>
              <span className="item-price">₹ {this?.state?.orderData?.shipping_charges}</span>
            </div>
            <div className="order-row">
              <span className="item-name">Tax</span>
              <span className="item-price">₹ {this?.state?.orderData?.tax}</span>
            </div>
            {this.state.couponApplied && (
                <div className="order-row">
                <span className="item-name">Discount</span>
                <span className="item-price">₹ {this?.state?.orderData?.discount}</span>
              </div>
            )}
            <div className="order-row">
              <span className="item-name">Total</span>
              <span className="item-price">₹ {this?.state?.orderData?.total}</span>
            </div>
            <Divider />
            <div className="order-total">
              <span className="text">Order Total</span>
              <span className="price">₹ {this?.state?.orderData?.total}</span>
            </div>
            {Cookies.get('is_otp_verified') === 'false' && <p
                style={{
                    color: '#221172',
                    cursor: 'pointer',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    //@ts-ignore
                    fontWeight: '600',
                }}
                onClick={() => {
                    //@ts-ignore
                    this.props.history.push({pathname: '/otp-verification', state: 'otp-verify'})
                }}
            >
                Verify phone number
            </p>}
            <Button disabled={this.allowNextStepOrNot()} className={`countinue-button ${this.allowNextStepOrNot() ? 'disabled' : ''}`} onClick={() => this.handleCountinue()}>Continue</Button>
            {this.allowNextStepOrNot() && !this.state.loading && 
              <span className='stock'>
                <p className="out-of-stock">{this.errorMessageAsPerStep()}
                </p>
              </span>}
          </div>
        </div>
      );
    };

    const renderOrderSuccessPage = () => {
      return (
        <div className="payment-success-container">
          <div className="checked-icon"><img src={checked} /></div>
          <p className="title">You have placed order Successfully</p>
          <p className="thank-you"> Thank you for shopping with us we will send confirmation on your primary email address.</p>
        <p className="date-title">Estimated delivery Date</p>
        <p className="date">Friday, July 23, 2021 - sunday, July 25, 2021</p>
       <Link to='/'> <Button className="shop-more-products">shop more products</Button></Link>
        </div>
      )
    }

    const isStepActiveOrCompleted = (activeStep: number, i: number) => {
      if (i + 1 === activeStep) return "active";
      else if (i + 1 < activeStep) return "completed";
    };

    const confirmationPopup = () => {
     return (
        //@ts-ignore
        <Modal
          open={this.state.removeItemModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box className='box-modal'>
            <div className='div-modal'>
              <img src={trash} alt='' />
            </div>
            <h4 className='div-modal-h4'>Remove Item</h4>
            <Typography>
              <p className='p-tag-modal'>
              Are you sure want to delete {this.state.itemOnfocus?.attributes?.catalogue?.name} from your cart ?
              </p>
            </Typography>
            <div
              style={{
                textAlign: 'center',
                marginBottom: '25px',
                display: 'flex',
                justifyContent:'space-around'
              }}
            >
              <Button
                variant='contained'
                className='modal-cancel-btn'
                onClick={() => {
                  this.setState({
                    removeItemModal: false,
                  });
                }}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                variant='contained'
                className='modal-remove-btn'
                onClick={() => {
                  this.removeCartItem(this.state.itemOnfocus?.id)}
                }
              >
                {this.state.loading ? (
                  <CircularProgress />
                ) : (
                  'Confirm'
                )}
              </Button>
            </div>
          </Box>
        </Modal>
      )
    }

    return (
      <>
        <Backdrop style={{ zIndex: 2 }} open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* @ts-ignore */}
        <BuyerNavbarWeb token__={Cookies.get('Login_Token')} isCartUpdate={this.state.cartUpdateFlag}/>
        {(<div className="cart-container">
          {activeStep !== 4 && <div className="stepper-container">
            <Stepper className="stepper" activeStep={activeStep}>
              {steps.map(({ name, icon }, i) => (
                <Step
                  className={`step-wrapper ${isStepActiveOrCompleted(
                    activeStep,
                    i
                  )}`}
                  key={i}
                >
                  <div
                    className={`icon ${isStepActiveOrCompleted(activeStep, i)}`}
                  >
                    {icon}
                  </div>
                  <div
                    className={`step-name ${isStepActiveOrCompleted(
                      activeStep,
                      i
                    )}`}
                  >
                    {name}
                  </div>
                </Step>
              ))}
            </Stepper>
          </div>}
          <div className="view-as-per-step-container">
            {renderViewAsPerStep()}
            {confirmationPopup()}
          </div>
          <FooterWeb />
        </div>)}
      </>
    );
  }
}
// Customizable Area End
