//Customizable Area Start
import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {imgUploadArrow} from "./assets";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import CancelIcon from '@material-ui/icons/Cancel';
//@ts-ignore
import AuthBanner from '../../../web/public/AuthBanner.jpg'
//@ts-ignore
import Logo from '../../../web/public/Logo.png'
import axios from 'axios';
import '../assets/DocumentSubmitKycDetailsWeb.scss'
//@ts-ignore
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
export const configJSON = require("./config");
const configURL = require('../../../framework/src/config')
const DocumentSubmitCompanyDetailWeb= (props:any) => {
  const [disable, setdisable] = useState<boolean>(false);
  const [imageName, setimageName] = useState<boolean>(false);
  const [imageAdharName, setimageAdharName] = useState<boolean>(false);
  const [closeImage, setcloseImage] = useState<boolean>(false);
  const [closeImageAdhar, setcloseImageAdhar] = useState<boolean>(false);
  const [selectedCompanyAdharcardImg, setselectedCompanyAdharcardImg] = useState<any>('');
  const [selectedCompanyPanCardImg, setselectedCompanyPanCardImg] = useState<any>('');
  const [isPdfFilePanCard, setisPdfFilePanCard] = useState<any>('');
  const [isPdfFileAdharCard, setisPdfFileAdharCard] = useState<any>('');

  const validations=  Yup.object().shape({
    selectedFile:Yup.string()
    .required()
  })
 
  const doSubmit=async (value?:any)=>{
    setdisable(true)
  if(props?.companyName && props?.gstNumber &&  props?.selectedCompanyImg){

    let urlCompanyImg = props?.selectedCompanyImg
    //@ts-ignore
    let urlCompany;
    //@ts-ignore
    let contentTypeCompany;
    if(props?.isPdfFile==='pdf'){
    //@ts-ignore
    urlCompany = urlCompanyImg.substr(28);
    contentTypeCompany='application/pdf'
    }else if(props?.isPdfFile==='jpeg'){
    //@ts-ignore
    urlCompany = urlCompanyImg.substr(23);
    contentTypeCompany='image/jpeg'
    }else if(props?.isPdfFile==='png'){
       //@ts-ignore
    urlCompany = urlCompanyImg.substr(22);
    contentTypeCompany='image/png'
    }
      
    let urlCompanyAdharImg = selectedCompanyAdharcardImg
    //@ts-ignore
    let urlAdhhar;
    //@ts-ignore
    let contentTypeAdhar;
    if(isPdfFileAdharCard==='pdfAdharCard'){
    //@ts-ignore
    urlAdhhar = urlCompanyAdharImg.substr(28);
    contentTypeAdhar='application/pdf'
    }else if(isPdfFileAdharCard==='pdfAdharCardJpeg'){
    //@ts-ignore
    urlAdhhar = urlCompanyAdharImg.substr(23);
    contentTypeAdhar='image/jpeg'
    }else if(isPdfFileAdharCard==='pdfAdharCardPng'){
    //@ts-ignore
    urlAdhhar = urlCompanyAdharImg.substr(22);
    contentTypeAdhar='image/png'
    }

    let urlCompanyPanImg = selectedCompanyPanCardImg
     //@ts-ignore
     let urlPan;
     //@ts-ignore
     let contentPancard;
    if(isPdfFilePanCard==='pdfPanCard'){
    //@ts-ignore
    urlPan = urlCompanyPanImg.substr(28);
    contentPancard='application/pdf'
    }else if(isPdfFilePanCard==='pdfPanCardJpeg'){
    //@ts-ignore
    urlPan = urlCompanyPanImg.substr(23);
    contentPancard='image/jpeg'
    }else if(isPdfFilePanCard==='pdfPanCardPng'){
        //@ts-ignore
        urlPan = urlCompanyPanImg.substr(22);
        contentPancard='image/png'
    }
   

    const data = {
      "seller_account": {
        "company_name": props?.companyName,
        "gstin_number": props?.gstNumber
      },
      "image":{
        "company_license_url": 
        {
         "url": urlCompany,
         "content_type":contentTypeCompany
        },
        "aadhar_card_uri":
        {
          "url":urlAdhhar,
          "content_type":contentTypeAdhar
        },
        "pan_card_uri":
        {
          "url":urlPan,
          "content_type":contentPancard
        }
      }
    }
  if(contentTypeCompany && contentTypeAdhar && contentPancard){  
    await axios
      .post(`${configURL.baseURL}`+'/'+`${configJSON.sellerEndPoint}`,data,{
        headers:{
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "token": Cookies.get('Login_Token')
        }
      } )
      .then((res:any) => {
        setdisable(false)
        toast.success('Addhar and PAN cards are updated successfully.',{position: toast.POSITION.BOTTOM_RIGHT, autoClose:2500})
        Cookies.set('is_document_uploaded','true')
        props?.changePage('3')
      })
      .catch((err:any) => {
        if(err?.message==='Request failed with status code 500'){
          toast.error(err?.message,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        if(err?.response?.data?.errors[0]){
        toast.error(err?.response?.data?.errors[0].token,{position: toast.POSITION.BOTTOM_RIGHT})
        }
        setdisable(false)
      }).finally(() => {
        setdisable(false)
       })
    }
  }
}

const useStyles = makeStyles((theme:any) => createStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
}));

const classes = useStyles();

  return (
    <Formik
    initialValues={{
      selectedPanFile:"",
      selectedAdharFile:"",
    }}
    validationSchema={
      validations
    }
    onSubmit={(values): any => 
      {
        
        doSubmit(values)
        
      }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleSubmit,
      isSubmitting,
      handleBlur,
      setFieldValue
    }) => (
      //@ts-ignore
      <Form >
         <div className="container">
    <div className="banner w-50">
      <img className="logo" src={Logo} />
      <img className="banner-img" src={AuthBanner} />
      <p className="rights-reserved">
        Grow Cart © 2021. All Rights Reserved
      </p>
    </div>
    <div className="auth-form-wrapper w-50">
    <div className="auth-form">
              <div className="login-form">
          
          <div className="docs-Line-div">
            <h2 className="docs-line"> Document submit only in 3 Steps</h2>
            </div>
            <p className="provide-line">Provide your company details &amp; document</p>
            <div>
              <br />&nbsp;
            <p className="step-line">Step 2 of 3</p>
            <h3 className="bank-detail-kyc-line">KYC Verification</h3>
            <br />
            {
            closeImage?(<>
            <h5 className="upload-line">Upload PAN Card</h5><br />
            <CancelIcon className="cross-circle" onClick={()=>{setcloseImage(false);setselectedCompanyPanCardImg(''),setFieldValue("selectedPanFile",'')}}/>
            {isPdfFilePanCard==='pdfPanCard'?(
            <embed
            src={selectedCompanyPanCardImg}
            width="600"
            height="200"
            className="pdf-class-kyc"
            />
            ):(<img src={selectedCompanyPanCardImg} alt="" className="DocsImg"  />)}
            </>
            ):(
              <div>
            <h5 className="upload-line">Upload PAN Card</h5>
            &nbsp;
            <DropzoneAreaBase
                onAdd={(fileObjs:any) =>{ 
                  if(fileObjs[0]?.file?.type==="application/pdf"){
                    setisPdfFilePanCard('pdfPanCard')
                  }else if(fileObjs[0]?.file?.type==='image/jpeg'){
                    setisPdfFilePanCard('pdfPanCardJpeg')
                  }else if(fileObjs[0]?.file?.type==='image/png'){
                    setisPdfFilePanCard('pdfPanCardPng')
                  }
                  setselectedCompanyPanCardImg(fileObjs[0]?.data)
                  setcloseImage(true)
                  setFieldValue("selectedPanFile", fileObjs[0]?.data)
                  setimageName(false)
              }}
              
                onDelete={(fileObj:any) => {}}
                 fileObjects={[]}
                 name="selectedFile"
                //  onChange={(e:any)=>{
                  
                //   setimageSelected(e)
                //   setFieldValue("selectedPanFile",e)
                //   setimageName(false)
                //  }}
                //@ts-ignore
                Icon={ArrowUpwardIcon}
                acceptedFiles={['.png, .jpg, .jpeg, .pdf']}
                showFileNames
                showAlerts={true}
                maxFileSize={5242880}
                //@ts-ignore
                dropzoneText={<div className="dropzone-text-div"><img src={imgUploadArrow}/> <p>Upload PAN Card</p></div>}
                filesLimit={1}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                previewChipProps={{classes: { root: classes.previewChip } }}
                previewText="Selected files"
                maxSize={5242880}
                />
                {(imageName && !closeImage)&&<p style={{fontSize:'12px',color:'#f44336'}}>PAN card is required</p>}
          </div>
            ) 
        }
         {
            closeImageAdhar?(<>
            <h5 className="upload-line">Upload Addhar Card</h5><br />
            <CancelIcon className="cross-circle" onClick={()=>{setcloseImageAdhar(false);setselectedCompanyAdharcardImg('');setFieldValue("selectedAdharFile", '')}}/>
            {isPdfFileAdharCard==='pdfAdharCard'?(
            <embed
            src={selectedCompanyAdharcardImg}
            width="600"
            height="200"
            className="pdf-class-kyc"
            />
            ):(<img src={selectedCompanyAdharcardImg} alt="" className="DocsImg"  />)}
            </>
            ):(
              <div style={{marginTop:'10px'}}>
            <h5 className="upload-line">Upload Addhar Card</h5>
            &nbsp;
            <DropzoneAreaBase
                onAdd={(fileObjs:any) =>{ 
                  if(fileObjs[0]?.file?.type==="application/pdf"){
                    setisPdfFileAdharCard('pdfAdharCard')
                  }else if(fileObjs[0]?.file?.type==='image/jpeg'){
                    setisPdfFileAdharCard('pdfAdharCardJpeg')
                  }else if(fileObjs[0]?.file?.type==='image/png'){
                    setisPdfFileAdharCard('pdfAdharCardPng')
                  }
                  
                  setselectedCompanyAdharcardImg(fileObjs[0]?.data)
                  setcloseImageAdhar(true)
                  setFieldValue("selectedAdharFile", fileObjs[0]?.data)
                  setimageAdharName(false)
              }}
              
                onDelete={(fileObj:any) => {}}
                 fileObjects={[]}
                 name="selectedFile"
                 maxSize={5242880}
                //@ts-ignore
                Icon={ArrowUpwardIcon}
                acceptedFiles={['.png, .jpg, .jpeg, .pdf']}
                showFileNames
                showAlerts={true}
                maxFileSize={5242880}
                //@ts-ignore
                dropzoneText={<div className="dropzone-text-div"><img src={imgUploadArrow}/> <p>Upload Addhar Card</p></div>}
                filesLimit={1}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                previewChipProps={{classes: { root: classes.previewChip } }}
                previewText="Selected files"
                />
                {(imageAdharName && !closeImageAdhar)&&<p style={{fontSize:'12px',color:'#f44336'}}>Adhhar card is required</p>}
          </div>
            ) 
        }
            </div>
          &nbsp;
          <div className="button-divider">
          <Button variant="contained"  className="prev-button"disabled={disable}
             onClick={(e) => {
              props?.changePage('1')
            }}>
              PREV
            </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {disable?<Button variant="contained" className="login-button"
             >
              <CircularProgress style={{color:'#FFFFFF'}}/>
            </Button>:(
            
            <Button variant="contained" className="login-button" disabled={disable}
            onClick={(e) => {
              //@ts-ignore
               if(values?.selectedAdharFile && values?.selectedPanFile){
               setimageName(false)
               setimageAdharName(false)
               doSubmit(values)
             }else{
               setimageName(true)
               setimageAdharName(true)
               handleSubmit();
             }
           }}>
             NEXT
           </Button>
           )}
          </div>
              </div>
              </div>
    </div>
  </div>
        
      </Form>
    )}
  </Formik>
  );
};
export default DocumentSubmitCompanyDetailWeb;
