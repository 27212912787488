import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
//@ts-ignore
import Cookies from 'js-cookie';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  verify:any;
  Otp:any
  disableVerifyBtn:boolean;
  disableResendBtn:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

export default class OtpResetController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  apiEmailsubmitCallId:any;
  apiOtpCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    this.state = {
        verify:'',
        Otp:'',
        disableVerifyBtn:true,
        disableResendBtn:false
    };
    // Customizable Area End
  }
// Customizable Area Start
  async componentDidMount() {
   
  }

  doVerify(): boolean {
   if(this.state.Otp){
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const httpBody = {
      data: { 
      "email":Cookies.get('Login_Email'),
      "otp_code": this.state.Otp,
      "token":Cookies.get('Forgot_Token')
    }

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailsubmitCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpConfirmEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
   }else{
    return false;
   }
  }
  doResend(): boolean {
  
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: Cookies.get('Login_Email')
    };

    const data = {
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpGenerateEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({disableResendBtn:true})
    return true;
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    try { 
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors  && !responseJson.error) {
        if (apiRequestCallId != null) {
          //Verify OTP response Api
          if (
            apiRequestCallId === this.apiEmailsubmitCallId &&
            responseJson !== undefined
          ) {
             // Cookies.set('Login_Token', responseJson.meta.token)
              toast.success('Otp verification successful',{position: toast.POSITION.BOTTOM_RIGHT, autoClose:2000})
               //@ts-ignore
               this.props.history.push({
                pathname: '/resetPass'
              })
          }
         //Resend OTP
          if (
            apiRequestCallId === this.apiOtpCallId &&
            responseJson !== undefined
          ) {
             // Cookies.set('Login_Token', responseJson.meta.token)
             this.setState({disableResendBtn:false})
             toast.success('Otp resent to your email address.',{position: toast.POSITION.BOTTOM_RIGHT, autoClose:2000})
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
       // const errors = responseJson?.errors;
       this.setState({disableResendBtn:false})
        if (responseJson?.errors[0].otp) {
          // this.setState({ loginLoader: false })
          toast.error(responseJson?.errors[0].otp,{position: toast.POSITION.BOTTOM_RIGHT})
          return
        }
       
      }
    }
      
    } catch (error:any) {
      toast.error('Something went wrong',{position: toast.POSITION.BOTTOM_RIGHT})
      this.setState({disableResendBtn:false})
    }
  // Customizable Area End
  }
  // Customizable Area End
}
