// Customizable Area Start
import React from "react";
import BuyerSidebarController from "./BuyerSidebarController.web";
import { withRouter } from 'react-router-dom'
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuIcon from '@material-ui/icons/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import LogOutModalWeb from "../../dashboard/src/LogOutModalWeb.web";
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
const logout = require("../assets/LogoutIcon.png")
const lock = require("../assets/lock.png")
const orders = require("../assets/orders.png")
const profile = require("../assets/profile.png")
const refferals = require("../assets/refferals.png")
const address = require("../assets/address.png")
const cards = require("../assets/cards.png")
const lock_act = require("../assets/lock_act.png")
const orders_act = require("../assets/orders_act.png")
const profile_act = require("../assets/profile_act.png")
const refferals_act = require("../assets/refferals_act.png")
const address_act = require("../assets/address_act.png")
const cards_act = require("../assets/cards_act.png")
const closeIcon = require('../../../components/src/close.png');
import '../assets/BuyerSidebar.scss'
import 'react-toastify/dist/ReactToastify.css';

class BuyerSidebar extends BuyerSidebarController {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
    <>
        <div className="profile-sidebar">
        <IconButton
                            aria-label="open drawer"
                            edge="start"
                            className="shows"
                            onClick={()=>{this.setState({mobileMenu:true})}}
                         >
                            <MenuIcon    
                          />
        </IconButton>
        <Hidden smUp>
        <Drawer anchor="left" className="drawer-width" open={this.state.mobileMenu} variant="temporary" onClose={()=>{this.setState({mobileMenu:false})}}>
          <Box
            //@ts-ignore
            sx={250}
            role="presentation"
          >
            <List>
            
              <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <h2 className="my-account-title"> My Account</h2>
              <img src={closeIcon} alt="" onClick={()=>{this.setState({mobileMenu:false})}} />
              </div>
              <ListItem
                //@ts-ignore
                className={this.props.activeTab === 1 && "list_item"} onClick={() => {
                  this.setActiveTab(1)
                  //@ts-ignore
                  this.props?.history.push({
                      pathname: '/account/profile',
                      state: 1
                    })
                  }
                }>
                <ListItemIcon style={{ marginTop: '7px' }}>
                  <img className="img-icons" src={this.props.activeTab === 1 ? profile_act: profile} />
                </ListItemIcon>
                <ListItemText className={this.props.activeTab === 1 ? "content-lines-active" : "content-lines"} primary={'Profile'} />
              </ListItem>

              <ListItem
                //@ts-ignore
                className={this.props.activeTab === 2 && "list_item"} onClick={() => {
                  this.setActiveTab(1)
                  //@ts-ignore
                  this.props?.history.push({
                    pathname: '/account/addresses',
                    state: 2
                  })
                }
                }>
                <ListItemIcon style={{ marginTop: '7px' }}>
                  <img className="img-icons" src={this.props.activeTab === 2 ? address_act: address} alt="" />
                </ListItemIcon>
                <ListItemText className={this.props.activeTab === 2 ? "content-lines-active" : "content-lines"} primary={'My Address'} />
              </ListItem>

              {/* <ListItem
                //@ts-ignore
                className={this.props.activeTab === 3 && "list_item"}
                onClick={() => {
                  this.setActiveTab(1)
                  //@ts-ignore
                  this.props?.history.push({
                      pathname: '/account/saved-cards',
                      state: 3
                    })
                  }
                }>
                <ListItemIcon style={{ marginTop: '7px' }}>
                  <img className="img-icons" src={this.props.activeTab === 3 ? cards_act: cards} alt="" />
                </ListItemIcon>
                <ListItemText className={this.props.activeTab === 3 ? "content-lines-active" : "content-lines"} primary={'Saved Cards'} />
              </ListItem> */}

              <ListItem
                //@ts-ignore
                className={this.props.activeTab === 4 && "list_item"}
                onClick={() => {
                  this.setActiveTab(1)
                  //@ts-ignore
                  this.props?.history.push({
                    pathname: '/account/my-orders',
                    state: 4
                  })
                }
                }>
                <ListItemIcon style={{ marginTop: '7px' }}>
                  <img className="img-icons" src={this.props.activeTab === 4 ? orders_act: orders} alt="" />
                </ListItemIcon>
                <ListItemText className={this.props.activeTab === 4 ? "content-lines-active" : "content-lines"} primary={'My Orders'} />
              </ListItem>

              <ListItem
                //@ts-ignore
                className={this.props.activeTab === 5 && "list_item"}
                onClick={() => {
                  this.setActiveTab(1)
                  //@ts-ignore
                  this.props?.history.push({
                    pathname: '/account/change-password',
                    state: 5
                  })
                }
                }>
                <ListItemIcon style={{ marginTop: '7px' }}>
                  <img className="img-icons" src={this.props.activeTab === 5 ? lock_act: lock} alt="" />
                </ListItemIcon>
                <ListItemText className={this.props.activeTab === 5 ? "content-lines-active" : "content-lines"} primary={'Change Password'} />
              </ListItem>

              <ListItem
                //@ts-ignore
                className={this.props.activeTab === 6 && "list_item"}
                onClick={() => {
                  this.setActiveTab(1)
                  //@ts-ignore
                  this.props?.history.push({
                    pathname: '/account/referrals',
                    state: 6
                  })
                }
                }>
                <ListItemIcon style={{ marginTop: '7px' }}>
                  <img className="img-icons" src={this.props.activeTab === 6 ? refferals_act: refferals} alt="" />
                </ListItemIcon>
                <ListItemText className={this.props.activeTab === 6 ? "content-lines-active" : "content-lines"} primary={'Refferals'} />
              </ListItem>

              <div className="">
                <ListItem
                  onClick={() => this.setShowLogoutModal(true)}
                >
                  <ListItemIcon style={{ marginTop: '8px' }}>
                    <img className="img-icons" src={logout} alt="" />
                  </ListItemIcon>
                  <ListItemText className="log-out" primary={'Logout'} />
                </ListItem>
                <LogOutModalWeb
                  //@ts-ignore
                  openLogoutModal={this.state.showLogoutModal} closeLogoutModal={() => this.setShowLogoutModal(false)} history={this.props.history} />
              </div>
            </List>
          </Box>
          </Drawer>
          </Hidden>
          <Hidden xsDown>
          {/* <Drawer anchor="left" open variant="permanent"> */}
          <Box
            //@ts-ignore
            sx={250}
            role="presentation"
          >
            <List>
            
              <h2 className="my-account-title"> My Account</h2>
              <ListItem
                //@ts-ignore
                className={this.props.activeTab === 1 && "list_item"} onClick={() => {
                  this.setActiveTab(1)
                  //@ts-ignore
                  this.props?.history.push({
                      pathname: '/account/profile',
                      state: 1
                    })
                  }
                }>
                <ListItemIcon style={{ marginTop: '7px' }}>
                  <img className="img-icons" src={this.props.activeTab === 1 ? profile_act: profile} />
                </ListItemIcon>
                <ListItemText className={this.props.activeTab === 1 ? "content-lines-active" : "content-lines"} primary={'Profile'} />
              </ListItem>

              <ListItem
                //@ts-ignore
                className={this.props.activeTab === 2 && "list_item"} onClick={() => {
                  this.setActiveTab(1)
                  //@ts-ignore
                  this.props?.history.push({
                    pathname: '/account/addresses',
                    state: 2
                  })
                }
                }>
                <ListItemIcon style={{ marginTop: '7px' }}>
                  <img className="img-icons" src={this.props.activeTab === 2 ? address_act: address} alt="" />
                </ListItemIcon>
                <ListItemText className={this.props.activeTab === 2 ? "content-lines-active" : "content-lines"} primary={'My Address'} />
              </ListItem>

              {/* <ListItem
                //@ts-ignore
                className={this.props.activeTab === 3 && "list_item"}
                onClick={() => {
                  this.setActiveTab(1)
                  //@ts-ignore
                  this.props?.history.push({
                      pathname: '/account/saved-cards',
                      state: 3
                    })
                  }
                }>
                <ListItemIcon style={{ marginTop: '7px' }}>
                  <img className="img-icons" src={this.props.activeTab === 3 ? cards_act: cards} alt="" />
                </ListItemIcon>
                <ListItemText className={this.props.activeTab === 3 ? "content-lines-active" : "content-lines"} primary={'Saved Cards'} />
              </ListItem> */}

              <ListItem
                //@ts-ignore
                className={this.props.activeTab === 4 && "list_item"}
                onClick={() => {
                  this.setActiveTab(1)
                  //@ts-ignore
                  this.props?.history.push({
                    pathname: '/account/my-orders',
                    state: 4
                  })
                }
                }>
                <ListItemIcon style={{ marginTop: '7px' }}>
                  <img className="img-icons" src={this.props.activeTab === 4 ? orders_act: orders} alt="" />
                </ListItemIcon>
                <ListItemText className={this.props.activeTab === 4 ? "content-lines-active" : "content-lines"} primary={'My Orders'} />
              </ListItem>

              <ListItem
                //@ts-ignore
                className={this.props.activeTab === 5 && "list_item"}
                onClick={() => {
                  this.setActiveTab(1)
                  //@ts-ignore
                  this.props?.history.push({
                    pathname: '/account/change-password',
                    state: 5
                  })
                }
                }>
                <ListItemIcon style={{ marginTop: '7px' }}>
                  <img className="img-icons" src={this.props.activeTab === 5 ? lock_act: lock} alt="" />
                </ListItemIcon>
                <ListItemText className={this.props.activeTab === 5 ? "content-lines-active" : "content-lines"} primary={'Change Password'} />
              </ListItem>

              <ListItem
                //@ts-ignore
                className={this.props.activeTab === 6 && "list_item"}
                onClick={() => {
                  this.setActiveTab(1)
                  //@ts-ignore
                  this.props?.history.push({
                    pathname: '/account/referrals',
                    state: 6
                  })
                }
                }>
                <ListItemIcon style={{ marginTop: '7px' }}>
                  <img className="img-icons" src={this.props.activeTab === 6 ? refferals_act: refferals} alt="" />
                </ListItemIcon>
                <ListItemText className={this.props.activeTab === 6 ? "content-lines-active" : "content-lines"} primary={'Refferals'} />
              </ListItem>

              <div className="">
                <ListItem
                  onClick={() => this.setShowLogoutModal(true)}
                >
                  <ListItemIcon style={{ marginTop: '8px' }}>
                    <img className="img-icons" src={logout} alt="" />
                  </ListItemIcon>
                  <ListItemText className="log-out" primary={'Logout'} />
                </ListItem>
                <LogOutModalWeb
                  //@ts-ignore
                  openLogoutModal={this.state.showLogoutModal} closeLogoutModal={() => this.setShowLogoutModal(false)} history={this.props.history} />
              </div>
            </List>
          </Box>
          {/* </Drawer> */}
          </Hidden>
        </div>
    </>
    );
  }
}
// @ts-ignore
export default withRouter(BuyerSidebar)
// Customizable Area End