import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
//@ts-ignore
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history:any;
}

export interface S {
  // Customizable Area Start
  disableBtn:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DocumentReviewWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId:string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      disableBtn:false
      // Customizable Area End
    };
  }

  // Customizable Area Start
  getAccountData(): boolean {
    this.setState({disableBtn:true})
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token":Cookies.get('Login_Token')
    };

    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewUserAccountData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.createAccountApiCallId &&
            responseJson !== undefined
          ) {
                this.setState({disableBtn:false})
                var email = responseJson.data.attributes.email
                var phone_number = responseJson.data.attributes.full_phone_number
                var is_otp_verified = responseJson.data.attributes.is_otp_verified
                var is_document_uploaded = responseJson.data.attributes.is_document_uploaded
                var is_bank_details_filled = responseJson.data.attributes.is_bank_details_filled
                var activated = responseJson.data.attributes.activated
                var token = responseJson.data.attributes.token

                 Cookies.set('Login_Email', email)
                 Cookies.set('Login_Account_Id', responseJson?.data?.id)
               
                if(is_otp_verified && is_document_uploaded && is_bank_details_filled && activated)
                { 
                    Cookies.set('account_activated', 'yes');
                    Cookies.set('is_otp_verified', is_otp_verified);
                    Cookies.set('is_document_uploaded', is_document_uploaded);
                    Cookies.set('is_bank_details_filled', is_bank_details_filled);
                    Cookies.set('Login_Token', token)
                    Cookies.set('Signup_Email', email)
                    Cookies.set('Signup_Phone', phone_number)
                    toast.success('Your profile is activated successfully.',{position: toast.POSITION.TOP_RIGHT,autoClose:3000})
                    
                    //@ts-ignore
                    this?.props?.history?.push("/dashboard")
                }else{
                    toast.warning('Your profile is still under review.',{position: toast.POSITION.TOP_RIGHT,autoClose:3000})
                    
                }
            } 
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        if(errors[0]?.token==='Invalid token'){
        toast.error(errors[0]?.token,{position: toast.POSITION.BOTTOM_RIGHT,autoClose:2000})
        }      
        this.setState({disableBtn:false})
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
