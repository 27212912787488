import React from "react";

// Customizable Area Start
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
//@ts-ignore
import OtpInput from "react-otp-input";
import '../../otp-input-confirmation/assets/otpauth.scss'
//@ts-ignore
import AuthBanner from '../../../web/public/AuthBanner.jpg'
//@ts-ignore
import Logo from '../../../web/public/Logo.png'
import * as Yup from "yup";
import {withRouter} from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
export const configJSON = require("./config");
import OtpResetController, { Props } from "./OtpResetController.web";
// Customizable Area End

// Customizable Area Start    
class OtpReset extends OtpResetController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    return (
        <div className="container">
        <div className="banner w-50">
          <img className="logo" src={Logo} />
          <img className="banner-img1" src={AuthBanner} />
          <p className="rights-reserved">
            Grow Cart © 2021. All Rights Reserved
          </p>
        </div>
        <div className="auth-form-wrapper w-50">
        <div className="otpauth-form">
        <h2 className="otp-heading">OTP</h2>
        <p style={{ color: "#565555" }}>
          A Verification code has been sent to your phone.
        </p>
        <div>
          <h4 className="input-lable">Enter OTP</h4>
          <OtpInput
            separator={
              <span>
                <strong> </strong>
              </span>
            }
            numInputs={4}
            inputStyle={{
              width: "100%",
              height: "3rem",
              margin: "0 1rem",
              fontSize: "2rem",
              borderRadius: 4,
              border: "1px solid rgba(0,0,0,0.3)",
            }}
            value={this.state.Otp}
            onChange={(num: any) => {
              if (num.toString().length === 4 && !isNaN(num)) {
                this.setState({ disableVerifyBtn: false, Otp: num });
              } else {
                this.setState({ disableVerifyBtn: true, Otp: "" });
              }
            }}
          />
          &nbsp;
        </div>
        <div>
          <Button
            variant="contained"
            className="submit-button"
            disabled={this.state.disableVerifyBtn}
            onClick={(e) => {
              this.doVerify();
            }}
          >
            VERIFY
          </Button>
        </div>
        &nbsp;
        <div>
          {this.state.disableResendBtn ? (
            <Button
            variant="contained"
            className="submit-button"
          >
            <CircularProgress
              className="loader"
            /></Button>
          ) : (
            <Button
              variant="contained"
              className="submit-button"
              disabled={this.state.disableResendBtn}
              onClick={(e) => {
                this.doResend();
              }}
            >
              RESEND CODE
            </Button>
          )}
        </div>
      </div>
      </div>
      </div>
    );
  }
}
 // Customizable Area End

 // Customizable Area Start
const OTPLoginStyles = {
} as any;
//@ts-ignore
const OTPWithRouter =withRouter(OtpReset)
export default withStyles(OTPLoginStyles)(OTPWithRouter)
// Customizable Area End