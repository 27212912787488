import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//live
// const firebaseConfig = {
//   apiKey: "AIzaSyDtYInZpU7D10_DHTWr2fDKEDaxGQ3l1_A",
//   authDomain: "deegee-868eb.firebaseapp.com",
//   projectId: "deegee-868eb",
//   storageBucket: "deegee-868eb.appspot.com",
//   messagingSenderId: "236194103057",
//   appId: "1:236194103057:web:45eb1101fd08b2cf4f56b0",
//   measurementId: "G-XN65NLZQJM"
// };

//local
const firebaseConfig = {
    apiKey: "AIzaSyDBubUWpO31SMgD7Z8dhnGY_wFgd0bxCkY",
    authDomain: "seedcart-1ae22.firebaseapp.com",
    projectId: "seedcart-1ae22",
    storageBucket: "seedcart-1ae22.appspot.com",
    messagingSenderId: "827672242724",
    appId: "1:827672242724:web:4c1a8324ae6f19380946b1",
    measurementId: "G-C62FMLPDBM"
  };

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();
//local
const publicKey = 'BKY47X-kEKkRKlRe7O1q2ci7pnBuZ3k_3ASBlzWNOhsbeNsA-XgTFWvLGk9N-MFhE6A8VK-KQTKOZW8pk80sp0M';

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
