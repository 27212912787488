// Customizable Area Start
import React from 'react'
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
	getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
//@ts-ignore
import Cookies from 'js-cookie'
import { toast } from 'react-toastify';

export const configJSON = require('./config.js');

type IMG={
    load:boolean;
}
export interface Props {
	// navigation: any;
	// id: string;
}
interface S {
	dashboardData: any;
	token: string;
	errorMsg: string;
	loading: boolean;
    searchValue: string;
    isSearchModalOpen: boolean;
    bannerImgLoaded: IMG[];
    topImgLoaded: IMG[];
    featImgLoaded: IMG[];
    searchSuggestions: any;
}
interface SS {
	id: any;
}

export default class DashboardBuyerController extends BlockComponent<Props, S, SS> {
	apiDashboardItemCallId: string = '';
    apiSearchProductCallId: string = '';
	apiGetQueryStrinurl: string = '';

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);
        this.scroll = this.scroll.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.getDashboardData = this.getDashboardData.bind(this)
		console.disableYellowBox = true;
		this.subScribedMessages = [
			getName(MessageEnum.AccoutLoginSuccess),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.SessionSaveMessage),
			getName(MessageEnum.SessionResponseMessage),
		];

		this.state = {
			dashboardData: [],
			errorMsg: '',
			token: '',
			loading: true,
            searchValue: '',
            isSearchModalOpen: false,
            bannerImgLoaded: [{load:false}],
            topImgLoaded: [{load:false}],
            featImgLoaded: [{load:false}],
            searchSuggestions:[]
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

    componentDidMount():any {
        document.addEventListener("mousedown", this.handleClickOutside);
        this.getDashboardData();
      }

    setWrapperRef(node:any) {
        //@ts-ignore
        this.wrapperRef = node;
      }

      handleClickOutside(event:any) {
        //@ts-ignore
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.target.id!=='searchbar') {
          this.setState({
            isSearchModalOpen:false
          })
        }
      }

    scroll(direction:any, list:string){
        if(list === 'top'){
            //@ts-ignore
            let far:any = (document.querySelector('.wrapper')?.clientWidth + 10)*direction
            let pos:any = document.querySelector('.wrapper')?.scrollLeft + far
            //@ts-ignore
            document.querySelector('.wrapper').scrollLeft = pos
        }
        if(list === 'featured'){
            //@ts-ignore
            let far:any = (document.querySelector('.wrapper1')?.clientWidth+10)*direction
            let pos:any = document.querySelector('.wrapper1')?.scrollLeft + far
            //@ts-ignore
            document.querySelector('.wrapper1').scrollLeft = pos
        }
    }

    searchProd(e:any):any{
        this.setState({
            searchValue : e.target.value,
            isSearchModalOpen : e.target.value ? true : false
        })
        const header = {
			'Content-Type': configJSON.dashboarContentType,
			token: Cookies.get('Login_Token'),
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage),
		);
		this.apiSearchProductCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.productsGetUrl + `?per_page=7&search=${e.target.value}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header),
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.dashboarApiMethodType,
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
    }

    shouldComponentUpdate(prevProps:any, prevState:any):any {
        return true
    }

	getDashboardData(): boolean {
		const header = {
			'Content-Type': configJSON.dashboarContentType,
			// token: Cookies.get('Login_Token'),
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage),
		);
		this.apiDashboardItemCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.dashboardGetUrl + '?per_page=8',
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header),
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.dashboarApiMethodType,
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

	async receive(from: string, message: Message) {
		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let ApiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
			var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
                );
            if(ApiCallId === this.apiDashboardItemCallId){
                if (responseJson && !responseJson.errors && !responseJson.error) {
                    if (responseJson.banners.data.length === 0 || responseJson.featured_products.data.length === 0 || responseJson.top_products.data.length === 0) {
                        this.setState({
                            errorMsg: 'Data Not Found',
                            loading: false,
                        });
                    } else {
                        let arr = []
                        let topArr = []
                        let featArr = []
                        for(let i = 0 ;i < responseJson.banners.data.length ; i ++){
                            arr.push({load:false})
                        }
                        for(let i = 0 ;i < 8 ; i ++){
                            topArr.push({load:false})
                            featArr.push({load:false})
                        }
                        this.setState({
                            bannerImgLoaded:arr,
                            topImgLoaded:topArr,
                            featImgLoaded:featArr,
                            dashboardData: responseJson,
                            errorMsg: '',
                            loading: false,
                        });
                    }
                } else {
                    var errorReponse = message.getData(
                        getName(MessageEnum.RestAPIResponceErrorMessage),
                    );
                    // toast.error(responseJson?.errors[0]?.token, {position:'bottom-right'})
                    if (errorReponse === undefined) {
                        this.setState({
                            errorMsg: 'Something went wrong',
                            loading: false,
                        });
                    } else {
                        toast.error(errorReponse)
                        this.setState({
                            loading: false,
                        });
                    }
                }
            }
            else if(ApiCallId === this.apiSearchProductCallId){
                if (responseJson.data && !responseJson.errors && responseJson.data.length !==0) {
                    let arr = [];
                    for(let i = 0 ;i < responseJson.data.length ; i++){
                        arr.push({name:responseJson.data[i].attributes.name, category:responseJson.data[i].attributes.category.name})
                    }
                    this.setState({
                        searchSuggestions: arr
                    })
                } else {
                    var errorReponse = message.getData(
                        getName(MessageEnum.RestAPIResponceErrorMessage),
                    );
                    if(responseJson.data?.length===0){
                       this.setState({
                        searchSuggestions:null
                       }) 
                    }
                    else if(responseJson.errors[0]){
                        toast.error(responseJson.errors[0].token)
                    }
                    if (errorReponse === undefined) {
                        this.setState({
                            errorMsg: 'Something went wrong',
                            loading: false,
                        });
                    } else {
                        toast.error(errorReponse)

                    }
                }
            }
		}
		// Customizable Area End
	}

	// Customizable Area Start
	// Customizable Area End
}