//Customizable Area Start
import React from 'react';
import {Link } from 'react-router-dom'
import BuyerNavbarWeb from '../../../blocks/dashboard/src/BuyerNavbar.web';
import ProductsListingController from './ProductsListingController.web';
import { Typography, CircularProgress } from '@material-ui/core';
//@ts-ignore
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../assets/ProductsListing.scss';
import Footer from '../../dashboard/src/Footer.web';
const prodSkel = require('../../../blocks/dashboard/assets/prodSkel.png');

export default class ProductsListing extends ProductsListingController {
	constructor(props: any){
		super(props);
	}
	render() {
		return (
			<>
                <BuyerNavbarWeb />
                {!this.state.catLoading && (
                    <>
                        <div className="home-path">
                            <Typography
                                className="home"
                                onClick={() => {
                                    //@ts-ignore
                                    this.props.history.push("/");
                                }}
                            >
                                Home {" >  "}
                            </Typography>
                            {/* @ts-ignore */}
                            {this.props.match.params.id !== "category" ? (
                                <span style={{ color: "#888", fontFamily: "Poppins" }}>
                                    {/* @ts-ignore */}
                                    {this.props.match.params.id}
                                </span>
                            ) : (
                                <span style={{ color: "#888", fontFamily: "Poppins" }}>
                                    {/* @ts-ignore */}
                                    {this.props.location.state.name}
                                </span>
                            )}
                        </div>
                        <div className="top-prod-container">
                            <div className="title">
                                <Typography
                                    style={{
                                        fontSize: "32px",
                                        //@ts-ignore
                                        fontWeight: "600",
                                    }}
                                >
                                    {/* @ts-ignore */}
                                    {this.props.match.params.id === "top"
                                        ? "Top Products"
                                        //@ts-ignore
                                        : this.props.match.params.id === "featured"
                                        ? "Featured Products" :
                                        //@ts-ignore
                                         this.props.match.params.id === "recently-sold"
                                        ? 'Recently Sold'
                                        //@ts-ignore
                                        : this.props.location.state.name}
                                </Typography>
                            </div>
                            {!this.state.loader ? (
                                <>
                                    <div className="product-section">
                                        {this.state.dashboardData.length !== 0 ? 
                                        (this.state.dashboardData.map(
                                                (data: any, i: any) => {
                                                    return (
                                                        <div
                                                            key={data.id}
                                                            className="product-card"
                                                            onClick={() => {
                                                                //@ts-ignore
                                                                this.props.history.push(
                                                                    `/product-details/${data.attributes.id || data.id}`
                                                                );
                                                            }}
                                                        >
                                                            <LazyLoadImage
                                                                src={
                                                                    data?.attributes
                                                                        ?.images?.[0]?.url
                                                                }
                                                                alt=""
                                                                className="prod-img"
                                                                height={250}
                                                                style={{
                                                                    borderRadius: "12px",
                                                                }}
                                                                effect="opacity"
                                                                placeholderSrc={prodSkel}
                                                            />
                                                            <>
                                                                <Typography className="card-prod-name">
                                                                    {data?.attributes?.name}
                                                                </Typography>
                                                                <Typography className="card-prod-quan">
                                                                    {data?.attributes?.value}{" "}
                                                                    {data?.attributes?.quantity}
                                                                </Typography>
                                                                {this.props.match.params.id === "recently-sold" &&
                                                                    <Typography>
                                                                        QTY: {data?.attributes?.sold_quantity}
                                                                    </Typography>
                                                                } 
                                                                <Typography className="card-prod-price">
                                                                    ₹{data.attributes.price}
                                                                </Typography>
                                                            </>
                                                            {/* )} */}
                                                        </div>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <Typography>No Products Found!!</Typography>
                                        )}
                                    </div>
                                    {!this.state.productsListEnd && (
                                        <div
                                            id="loader"
                                            style={{ display: "block", margin: "10px 48%" }}
                                        >
                                            <CircularProgress />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <CircularProgress style={{ margin: "10% 49%" }} />
                            )}
                        </div>
                    </>
                )}
                {/* @ts-ignore */}
                {!this.state.loader && <Footer hasData={this.state.dashboardData} />
}
            </>
		);
	}
}
//Customizable Area End
