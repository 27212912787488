//Customizable Area Start
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./DeleteBankAccountModalweb.scss"
const trash = require("../src/trash.jpg")
const DeleteBankAccountModalweb= (props: any) => {
    const [progress, setprogress] = useState<boolean>(false);
    const closeProgress=()=>{
        setprogress(false)
    }
  return (
    <Modal
    open={props?.deleteBankModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box className="delete-bankbox-modal">
    <div className="div-modal">
    <img src={trash}/>
    </div>
     <h4 className="div-modal-h4">Delete Bank Account</h4>
      <Typography >
        <p  className="p-tag-modal">Are you sure you want to delete bank account?</p>
      </Typography>
      <div className="delete-bank-div">
      <Button variant="contained" className="delete-cancel-btn" onClick={()=>{
      props?.doClose()
     }} >
            CANCEL
      </Button>&nbsp;&nbsp;&nbsp;&nbsp;
      
      {progress?<Button variant="contained" className="delete-bankmodal-btn" disabled={progress}
             >
              <CircularProgress style={{color:'#FFFFFF'}}/>
            </Button>:(
            <Button variant="contained" className="delete-bankmodal-btn" onClick={()=>{
                setTimeout(() => {
                    setprogress(true)    
                }, 1);
                props?.deleteBankAccount(props?.bankID,closeProgress)
               }} >
                      DELETE
                </Button>
           )}
      </div>
    </Box>
  </Modal>
  );
};
export default DeleteBankAccountModalweb;
// Customizable Area End
